import * as ModelUsuario from './model.usuario.editar'

export function Enviar (e, dados, loading, setLoading, usuario, setUsuario, setDadosMsg, setOpen) {
    
  e.preventDefault();
    
  // CHAMA A API
  setLoading ({...loading, inicio: true, fim: false, isSuccess: false})
  
  let data = Body (dados, usuario)
  
  ModelUsuario.Editar (data, async (result) => {
            
      if (result.isSuccess) {
          
        console.debug ('Dados do perfil atualizados com sucesso');
        setUsuario ({...usuario, nome: dados.nome, sobrenome: dados.sobrenome, email: dados.email})
        setDadosMsg ("Dados do perfil atualizados com sucesso!");
      
      } else {
        
        console.debug ('Erro ao atualizar os dados do perfil: ', result.erro + ' - ' + result.msg);
        setDadosMsg ("Erro ao atualizar os dados do perfil. Tente novamente mais tarde.");
        
      }
      setLoading (result);
      setOpen (true); 
  })   
      }

function Body (dados, usuario) {

  if (dados.senha === "" && dados.repetirSenha === "") {
    
    return {id_usuario: usuario.id, nome: dados.nome, sobrenome: dados.sobrenome, email: dados.email};
    
  } else 
  
  return {id_usuario: usuario.id, nome: dados.nome, sobrenome: dados.sobrenome, email: dados.email, senha: dados.senha};

}      