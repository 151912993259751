import { chamarApi } from "../../../services/service.api";
import * as React from 'react';
import { OnlineStatus } from '../../../services/service.hooks'

export function useQuestoes (data, fetch, setLoading, disciplinaEscolhida) {
    
    const [shouldFetch, setShouldFetch] = React.useState(fetch);  
    const [questoes, setQuestoes] = React.useState(null);
    const loading = React.useRef ({});
    const dataTemp = React.useRef (data);
    const questoesAnteriores = React.useRef ([]);

    const onlineStatus = OnlineStatus(); 
    
        const fetchQuestoes = React.useCallback(async () => {
   
            try {
            
                console.debug('Listar questoes');
                let endpoint;

                if (disciplinaEscolhida.idSegmento === 3) {
                    endpoint = 'questoesListarSelecao';
                }

                if (disciplinaEscolhida.idSegmento === 4) {
                    endpoint = 'questoesListarEMSelecao';
                    dataTemp.current = {...dataTemp.current, disciplina: disciplinaEscolhida.idDisciplina};
                }
                
                const result = await chamarApi ({ type: 'post', endpoint: endpoint, data: dataTemp.current });
            
                if (result.isSuccess) {
                    
                    if (dataTemp.current.offset > 0) {
                    
                        result.response.forEach ((item) => questoesAnteriores.current.push(item))
                        setQuestoes(questoesAnteriores.current);
                    
                    } else {
                    
                        let a = result.response;
                        questoesAnteriores.current = a;
                        setQuestoes(questoesAnteriores.current);
                    
                    }
                    
                } else {
                    
                    setQuestoes(questoesAnteriores.current);        
                
                }

            loading.current = {inicio: result.inicio, fim: result.fim, isSuccess: result.isSuccess, online: true, erro: result.erro, msg: result.msg}
            setLoading (loading.current) 

          } catch (error) {
                
            setQuestoes(questoesAnteriores.current);
            loading.current = {inicio: error.inicio, fim: error.fim, isSuccess: error.isSuccess, online: true,  erro: error.erro, msg: error.msg};
            setLoading (loading.current) 

          } finally {
            
            setShouldFetch(false);
            
          }
      }, []); // Dependências do useEffect
     
      React.useEffect(() => {
        
        if (loading.current.inicio===false && onlineStatus) {
        
            fetchQuestoes();
        
        }

        if (!onlineStatus) {
            
            loading.current = {inicio: false, fim: false, isSuccess: false, online: false};
            setLoading (loading.current) 
        
        }
    
    }, [onlineStatus]);

    
    const refetch = (valor) => {dataTemp.current = valor; setShouldFetch(true); fetchQuestoes()};
      
    return [ questoes, refetch  ];

}

export function ObterPorIDLista (data, callback) {
    data = {params: data}
    const getResult = async () => {
        try {
            
            const result = await chamarApi ({type: 'get', endpoint: 'questoesObterPorIDLista', data: data}) // chama a API
            callback (result);
        } catch (error) {
            callback (error);
        } 
    }
        getResult();
    }


export function ReportarProblema (data, callback) {

    const getResult = async () => {
        try {
            
            const result = await chamarApi ({type: 'post', endpoint: 'questoesReportarProblema', data: data}) // chama a API
            callback (result);
        } catch (error) {
            callback (error);
        } 
    }
        getResult();
    }        

    

function BodyEditar (disciplina, id, filtros, tags, ClassificarPor, nome, enun, gab_comentado) {
    
    let a ={}
    
    a = filtros;
    
    if (tags.length > 0 ) {
        if (tags.length === 1) {
            a.tag = tags[0]
        } else
        {
            a = []
            return a;
        }
    }
      
    
    a.id = id;
    
    if (nome !== '') {
        a.enunciado = enun;
        a.gabarito_comentado = gab_comentado;
    }

    
    return a;
}



