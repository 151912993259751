import { useState } from "react";
import { Typography, Divider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { CARD_TITULO_BORDER_RADIUS, CARD_TITULO_FONT_WEIGHT_TITULO, CARD_TITULO_VARIANT_TITULO, CARD_TITULO_MT_CHILDREN, CARD_TITULO_FONT_SIZE_TITULO,
  CARD_TITULO_MB, CARD_TITULO_MT, CARD_TITULO_FONT_COLOR_TITULO  } from "../../data/config/config";

  const borderRadius = CARD_TITULO_BORDER_RADIUS;

function AccordionCustom (props) {
    
    const titulo = props.titulo ? props.titulo : '';
    const margin_top  = props.margin_top ? props.margin_top : 0;
    const divider = props.divider ? props.divider : true;
    const variant = props.variant ? props.variant : "body1"
    const nome = props.nome ? props.nome : "painel"
    const btn = props.btn ? props.btn : null;
    const componentes = props.componentes;
    const fontWeightTitulo = props.fontWeightTitulo ? props.fontWeightTitulo : CARD_TITULO_FONT_WEIGHT_TITULO;
    const variantTitulo = props.variantTitulo ? props.variantTitulo : CARD_TITULO_VARIANT_TITULO;
    const fontSizeTitulo = props.fontSizeTitulo ? props.fontSizeTitulo : CARD_TITULO_FONT_SIZE_TITULO;
    const fontColorTitulo = props.fontColorTitulo ? props.fontColorTitulo : CARD_TITULO_FONT_COLOR_TITULO; 
    
    const [expanded, setExpanded] = useState(props.nome);


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
   };

    return <> 

        <div style={{marginTop: margin_top, width: '100%'}}>
            <Accordion expanded={expanded === nome} onChange={handleChange(nome)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={nome + "-content"}
                    id={nome + "panel1a-header"}
                >
                    <Typography sx={{fontWeight: fontWeightTitulo, fontSize: fontSizeTitulo, color: fontColorTitulo, mt:1, mb:1, ml:0}} variant={variant} >
                          {titulo}
                    </Typography>
                    
                    {btn && <div style={{marginBottom: -5}}> 
                    {btn}
                    </div>
                }         
                
                </AccordionSummary>
                {divider && <Divider sx={{ml:2, mr: 2, mt:-1}}/>} 
                <AccordionDetails sx={{mt:-4}}>
                    {componentes}

                    </AccordionDetails> 
                </Accordion>
        </div>            
    
    </>
} export default AccordionCustom;

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme  }) => ({
    border: 'none',
    borderRadius: 10,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(2),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));