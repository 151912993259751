import { Grid, IconButton } from "@mui/material"
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export default function BotaoQuestaoAnterior (props) {

    const { onClick, disabled, isMobile } = props
    
    const Btn = styled (IconButton) (({ theme }) => ({
        
        backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[300],
        '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? grey[700] : grey[400],
        
    },
      }));

    return (

        <>
        
        <Grid item xs={isMobile ? 2 : undefined} sx={isMobile ? { ml: 1 } : undefined}>
            
            <Btn variant="outlined" color='primary' size="medium" onClick={onClick} disabled={disabled}>
            
            <NavigateBeforeIcon />
            
            </Btn>
        
        </Grid>
        
        </>
    )
}