// SideBar.js
import { useState} from 'react';
import { Drawer, List, Box, IconButton } from '@mui/material';
import { useLocation } from "react-router-dom";
import MenuItem from '../../components/SideBar/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoNavBar from './view.navbar.logo.empresa';
import { styled, useTheme } from '@mui/system';
import { sidebarItems } from './view.sidebar.config';
import { useTela } from '../../services/service.tela.provider';
const drawerWidth = 230;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
     
  }));

  

const SideBar = ({ isMobile, open, setOpenMenu, handleDrawerClose }) => {
    const theme = useTheme();
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(location.state?.selected);
    const [tela, setTela] = useTela();

    function onClickSideBarIcon (text) {
      if (!isMobile) {
        //  handleDrawerOpen ();
       } else {
      
         handleDrawerClose ();
       }
      setSelectedIndex(text);
      setTela (text)
       
      }
      
      const toggleDrawer = (event, reason) => {
       
        if (isMobile && event.target.innerHTML.length > 10) {
        handleDrawerClose ();
          
        }
    
       
      };

   
     
    return (
    <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            
          },
        }}
        transitionDuration ={500}
        variant={"persistent"}
        anchor="left"
        open={open}
        hideBackdrop={false}
        onClick={toggleDrawer}
      >
        {/* ************* CABEÇALHO ************* */}
        <DrawerHeader sx={{   display: 'flex', justifyContent: 'space-between', 
        ...theme.palette.mode==='light' && {backgroundColor: 'background.paper'},
        ...isMobile && open && {mt:-1.5, mb:1},
        ...isMobile && !open && {mt:-1.0, mb:1},
        ...!isMobile && !open && {mt:-2.0, mb:1.8},
        ...!isMobile && open && {mt:-3.2, mb:1.8},
        
        }}>

        {/* ************* LOGO  ************* */}
        
        {/* Mostra apenas se o menu estiver aberto */}
        <div style={{width: '25px',
          ...isMobile && open && {marginTop: 20},
        }}/>
            
        <Box sx={{
             ...isMobile && {mt:-0.7},
             ...!isMobile && open && {mt: 0.9, mb:0.1},
             ...!isMobile && !open && {mt:0.0},
             
          }}>

          {open && <LogoNavBar open={open} />}
        </Box>
            
        {/* ************* BTN ICONE FECHAR MENU  ************* */}  
          <IconButton onClick={handleDrawerClose} sx={{
            ...isMobile && {mt:1.0},
            ...!isMobile && open && {mt:3.2},
            ...!isMobile && !open && {mt:1.8},
            ...theme.palette.mode==='light' 
            }}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
               
      {/* ************** MENU ITEMS ************** */}
      
      <List>
          {sidebarItems.map((item) => (
            <MenuItem 
              key={item.nome}
              text={item.nome}
              open={open}
              index={item.id}
              Icon={item.icon}
              link={item.link}
              divisor={item.divisoria}
              selected={tela.atual === item.nome ? true : false}
              isMobile={isMobile}
              onClick={onClickSideBarIcon}
              />
          ))}
        </List>
    </Drawer>
  );
};



export default SideBar;
