import React from 'react';
import { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { protegerRotas } from './data/config/config';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
  import { QuestoesProvider } from './services/service.questoes.provider';
import { TemasContext } from './services/service.temas.provider';

import { ThemeProvider } from '@mui/material';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import CssBaseline from '@mui/material/CssBaseline';

// PÁGINAS
import Home from './pages/Home/Home';
import LoginPage from './pages/Login/Login';
import Teste from './pages/teste'; 
import Disciplinas from './pages/Disciplinas/Disciplinas.jsx';
import Questoes from './pages/Questoes/Questoes'; 
import Configuracoes from './pages/Configuracoes/Configuracoes';
import Creditos from './pages/Creditos/Creditos.jsx';
import TesteChatGPT from './pages/teste';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import Notificacoes from './pages/Notificacoes/Notificacoes.jsx';
import Listas from './pages/Listas/Listas';
import Contato from './pages/Contato/Contato';
import Logout from './pages/Logout/Logout';
import PIXPage from './pages/PIXPage.jsx/PIXPage';
import { CssVarsProvider, extendTheme } from '@mui/material-next/styles'; 
import LandingPage from './pages/LandingPage/LandingPage';
import SobrePage from './pages/LandingPage/SobrePage';
import AjudaPage from './pages/LandingPage/AjudaPage';
import FaleConoscoPage from './pages/LandingPage/FaleConoscoPage';
import AlunoRoute from './pages/Aluno/Route/modulo.aluno.route';
import AlunoCadastro from './pages/Aluno/Cadastro/Cadastro';
import AlunoLista from './pages/Aluno/Lista/Lista';
import AlunoQuestao from './pages/Aluno/Lista/controller.aluno.questao';
import NovoLink from './pages/Novo Link/NovoLink';
import Stats from './pages/Listas/StatsLista';
import SiteFramework from './components/site/view.site-framework';
import AlunosInscritos from './pages/Listas/page.alunos.inscritos';
import VerQuestoes from './pages/Listas/VerQuestoes';

function App() {
  //const {theme} = useContext(VariaveisContext);
  const {theme} = useContext (TemasContext);
  
  const md3Theme = extendTheme({ref: {
    palette: {
      source: '#657a00',
      primary: {
        0: '#000000',
        10: '#171e00',
        20: '#2a3400',
        25: '#344000',
        30: '#3e4c00',
        35: '#495900',
        40: '#546500',
        50: '#6a8009',
        60: '#839a28',
        70: '#9db541',
        80: '#b8d15a',
        90: '#d4ee73',
        95: '#e2fc7f',
        98: '#f5ffc6',
        99: '#fbffe0',
        100: '#ffffff',
      },
      secondary: {
        0: '#000000',
  10: '#171e00',
  20: '#2a3400',
  25: '#344000',
  30: '#3e4c00',
  35: '#495900',
  40: '#546500',
  50: '#6a8009',
  60: '#839a28',
  70: '#9db541',
  80: '#b8d15a',
  90: '#d4ee73',
  95: '#e2fc7f',
  98: '#f5ffc6',
  99: '#fbffe0',
  100: '#ffffff',
      },

      tertiary: {
        0: '#000000',
        10: '#00201b',
        20: '#04372f',
        25: '#14433a',
        30: '#214e46',
        35: '#2e5a51',
        40: '#3a665d',
        50: '#537f76',
        60: '#6c998f',
        70: '#86b4a9',
        80: '#a1d0c5',
        90: '#bdece0',
        95: '#cbfbee',
        98: '#e5fff7',
        99: '#f3fffa',
        100: '#ffffff',

    },
    neutral: {
      0: '#000000',
  10: '#1b1c17',
  20: '#30312b',
  25: '#3b3c36',
  30: '#474741',
  35: '#53534d',
  40: '#5f5f58',
  50: '#787771',
  60: '#92918a',
  70: '#acaba4',
  80: '#c8c7bf',
  90: '#e4e2da',
  95: '#f3f1e8',
  98: '#fcf9f1',
  99: '#fefcf4',
  100: '#ffffff',
    },

    neutralVariant: {
      0: '#000000',
      10: '#1b1d12',
      20: '#303126',
      25: '#3b3c31',
      30: '#46483c',
      35: '#525347',
      40: '#5e5f52',
      50: '#77786a',
      60: '#919283',
      70: '#abac9d',
      80: '#c7c8b8',
      90: '#e3e4d3',
      95: '#f2f2e1',
      98: '#fafae9',
      99: '#fdfdec',
      100: '#ffffff',
    },

    error: {
      0: '#000000',
  10: '#410002',
  20: '#690005',
  25: '#7e0007',
  30: '#93000a',
  35: '#a80710',
  40: '#ba1a1a',
  50: '#de3730',
  60: '#ff5449',
  70: '#ff897d',
  80: '#ffb4ab',
  90: '#ffdad6',
  95: '#ffedea',
  98: '#fff8f7',
  99: '#fffbff',
  100: '#ffffff',
    },
      
    light: {
      classico: {
        nome: 'Clássico',
   
        
          primary: {
          main: "#546500",
          contrastText: "#ffffff"
          },
          primaryContainer: {
          main: "#d4ee73",
          contrastText: "#171e00"
          },
          secondary: {
          main: "#5c6146",
          contrastText: "#ffffff"
          },
          
          background: "#fefcf4",
          onBackground: "#1b1c17",
          surface: "#fefcf4",
          onSurface: "#1b1c17",
          surfaceVariant: "#e3e4d3",
          onSurfaceVariant: "#46483c",
          outline: "#77786a",
          inverseOnSurface: "#f3f1e8",
          inverseSurface: "#30312b",
          inversePrimary: "#b8d15a",
          shadow: "#000000",
          surfaceTint: "#546500",
          outlineVariant: "#c7c8b8",
          scrim: "#000000"
          },
        
    
  
  // cssVarPrefix is only required if multiple themes coexist
  // on the same page like on this guide
  cssVarPrefix: 'turquoise-md3',}}}});

  return (
    <ThemeProvider theme={theme}>   
    <CssBaseline enableColorScheme/>
    
    <QuestoesProvider>
    <Router>
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/sobre" element={<SobrePage />} />
            <Route path="/ajuda/faq" element={<AjudaPage />} />
            <Route path="/ajuda/faleconosco" element={<FaleConoscoPage />} />
            <Route path="/conta/login" element={<LoginPage estado={0} />} />
            <Route path="/conta/cadastro" element={<LoginPage estado={1} />} />
            
            {protegerRotas ? <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} /> : <Route path="/home" element={<Home />} />}
            {protegerRotas ? <Route path="/disciplinas" element={<PrivateRoute><Disciplinas /></PrivateRoute>} /> : <Route path="/disciplinas" element={<Disciplinas />} />}
            {protegerRotas ? <Route path="/questoes" element={<PrivateRoute><Questoes /></PrivateRoute>} /> : <Route path="/questoes" element={<Questoes />} />}
            {protegerRotas ? <Route path="/usuario/editar" element={<PrivateRoute><Configuracoes /></PrivateRoute>} /> : <Route path="/usuario/editar" element={<Configuracoes />} />}
            {protegerRotas ? <Route path="/usuario/contato" element={<PrivateRoute><Contato /></PrivateRoute>} /> : <Route path="/usuario/contato" element={<Contato />} />}
            {protegerRotas ? <Route path="/usuario/logout" element={<PrivateRoute><Logout /></PrivateRoute>} /> : <Route path="/usuario/logout" element={<Logout />} />}
            
            {protegerRotas ? <Route path="/creditos" element={<PrivateRoute><SiteFramework ><Creditos /></SiteFramework ></PrivateRoute>} /> : <Route path="/creditos" element={<SiteFramework ><Creditos /></SiteFramework>} />}
            {protegerRotas ? <Route path="/pagamento" element={<PrivateRoute><SiteFramework naomudarnometela voltar ><PaymentPage /></SiteFramework></PrivateRoute>} /> : <Route path="/pagamento" element={<SiteFramework naomudarnometela voltar ><PaymentPage /></SiteFramework>} />}
            {protegerRotas ? <Route path="/pagamento/pix" element={<PrivateRoute><PIXPage /></PrivateRoute>} /> : <Route path="/pagamento/pix" element={<PIXPage />} />}
            {protegerRotas ? <Route path="/notificacoes" element={<PrivateRoute><Notificacoes /></PrivateRoute>} /> : <Route path="/notificacoes" element={<Notificacoes />} />}
            {protegerRotas ? <Route path="/listas" element={<PrivateRoute><SiteFramework ><Listas /></SiteFramework ></PrivateRoute>} /> : <Route path="/listas" element={<SiteFramework ><Listas /></SiteFramework>} />}
            {protegerRotas ? <Route path="/listas/link" element={<PrivateRoute><NovoLink /></PrivateRoute>} /> : <Route path="/listas/link" element={<NovoLink />} />}
            {protegerRotas ? <Route path="/listas/questoes" element={<PrivateRoute><SiteFramework ><VerQuestoes /></SiteFramework ></PrivateRoute>} /> : <Route path="/listas/questoes" element={<SiteFramework ><VerQuestoes /></SiteFramework>} />}
            {protegerRotas ? <Route path="/listas/link/stats" element={<PrivateRoute><SiteFramework ><Stats /></SiteFramework></PrivateRoute>} /> : <Route path="/listas/link/stats" element={<SiteFramework ><Stats /></SiteFramework>} />}
            {protegerRotas ? <Route path="/listas/link/alunos" element={<PrivateRoute><SiteFramework ><AlunosInscritos /></SiteFramework></PrivateRoute>} /> : <Route path="/listas/link/alunos" element={<SiteFramework ><AlunosInscritos /></SiteFramework>} />}

            {/* ALUNO */}
            {<Route path = '/aluno/' element = { <AlunoRoute> <AlunoLista /> </AlunoRoute> } />}
            {<Route path = '/aluno/:id_link/:token' element = { <AlunoRoute>  <AlunoQuestao>  </AlunoQuestao> </AlunoRoute> } />}
            {<Route path = '/aluno/cadastro' element = { <AlunoCadastro /> } />} 

        </Routes>  
    </Router>
    </QuestoesProvider>
   
    </ThemeProvider>
  )};
 export default App; 

