import { useState } from 'react';

import Pix from '../../components/Pix/Pix';

export default function PIXPage (props) {
  
  const [pix] = useState (JSON.parse (localStorage.getItem ('pix')));

  return (
        
        <Pix dados = {pix} onFinish={(valor) => props.onFinish (valor) } /> 
    
  )
   
}