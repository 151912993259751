export function Existe (key) {
    if (localStorage.getItem(key)) {
        return true
    } else return false;
}
 
export function Cria (key) {
    localStorage.setItem (key, JSON.stringify({}));
}

export function Abrir (key) {
    //if (!Existe (key)) Cria (key);
        
    return JSON.parse (localStorage.getItem (key));
}

export async function Salvar (key, data) {
    //if (!Existe (key)) Cria (key);
        
    localStorage.setItem (key, JSON.stringify(data));
}

export function Get (key, tipo) {

    if (Existe (key)) {
        
        switch (tipo) {
            case 'objeto':
                return JSON.parse (localStorage.getItem (key));
            case 'array':
                return JSON.parse (localStorage.getItem (key));
            default:
                return localStorage.getItem (key);
        }
    } else {

        switch (tipo) {
            case 'objeto':
                return {}
            case 'array':
                return []
            default:
                return ''
        }

    }
}

export function Set (key, valor, tipo ) {
    
    switch (tipo) {
        case 'objeto':
            localStorage.setItem (key, JSON.stringify(valor));
            break;
        case 'array':
        console.log(valor)    
        localStorage.setItem (key, JSON.stringify(valor));
            break;
        default:
            localStorage.setItem (key, valor);
    }
}

export function Remover (key) {
    
    localStorage.removeItem (key)
}
