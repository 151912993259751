import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { QuestoesContext } from '../../services/service.questoes.provider.jsx';
import { Box, Slide } from '@mui/material';
import { useTela } from '../../services/service.tela.provider.jsx';
import ResumoPagamento from './modulo.pagamento.resumo.jsx';
import { useLocalStorage } from '../../services/service.hooks.js';
import ModalSucessoErro from '../ModalSucessoErro/ModalSucessoErro.jsx';
import MercadoPago from './modulo.mercado.pago.jsx';
import * as Pagamento from './controller.pagamento.js'
import PIXPage from '../../pages/PIXPage.jsx/PIXPage.jsx';

function DadosPagamento ({ dados }) {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [ tela, setTela ] = useTela ();

  const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false});
  
  const [paymentError, setPaymentError] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentMsg, setPaymentMsg] = useState('');
  
  const { usuario, setUsuario } = useContext (QuestoesContext);
  
  const [pixPage, setPixPage] = useState (false);

  // Leva para a página de disciplinas se o pagamento for bem sucedido
  const handlePaymentSuccess = (e) => {
    
   // e.preventDefault();
    setPaymentSuccess(true);
    
    if (tela.atual === "Questões") {
        
      navigate ('/questoes')
    
    }
    
    if (tela.atual === "Listas") {
        
      navigate ('/listas', {state: location.state})
    
    }
    
    setTela ('Disciplinas')
    navigate ('/disciplinas');
  };

  return (
    <>
      
      <Box style= {{ml:-2, mr:-2}} >
          
        {!pixPage &&
        <>
        <Slide in={true} direction={'down'} timeout ={1200}>
        <Box>
        <ResumoPagamento valores = {dados} />
        </Box>
        </Slide>   
        
        <Slide in={true} direction={'up'} timeout ={1200}>
        <Box>
        <MercadoPago mostrar={loading.inicio} dados = {dados} email={usuario.email} idUsuario={usuario.id} 
                     onFinish={(response, metodo) => Pagamento.Finalizar (response, metodo, setPixPage, setPaymentError, setPaymentMsg, setPaymentSuccess, navigate, usuario, setUsuario)} 
                     onReady = {(valor) => Pagamento.onReady (valor, setLoading)} />
                     </Box>
                     </Slide>   
                     </>  
        }

        {pixPage && 
          <PIXPage onFinish = {(valor) => Pagamento.PIXFinalizar (valor, setPaymentMsg, setPaymentSuccess, usuario, setUsuario)}/>
        }
      </Box>
     
      {/* Alerta de erro em caso de erro no pagamento */}
      {paymentError ? 
          
          <ModalSucessoErro open={paymentError} msg={paymentMsg} data={{errorTipo: "error", btn1: {mostrar: true, label: "OK", color: "primary", component: null}, btn2: {mostrar: false}}} 
                            success={false} onClickBtn1={(e) => {e.preventDefault(); setPaymentError(false)}} onClose={(e) => {setPaymentError(false); window.location.reload();}} />
      
      : null}

      {/* Alerta de sucesso em caso de pagamento realizado com sucesso */}
      {paymentSuccess ? 
          
          <ModalSucessoErro open={paymentSuccess} msg={paymentMsg} data={{successTipo: "success", btn1: {mostrar: true, label: "OK", color: "primary", component: null}, btn2: {mostrar: false}}} 
                            onClickBtn1={(e) => handlePaymentSuccess (e)} onClose={(e) => handlePaymentSuccess(e)} success/>
      
      : null}
    
    </>
);
}


export default DadosPagamento;


