import { useState, useEffect } from 'react';
import { Grid, CircularProgress, TextField, Button, InputAdornment, IconButton, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AtualizaCampos, validarSenha, validarEmail, validarFormularioCriarConta } from './controller.login';
import  CreateIcon from '@mui/icons-material/Create';
import Copyright from '../Copyright/view.copyright';
import { COPYRIGHT_NOME, COPYRIGHT_SITE } from '../../data/config/config';

import Logo from './view.logo';
import Titulo from './view.titulo';
import Avatar from './view.avatar'
import FormNomeView from '../Form/view.form.nome';
import FormSobreNomeView from '../Form/view.form.sobrenome';
import EmailField from '../Form/view.form.email';
import SenhaField from '../Form/view.form.senha';
import RepetirSenhaField from '../Form/view.form.repetir.senha';
import Botao from './view.button';
import AlterarParaLogin from './view.alterar.para.login';

function CriarConta({ dadosCriarConta, setDadosCriarConta, alterarParaLogin, handleCriarConta, isLoading, estado, isMobile }) {
    
    const titulo = "Criar conta"
    
    const [botaoLigado, setBotaoLigado] = useState(false);
    const [senhaIgual,  setSenhaIgual]  = useState(true);
    const [emailValido, setEmailValido] = useState(true);
        
    // VALIDAÇÃO DOS DADOS - UPDATE
    useEffect(() => {
        
        validarFormularioCriarConta(dadosCriarConta, emailValido, senhaIgual, setBotaoLigado);
    
    }, [dadosCriarConta, senhaIgual]);

    return (
        <>
            <Logo isMobile = { isMobile } />

            <Avatar bgcolor = 'error.dark' mt = { 2 } icon = {<CreateIcon />} />
            
            <Titulo titulo = {titulo} />

            <FormNomeView      focus = {true}                  value = {dadosCriarConta.nome}         onChange={(e) => AtualizaCampos (e, estado, null, setDadosCriarConta)} disabled={isLoading} />
            <FormSobreNomeView                                 value = {dadosCriarConta.sobrenome}    onChange={(e) => AtualizaCampos (e, estado, null, setDadosCriarConta)} disabled={isLoading} />
            <EmailField                                        value = {dadosCriarConta.email}        onChange={(e) => AtualizaCampos (e, estado, null, setDadosCriarConta)} disabled={isLoading} emailValido = {(valor) => setEmailValido (valor)} /> 
            <SenhaField                                        value = {dadosCriarConta.senha}        onChange={(e) => AtualizaCampos (e, estado, null, setDadosCriarConta)} disabled={isLoading} /> 
            <RepetirSenhaField senha = {dadosCriarConta.senha} value = {dadosCriarConta.repetirsenha} onChange={(e) => AtualizaCampos (e, estado, null, setDadosCriarConta)} disabled={isLoading} senhaIgual = {(valor) => setSenhaIgual (valor)} /> 
            
            <Botao botaoLigado = {botaoLigado} isLoading = {isLoading} handle = {(event) => handleCriarConta (event)} palavra = {titulo} /> 
            
            <AlterarParaLogin isLoading = {isLoading} alterarParaLogin = {alterarParaLogin} />
            
            <Copyright nome={COPYRIGHT_NOME} site={COPYRIGHT_SITE} />
        </>

    );
}

export default CriarConta;
