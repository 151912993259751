import { Navigate } from "react-router-dom";

function PrivateRoute(props){
    
        const existeLocalStorage = localStorage.getItem('usuario') ? true : false;
        let logado = false;
    
        if (existeLocalStorage) {

                const logadoTemp = JSON.parse (localStorage.getItem('usuario')).token ? true : false;
                logado = logadoTemp;

        } else {
                
                logado = false;
        }
        
        return logado ? props.children : <Navigate to="/conta/login" />;

}

export default PrivateRoute;