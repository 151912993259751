import { useState, useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Divider } from '@mui/material';
import { TemasContext } from '../../services/service.temas.provider';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useTheme } from '@mui/material';

export default function MenuMudaTema(props) {
  
  const {setMode, setColorTema, temas} = useContext(TemasContext); // funções para mudar o tema
  const [selectedIndex, setSelectedIndex] = useState (0);
  const theme = useTheme();

  const handleMenuItemClick = (id, modo) => {
    setSelectedIndex(id);
    setMode (modo);
    setColorTema (id);
    props.setOpen (false);
  };

  const handleClose = () => {
    props.setOpen (false);
  };

  return (
    
      
      <Menu
        
        id="lock-menu"
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorReference={'anchorPosition'}
        anchorPosition={{ top: props.top, left: props.left }}
        open={props.open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        <MenuList>
        {temas.map((item) => (
              <>
              <MenuItem
                key={item.id}
                selected={item.id === selectedIndex}
                onClick={() => handleMenuItemClick(item.id, item.modo)}
              >
                  <ListItemIcon>
                    {item.modo==='light' ? <LightModeIcon fontSize='small' sx={{color: theme.palette.text.secondary}} /> : <DarkModeIcon fontSize='small' sx={{color: theme.palette.text.secondary}}/>}
                  </ListItemIcon>
                  <ListItemText>{item.nome}</ListItemText>
              </MenuItem>
              {item.divisoria && <Divider />}
              </>
            ))}
      </MenuList>
      </Menu>
      
  );
}