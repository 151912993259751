import { useState, useEffect } from "react";
import { Grid, Box, LinearProgress, Typography } from "@mui/material"
import Botao from "./modulo.button.finalizar";

export default function ProgressoView (props) {
    
    const [progress, setProgress] = useState(props.progress);
    const [atual, setAtual] = useState(0);
    
    function Animar () {

        const timer = setInterval(() => {

            setAtual ((oldAtual) => {

                if (oldAtual === progress) {
              
                  clearInterval(timer);
              
                }
              
              const diff = 5;
              
              return Math.min(oldAtual + diff, progress);
  
            });
          }, 100);
      
          return () => {
            clearInterval(timer);
          };
    }
    

    useEffect(() => {
      
       setProgress (props.progress)

    }, [props.progress]);

      
    useEffect(() => {
      
        Animar ()

    }, [props.progress]);
    
    return (


        <>
        <Box sx={{ width: '100%', mb:1}}>
            <Grid container direction='row' alignItems='center'>
            
            <Grid item xs={9}>
            
            <Typography variant = "body2">
                Progresso
            </Typography>
            
            <LinearProgress variant="determinate" value = { progress } />
            
            </Grid>
            
            <Grid item xs={1} >
            </Grid>
            
            <Grid item xs={2} justifyItems='center'>
            
            <Botao botaoLigado={true} onClick = {(valor) => props.onClick(valor)} {...props} />
            
            </Grid>
            
            </Grid>
        </Box>
        
        </>
    )
}