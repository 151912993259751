import { useState } from 'react';

import Card from '../../views/components/view.card';
import CardTitulo from '../../views/components/view.card.titulo';

import { Box, useTheme } from '@mui/material';

import { PieChart,  pieArcLabelClasses } from '@mui/x-charts';

import AssignmentIcon from '@mui/icons-material/Assignment';

export default function PieChartQuestoes (props) {
    
  const theme = useTheme ();
        
    return (

        <>
        <Box sx={{width: 'auto'}} >
        <Card titulo={<CardTitulo titulo='Questões' chipLabel={'Válidas: ' + props.questoes.validas} slide icon={<AssignmentIcon />} help tooltip={
        <span>Válidas: apenas as questões de múltipla escolha são consideradas para todos os resultados apresentados.<br/><br/>
        O gráfico mostra a porcentagem das questões que foram respondidas (o aluno selecionou alguma opção de resposta) e a porcentagem
        das questões que foram deixadas em branco (o aluno não selecionou nenhuma das opções de resposta)
  
        </span>

        }/>}  margin_top={20} fontSizeTitulo="1.2rem" divider  >

            
            
            

            <PieChart
                series={[
                  {
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    arcLabel: (item) => `${item.value} %`,
                    arcLabelMinAngle: 30,
                    data: [
                      { id: 0, value: props.questoes.pctRespondidas, label: (location) => {
                        if (location==='legend') return 'Respondidas'
                        if (location==='tooltip') return 'Porcentagem das questões que foram respondidas' 
                      },
                      color: theme.palette.chart.verde
                      },
                      
                      
                      
                      { id:1 , value: props.questoes.pctEmBranco, label: (location) => {
                        if (location==='legend') return 'Em branco'
                        if (location==='tooltip') return 'Porcentagem das questões que não foram respondidas' 
                      },
                      color: theme.palette.chart.vermelho
                      },
                    ],
                  },
                ]}

                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    
                  },
                }}

               
                height={200}
              />
           
            
        </Card>
        </Box>
        </>
    )
}   


