import { Zoom, Button, Card, CardContent, Typography, Divider, Box, Chip } from "@mui/material";
import { CARD_TITULO_BORDER_RADIUS, CARD_TITULO_FONT_WEIGHT_TITULO, CARD_TITULO_VARIANT_TITULO, CARD_TITULO_MT_CHILDREN, CARD_TITULO_FONT_SIZE_TITULO,
         CARD_TITULO_MB, CARD_TITULO_MT, CARD_TITULO_FONT_COLOR_TITULO  } from "../../data/config/config";

         

        
         
function CardTitulo (props) {
  const borderRadius = props.borderRadius ? props.borderRadius : CARD_TITULO_BORDER_RADIUS;
  const fontWeightTitulo = props.fontWeightTitulo ? props.fontWeightTitulo : CARD_TITULO_FONT_WEIGHT_TITULO;
  const variantTitulo = props.variantTitulo ? props.variantTitulo : CARD_TITULO_VARIANT_TITULO;
  const fontSizeTitulo = props.fontSizeTitulo ? props.fontSizeTitulo : CARD_TITULO_FONT_SIZE_TITULO;
  const fontColorTitulo = props.fontColorTitulo ? props.fontColorTitulo : CARD_TITULO_FONT_COLOR_TITULO;
  const variantBtn = props.variantBtn ? props.variantBtn : "contained";
  const colorBtn = props.colorBtn ? props.colorBtn : "primary";
  const mt = props.mt ? props.mt : CARD_TITULO_MT;
  const mb = props.mb ? props.mb : CARD_TITULO_MB;
  const mtChildren = props.mtChildren ? props.mtChildren : CARD_TITULO_MT_CHILDREN;
  const mlChildren = props.mlChildren ? props.mlChildren : 2
  const mrChildren = props.mrChildren ? props.mrChildren : 2
 

  return (
    <>
    
    <Card variant="outlined" sx={{borderRadius: borderRadius, mt: mt, mb: mb, borderStyle: 'none'}}>
        <CardContent >
            
                {props.titulo &&
                  
                    
                    <Typography sx={{fontWeight: fontWeightTitulo, fontSize: fontSizeTitulo, color: fontColorTitulo, mt:1, mb:1, ml:2}} variant={variantTitulo} component="div">
                        {props.titulo}
                        {
                        props.chip && 
                             <Zoom in={true}>
                                <Chip size='small' color='primary' label='SELECIONADA' sx={{fontSize: '0.7em', fontWeight:400, ml:2, mb:0.7}}  />
                             </Zoom>  
}
                    </Typography>
                   
}
                
           
                {props.divider && <Divider sx={{mt:0.5}}/>}
                
                {props.btnTitulo && 
                  <Button sx={{ml:0, mt: 2, mb: 1, ml:2}} size="small" variant={variantBtn} color={colorBtn} disabled ={props.btnDisabled} onClick={props.btnClick}> 
                      {props.btnTitulo}
                </Button>
}
                <Box sx={{mt:mtChildren, ml:mlChildren, mr:mrChildren, mb: 1}}>
                  {props.children}
                </Box>
      
    
    </CardContent >
        </Card>
     
    </>
  );
} export default CardTitulo;