import { Grid, Button } from "@mui/material"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export default function BotaoRemoverTodas (props) {

    const { onClick, isMobile } = props
    
    const BtnCustom = styled(Button)(({ theme }) => ({
      
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[300],
        },
      }));

    return (

        <>
        
        <Grid item  style={{textAlign: isMobile ? 'center' : 'initial'}} >
            
            <BtnCustom variant="text" color= 'vermelho' onClick={onClick} size="small" sx={{ml: isMobile ? 0 : 4}}>
            
                {isMobile ? <DeleteForeverIcon /> : "Remover todas"}
            
            </BtnCustom>
        
        </Grid>
        
        </>
    )
}