import { useEffect, useState, useRef } from "react";
import { Box, Slide, useTheme } from "@mui/material";
import { useLocalStorage } from "../../../services/service.hooks";
import Enunciado from "./Enunciado/modulo.enunciado";
import Opcoes from "./Opcao/modulo.opcao";
import BotaoQuestaoAnterior from "./view.botao.questao.anterior";
import BotaoQuestaoProxima from "./view.botao.questao.proxima";
import { containerStyle, getQuestoesValidas, getQuestoesRespondidas } from "./controller.aluno.lista";
import Progresso from "./Progresso/modulo.progresso";
import MsgIniciarLista from "../IniciarListaMsg/modulo.msg.iniciar.lista";

export default function AlunoLista (props) {
    
    const [ aluno, setAluno ] = useLocalStorage ('aluno')
    const theme = useTheme()
    const questao = aluno.questoes
    
    const [ atual, setAtual ] = useState (1);
    const [ totalValidas, setTotalValidas ] = useState (getQuestoesValidas (questao));
    const [ totalRespondidas, setTotalRespondidas ] = useState (getQuestoesRespondidas (questao));
    const [ anteriorDisabled, setAnteriorDisabled ] = useState (true);
    const [ proximaDisabled, setProximaDisabled ] = useState (false);
    const containerRef = useRef(null);
    const total         = questao.length;
    
   const [anima, setAnima] = useState (false)
   const [mostrar, setMostrar] = useState (false)

   
   function onClose () {
    setMostrar(true)
   }

    useEffect (() => {

        if (atual === total) {

            setProximaDisabled (true)
        } else {
            setProximaDisabled (false)
        }
        
        if (atual === 1) {
            setAnteriorDisabled (true)
        } else {
            setAnteriorDisabled (false)

    }
    setAnima (!anima)
    }, [atual])
    
   
    
    
    
    return (<>
        
        {props.resultado.aluno_iniciou && !mostrar &&
            
            <MsgIniciarLista onClose={() => setMostrar(true)} {...props}/>
        
        }    
        
        <Box style={containerStyle} sx={{backgroundColor: theme.palette.background.paper}}>

        

        <BotaoQuestaoAnterior disabled = {anteriorDisabled} onClick = {() => setAtual (atual-1)} />
        <BotaoQuestaoProxima disabled = {proximaDisabled} onClick = {() => setAtual (atual+1)} />
        
                
        <Progresso atual = {atual} questoes = {questao} aluno = {aluno} totalValidas = {totalValidas} totalRespondidas = {totalRespondidas}/>
        
        
        <Enunciado enunciado = {questao [atual - 1].enunciado} atual = {atual} total = {total} gabarito_liberado ={props.gabarito_liberado}/>
        <Opcoes    opcoes    = {questao [atual - 1].opcoes} aluno = {aluno} setAluno = {setAluno} atual = {atual} setTotalRespondidas = {setTotalRespondidas} resultado = {false}/>
        
        </Box>
     
        </>
        );

}