import { useState, useContext, useRef, useEffect } from "react";
import { QuestoesContext } from "../../services/service.questoes.provider";

import * as ModelCredito from './model.credito'
import * as ModelLista from './model.lista'
import * as ModelQuestao from './model.questao'

import { useLocalStorage } from '../../services/service.hooks.js';

import Loading from '../../components/Loading/view.loading2';
import * as ls from '../../services/service.local-storage';

function HomeController (props) {
    
    const {usuario, setUsuario} = useContext (QuestoesContext)
   
    const terminou = useRef({credito: {loading: true, sucesso: null}, questao: {loading: true, sucesso: null}, lista: {loading: true, sucesso: null}});
    const [loading, setLoading] = useState({inicio: true, fim: false, isSuccess: false, online: true});
    
    useEffect (() => {

        ChamarAPI();
    }, [])


    function ChamarAPI () {
        props.onFinishLoading (false);
        setLoading ({...loading, inicio: true, fim: false, isSuccess: false});
        
        let dadosListar = {};
        
        dadosListar.params = {id_usuario: usuario.id};

        ModelCredito.Validos (dadosListar, async (result) => {
            
            if (result.isSuccess) {
                
                terminou.current.credito.total = result.response 
                console.debug ('Créditos válidos', result.response);
                onFinishLoading (true, 'credito')
                
                
              //  usuario.numcreditos = result.response;
               // ls.Salvar ('usuario', usuario )
             
            } else {
                
                console.debug ('Erro ao obter créditos válidos:', result.erro + ' - ' + result.msg);
                onFinishLoading (false, 'credito')

            } 
    })  

        ModelLista.Total (dadosListar, async (result) => {
            if (result.isSuccess) {
                
                terminou.current.lista.total = result.response 
                console.debug ('Listas total', result.response);
                onFinishLoading (true, 'lista')
                
                
               // usuario.numprovas = result.response;
               // ls.Salvar ('usuario', usuario )

            } else {
                
                console.debug ('Erro ao obter o total de listas:', result.erro + ' - ' + result.msg);
                onFinishLoading (false, 'lista')

            } 
    })
    
        ModelQuestao.Total (dadosListar, async (result) => {
            if (result.isSuccess) {
                
                terminou.current.questao.total = result.response 
                console.debug ('Questões total', result.response);
                onFinishLoading (true, 'questao')
                
                
                //usuario.numquestoes = result.response;
                //ls.Salvar ('usuario', usuario )

            } else {
                
                console.debug ('Erro ao obter o total de questoes:', result.erro + ' - ' + result.msg);
                onFinishLoading (false, 'questao')

            } 
    })  
    }
    
        
    function onFinishLoading (resultado, tipo) {
       
        if (resultado) {

            terminou.current [tipo].sucesso = true;
            terminou.current [tipo].loading = false;

        } else {
            
            terminou.current [tipo].sucesso = false;
            terminou.current [tipo].loading = false;
            setLoading ({...loading, inicio: false, fim: true, isSuccess: false});
            props.onFinishLoading (false);
            return;
            
        }
        
        if (!terminou.current.credito.loading && !terminou.current.lista.loading && !terminou.current.questao.loading) {
            
            if (terminou.current.credito.sucesso && terminou.current.lista.sucesso && terminou.current.questao.sucesso) {
                
                let a = usuario;
                a.numcreditos = terminou.current.credito.total;
                a.numprovas   = terminou.current.lista.total;
                a.numquestoes = terminou.current.questao.total;
                localStorage.setItem ('usuario', JSON.stringify (a))
                setUsuario (a);

                props.onFinishLoading (true);
                setLoading ({...loading, inicio: false, fim: true, isSuccess: true })
            }
            
            if (terminou.current.credito.sucesso === false || terminou.current.lista.sucesso === false || terminou.current.questao.sucesso === false) {
            
                props.onFinishLoading (false);
                setLoading ({...loading, inicio: false, fim: true, isSuccess: false })
            
            } 
        }
    }

 
    return (
        <>
            <Loading local="conteudo" loading={loading} online={loading.online} tipo='imagem' msg={"Carregando seus dados..."} mostrarErroOnlineStatus/> 
           {/*} <Loading tipo="simples" loading={loading} onlineStatus={(valor) => valor ? ChamarAPI() : null} show={props.show} msg={"Carregando seus dados..."}/>  */}
        </>
    )

} export default HomeController;

// DETERMINAR SE É PRIMEIRO ACESSO
export function PrimeiroAcesso (usuario) {
    
    let resultado = false;
    usuario.primeiro_acesso===true && usuario.numcreditos > 0 ? resultado = true : resultado = false;
    return resultado; 

  }

