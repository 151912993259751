import { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Stack, Box, AppBar, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import BarChartPctPorOpcaoResposta from "../../components/Charts/BarChart/BarChartPctPorOpcaoDeResposta.jsx";
import BarChartPctAcertosPorQuestao from "../../components/Charts/BarChart/BarChartPctAcertosPorQuestao.jsx";
import GaugeChartNotaMedia from "../../components/Charts/BarChart/GaugeChartNotaMedia.jsx";
import PieChartAlunos from "../../components/Charts/PieChartAlunos.jsx";
import PieChartQuestoes from "../../components/Charts/PieChartQuestoes.jsx";
import BarChartPctNotas from "../../components/Charts/BarChart/BarChartPctNotas.jsx";
import ListaStatsAlunos from "./view.lista.stats.alunos.jsx";
import ListaStatsAlunosRespostas from "./view.lista.stats.alunos.respostas.jsx";

import BarChartPctAcertosPorAssunto from "../../components/Charts/BarChart/BarChartPctAcertosPorAssunto.jsx";
import { useLinkStats } from "./model.lista.link.js";
import { useTela } from "../../services/service.tela.provider.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import BotaoVoltar from "../../components/BotaoVoltar/view.botao.voltar2.jsx";
import Loading from '../../components/Loading/view.loading2.jsx';
import { QuestoesContext } from "../../services/service.questoes.provider.jsx";

export default function Stats (props) {
    
    const location = useLocation();
    const navigate = useNavigate();
    const [id_lista] = useState (location.state.id_lista)
    const [id_link] = useState (location.state.id_link)
    const {usuario} = useContext(QuestoesContext)
    const [turma] = useState (location.state.turma)
    const [listas_questoes, setListaQuestoes] = useState (buscarNoLocalStorage('listas_questoes'));
    const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false})
    const [questoes, setQuestoes] = useState (buscarNoLocalStorage('listas_questoes', id_lista));
    
    const [, setTela] = useTela ();
    const [stats] = useLinkStats ({params: {id_usuario: usuario.id, id_link: id_link}}, loading.inicio, setLoading, questoes)
    
    const [alunos, setAlunos] = useState ({})
    
    const [questoesStats, setQuestoesStats] = useState ({total: questoes.length, validas: questoesValidas (questoes), tags: valoresIneditosArvore(questoes) });
    const [liberado, setLiberado] = useState (false)
    
    const [tabValue, setTabValue] = useState(0);

    const handleChangeTab = (event, newValue) => {
      
        setTabValue (newValue);

    };

   useEffect (() => {
    
   if (loading.inicio === false) {

   if (loading.msg === "Questões não encontradas") {
    setLoading ({inicio: false, fim: true, isSuccess: false})
    return;
   } 
   alunos.dados = RespostasParaArray (stats.alunos)
   alunos.total = alunosTotal (stats.alunos);
   alunos.queFinalizaram = alunosQueEntregaram(stats.alunos)
   alunos.queComecaram = alunosQueComecaram(stats.alunos)
   alunos.queNaoComecaram = alunosQueNaoComecaram(stats.alunos)
   setAlunos (alunos)
   
   if (stats.questoes) {
      let a = [...listas_questoes, {id_lista: id_lista, questoes: stats.questoes}]
      localStorage.setItem ('listas_questoes', JSON.stringify (a))
   
   } 

   let questoes_final = stats.questoes ? getNumOpcoes (stats.questoes) : getNumOpcoes (questoes)  // Determina quantas opções existem em uma questão, além de separar cada opção do enunciado
    
    questoes_final = MarcacoesPorLetrasTodasQuestoes (questoes_final, alunos.dados) // Calcula a pct de marcação de cada opção para cada questão
    questoesStats.pctRespondidas = questoesPctRespondidas (questoes_final, alunos.dados )
    questoesStats.pctEmBranco = questoesPctEmBranco (questoes_final, alunos.dados )
    setQuestoesStats (questoesStats)
    
    alunos.dados = alunosNotas (alunos.dados, questoes_final)
    
    alunos.dados = alunos.dados.sort((a, b) => b.nota - a.nota);
    
    
    questoes_final = AcertosQuestoes (questoes_final)
    
    questoesStats.acertosPorTag = AcertosQuestoesPorTag (questoes_final, questoesStats, alunos);

    setAlunos (alunos)
    setQuestoes (questoes_final)
    setQuestoesStats (questoesStats)
   
    //setMarcacoesPorLetrasDados (getMarcacoesPorLetraDados (questoes_final[0]))
    setLiberado (true)
    }
   }, [loading.inicio])
    

    if (alunos.total === 0) {
        return (
            <>
            <BotaoVoltar onClick = {() => {setTela ('Listas'); navigate ('/listas', {state: {id_lista: id_lista, selected: 'Stats'}})}} titulo={'Turma: ' + turma} />
            <NenhumAlunoAlerta />
            </>
        )
    } else {

    return (
        
        <>
        {/* ************** LOADING ************** */}
        <Loading local="conteudo" loading={loading} online={loading.online} tipo='imagem' msg={"Buscando o resultado..."} mostrarErroOnlineStatus /> 

        <Box sx={{ maxWidth: '1200px', margin: '0 auto', typography: 'body1', 
                     bgcolor: 'background.default',  }}>
        
        
        
        <BotaoVoltar onClick = {() => {setTela ('Listas'); navigate ('/listas', {state: {id_lista: id_lista, selected: 'Stats'}})}} titulo={'Turma: ' + turma} />
        
        <AppBar position="sticky" sx={{ bgcolor: 'background.default',  boxShadow: 'none', mt:-2}}>
        
        <Tabs  value={tabValue} onChange={handleChangeTab}  variant="fullWidth" >
          
            <Tab label="Geral"    {...a11yProps(0)} />
            <Tab label="Assuntos"    {...a11yProps(1)} />
            <Tab label="Questões" {...a11yProps(2)} />
            <Tab label="Alunos"   {...a11yProps(3)} />
            
        </Tabs>
        
        </AppBar>
            
            <TabPanel value={tabValue} index={0} >

                {liberado && 
                <>
                    <Geral alunos={alunos} questoesStats={questoesStats} />
                </> 
                } 

            </TabPanel>
            
            <TabPanel value={tabValue} index={1} >

                {liberado && 
                <>
                    <Assuntos questoesStats={questoesStats} />
                </> 
                } 

            </TabPanel>

            <TabPanel value={tabValue} index={2}>

                {liberado && 
                <>
                    <Questoes questoes = {questoes} />
                </> 
                }

            </TabPanel>

            <TabPanel value={tabValue} index={3}>

                {liberado && 
                <>
                    <Alunos alunos={alunos.dados} questoes={questoes} turma={turma}/>
                </> 
                }       
            
            </TabPanel>
        
        </Box>    
            
            

            
        
        </>
    )
}
} 

function Geral (props) {
    
    const {alunos, questoesStats} = props

    return (<>
    
    <Grid container  spacing={2} display='flex' flexDirection={'row'}  justifyContent='flex-start' >
        <Grid item xs={12} md={3}> <GaugeChartNotaMedia alunos={alunos.dados} /> </Grid>
        <Grid item xs={12} md={9}> <BarChartPctNotas alunos={alunos} /></Grid>
        <Grid item sm={12} md={6}> <PieChartAlunos alunos={alunos} /> </Grid>
        <Grid item sm={12} md={6}> <PieChartQuestoes questoes={questoesStats} /></Grid>
        
    </Grid>
    </>)
}

function Questoes (props) {
    
    const {questoes} = props

    return (<>
    
    <Grid container   display='flex' flexDirection={'column'} >
        <Grid item >
        <BarChartPctAcertosPorQuestao questoes={questoes}/>
        </Grid>
    </Grid>

    <Grid container sx={{mt:0.5, mb:1}} spacing={2} display='flex' flexDirection={'row'} justifyContent='flex-start' alignItems='top' >
                {questoes.map ((item, index) =>   
                <Grid item sm={12} lg={6} >
                <BarChartPctPorOpcaoResposta titulo={'Questão ' + (index + 1)} dataset={getMarcacoesPorLetraDados (item)} questao={item}/> 
                </Grid>
                )}
    </Grid>    
    </>)
}

function Alunos (props) {

    return (<>
       
        <Grid container   display='flex' flexDirection={'column'} >
            <Grid item >
            
                <ListaStatsAlunos alunos={props.alunos} turma={props.turma}/>
                
            </Grid>

            <Grid item mt={1}>
            
                <ListaStatsAlunosRespostas alunos={props.alunos} questoes={props.questoes}/>

            </Grid>
        </Grid>
    </>)
}

function Assuntos (props) {

    return (<>
       
        <Grid container   display='flex' flexDirection={'column'} >
            <Grid item >
            
                <BarChartPctAcertosPorAssunto questoesStats = {props.questoesStats} />
                
            </Grid>

            
        </Grid>
    </>)
}

function buscarNoLocalStorage(chave, id_lista) {
    // Obtém o valor associado à chave do localStorage
    const valor = localStorage.getItem(chave);
  
    // Verifica se o valor é null (chave não encontrada)
    if (valor === null) {
      return [];
    }
  
    // Parseia o valor como JSON (assumindo que é uma string JSON)
    const arrayDeObjetos = JSON.parse(valor);
 
    // Verifica se arrayDeObjetos é realmente uma array
    if (!Array.isArray(arrayDeObjetos)) {
      return [];
    }

    
    
    // Percorre a array para encontrar o objeto com o id_lista especificado
    for (const objeto of arrayDeObjetos) {
        console.log (id_lista)
        if (objeto.id_lista === id_lista) {
        return objeto.questoes || [];
      }
    }
  
    // Se não encontrar o id_lista, retorna uma array vazia
    return [];
  }

  
function valoresIneditosArvore(array) {
    // Usar um conjunto para garantir que os valores sejam únicos
    const valoresUnicos = new Set();

    // Iterar sobre cada objeto no array
    array.forEach(objeto => {
        if (objeto.arvore) {
            valoresUnicos.add(objeto.arvore);
        }
    });

    // Converter o conjunto de volta para um array
    return Array.from(valoresUnicos);
}

function questoesPctEmBranco (questoes, alunos) {
    let total_questoes = questoesValidas (questoes)
    let result = 0;
    
    for (let questao of questoes) {
        if (questao.tipo === 1) {
            
            for (let aluno of alunos) {
                console.log (aluno.respostas)
                if (aluno.respostas.length === 0  || (aluno.respostas[questao.num_questao] === 'X'))
                {
                    result += 1;
                }
                
            }
            
        }
    }

    let total_aluno = alunos.length
    total_questoes = total_questoes * total_aluno
    
    let pct = 100 * result / total_questoes
    return pct.toFixed(0);
   
}

function questoesPctRespondidas (questoes, alunos) {
    let total_questoes = questoesValidas (questoes)
    let result = 0;
    for (let questao of questoes) {
        if (questao.tipo === 1) {
            
            for (let aluno of alunos) {
                
                if (aluno.respostas.length > 0) 
                {
                    let resposta = aluno.respostas[questao.num_questao]
                    if ( resposta !== 'X' && resposta !== 'Z') {
                        
                        result += 1;
                    }
                    
                }
            }
            
        }
    }
    
    let total_aluno = alunos.length
    total_questoes = total_questoes * total_aluno
  
    let pct = 100 * result / total_questoes
    return pct.toFixed(0);
}

function questoesValidas (questoes) {

    let result = 0;
    for (let questao of questoes) {
        if (questao.tipo === 1) {
            result += 1;
        }
    }
    return result;
}

function alunosTotal (alunos) {

    return alunos.length
}

function alunosNotas (alunos, questoes) {
    
    let total_questoes = questoesValidas (questoes)
    
    for (let aluno of alunos) {
        
        let nota = 0;
        let acertos = 0;
        
        for (let questao of questoes) {
        
            if (questao.tipo===1 && aluno.respostas[questao.num_questao] === questao.gabarito_objetiva) {
            
                acertos += 1;
        
        }
        }
        nota = 10 * acertos / total_questoes
        aluno.nota = nota.toFixed (1);
    }
    
    return alunos;
}

function alunosQueEntregaram (alunos) {

    let result = 0;
    for (let aluno of alunos) {
        if (aluno.encerrada === true) {
            result += 1;
        }
    }
    return result;
}

function alunosQueComecaram (alunos) {

    let result = 0;
    for (let aluno of alunos) {
        if (aluno.encerrada === false && aluno.respostas.length > 0) {
            result += 1;
        }
    }
    return result;
}

function alunosQueNaoComecaram (alunos) {

    let result = 0;
    for (let aluno of alunos) {
        if (aluno.encerrada === false && aluno.respostas.length === 0) {
            result += 1;
        }
    }
    return result;
}

function getNumOpcoes (questoes) {
    
    for (let questao of questoes) {
        questao.enunciado_completo = questao.enunciado
        let num_opcoes = 0;
        let opcoes_texto = [];
        
        
        if (questao.tipo === 1) {
        // Expressões regulares para encontrar as opções
        const regexOpcoes = /<p>([a-e]\))/g;
        let match
        
        while ((match = regexOpcoes.exec(questao.enunciado)) !== null) {
            const inicio = match.index;
          const proximaOpcao = regexOpcoes.exec(questao.enunciado);
  
          const fim = proximaOpcao ? proximaOpcao.index : questao.enunciado.length;
          let   opcao = questao.enunciado.slice(inicio, fim).trim();
          
          // Removendo o texto 'a)', 'b)', 'c)', 'd)', 'e)'
          opcao = opcao.replace(/[a-e]\)/, '').trim();
          
          opcoes_texto.push(opcao);
          
          // Resetando o regex para continuar a busca
          regexOpcoes.lastIndex = inicio + opcao.length;
          
          num_opcoes += 1;
        
        }
            questao.enunciado = questao.enunciado.split('<p>a)')[0].trim();
            questao.opcoes = num_opcoes
            questao.opcoes_texto = opcoes_texto;
    } else {
        questao.opcoes = 0;
        questao.opcoes_texto = [];
    }

    }

    return questoes;
}

function LetraIndex (letra) {

    switch (letra) {
        case 'A': return 0;
        case 'B': return 1;
        case 'C': return 2;
        case 'D': return 3;
        case 'E': return 4;
}
}

function getMarcacoesPorLetraDados (questao) {
    
    let dataset = []
    let letras = ['a)', 'b)', 'c)', 'd)', 'e)']
    let opcoes = questao.opcoes;
    let total = questao.marcacoes.reduce((acumulador, valorAtual) => acumulador + valorAtual, 0);

    for (let i = 0; i < opcoes; i++) { 
            
        let obj = {}
        obj.y = letras [i]
        console.log (total)
        let pct = 100 * questao.marcacoes [i] / total
        console.log (questao.marcacoes[i])
        obj.x = parseFloat(pct.toFixed(0));
        if (total === 0) obj.x = 0.0 
        dataset.push (obj)
    }
    console.log (dataset)
    return dataset;
}

function MarcacoesPorLetraAlunos (alunos, letra, num_questao) {
    
    let marcacoes = 0;
    
    for (let item of alunos) {

        if (item.respostas [num_questao] === letra) {

            marcacoes += 1;
        
        }
    }
    
    return marcacoes;

}


function MarcacoesDasLetrasPorQuestao (alunos, questoes, num_questao) {
    
    let letras = ['A', 'B', 'C', 'D', 'E']
    let marcacoes = []
    let opcoes = questoes[num_questao].opcoes;
    
    for (let i = 0; i < opcoes; i++) { 
            
        marcacoes.push (MarcacoesPorLetraAlunos (alunos, letras [i], num_questao ))

    }
    
    return marcacoes;

}

function MarcacoesPorLetrasTodasQuestoes (questoes, alunos) {
        
    let num_questao = 0;
    
    for (let item of questoes) {
        
        let marcacoes = MarcacoesDasLetrasPorQuestao (alunos, questoes, num_questao)
        item.marcacoes = marcacoes;
        item.num_questao = num_questao
        num_questao += 1;

    }
    
    return questoes;

}

function AcertosQuestoes (questoes) {
        
    for (let item of questoes) {

        let index = LetraIndex (item.gabarito_objetiva)

        let acertos = item.marcacoes [index];
        item.acertos = acertos;
    }
    
    return questoes;

}

function AcertosQuestoesPorTag (questoes, questoesStats, alunos) {
    
    let tags = questoesStats.tags;
    
    let result = [];
   
    for (let tag of tags) {
        
        let acertos = 0;
        let total = 0;

        for (let item of questoes) {
       
        if (item.arvore === tag) {
            acertos += item.acertos;
            total += 1;
        }
    }
    console.log (acertos)
    console.log (total)
    console.log (alunos.total)
    let pct = 100 * acertos / (alunos.total * total)
        pct = parseFloat(pct.toFixed(0));
    
    if (!pct) pct = 0.0;
    result.push ({tag: tag, pct: pct})
    console.log (result)
}
    return result;

}

function RespostasParaArray (alunos) {
    
    for (let item of alunos) {
        
        item.respostas = TransformarStringParaArrayStrings (item.respostas)
    }
    
    return alunos;

}

function TransformarStringParaArrayStrings (str) {
    
    if (str == null  ) return []
    return str.split(',').map(String);

}


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        
      role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ px: 2, pt: 4}} >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  function NenhumAlunoAlerta () {
    return (
      <Alert icon={<CheckIcon fontSize="inherit" />} variant="filled"  severity="info" >
        Nenhum aluno se cadastrou nesta lista.
      </Alert>
    );
  }