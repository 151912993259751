import { useState } from "react";
import * as modelNovoLink from './model.lista.link'
import Loading from '../../components/Loading/view.loading2.jsx';
import ModalSucessoErro from "../../components/ModalSucessoErro/ModalSucessoErro";
import { useLocalStorage } from "../../services/service.hooks";

export default function ControllerListaEncerrar (props) {
    
    const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
    
    function chamarApi () {
        
        let dados = {}
        dados = {id_link: props.id_link, encerrada: true}
                
        modelNovoLink.Encerrar (dados, async function (result) {
            
            if (result.isSuccess) {
               
               setLoading ({...loading, inicio: false, fim: true, isSuccess: true, online: true})
                
            } else {
                
                setLoading ({...loading, inicio: false, fim: true, isSuccess: false})
                console.log ('Erro ao encerrar um link', result)
                props.setOpenEncerrar (false)
                
            }

           
        })
        
    }

    function handleBtn2 () {
        
        setLoading ({...loading, inicio: true, online: true})
        chamarApi();

    }

    function onCloseModal () {
        window.location.reload();
        props.setOpenEncerrar (false)
    }


    return (
        <>
        
        {/* ************** LOADING ************** */}
        {props.open===true && 
            <Loading local="full" loading={loading}  tipo='imagem' online={loading.online} snackMsg={{success: 'Lista encerrada com sucesso!', error: 'Houve um erro ao tentar encerrar a lista'}}
                    msg={"Encerrando..."} mostrarErroOnlineStatus tipoSucesso='snack' onClose={() => onCloseModal()}
                               
            />
        } 
        
        {props.open===true && loading.inicio===false && loading.fim===false &&
        <ModalSucessoErro open={props.open} titulo='Encerrar a lista' msg='Você está prestes a encerrar a lista para esta turma antes do prazo estabelecido. Dessa forma, os alunos não poderão mais enviar as respostas. 
                                                                           O resultado final estará disponível imediatamente.' success={false}
          data={{btn1: {label: "VOLTAR", color: "secondary", mostrar: true}, btn2: {label: "ENCERRAR", color: "primary", mostrar: true} , errorTipo: "warning"}} 
          onClose={() => props.setOpenEncerrar (false)}
          onClickBtn2={() => handleBtn2()}
        />}
        


        </>
    )

}



