import { styled } from '@mui/system';

// LARGURA DO MENU LATERAL
export const drawerWidth = 230;

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
     
  }));

  export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, isMobile }) => ({
      
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen + 1000,
      }),
      
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen + 1000,
        }),
       
       [theme.breakpoints.up('sm')]: {marginLeft: 0},
       
      }),
    }),
  );  
  