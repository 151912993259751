import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';

export default function Alerta (props) {
    
    const tipo = props.tipo ? props.tipo : 'error';
    const msg = props.msg ? props.msg : 'Erro desconhecido';
    const variant = props.variant ? props.variant : null;
    const titulo = props.titulo ? props.titulo : null;
    return (
        <Alert 
        
            {...(variant && {'variant': variant})}
            severity = {tipo}
            >
             
             {titulo && 
                <AlertTitle>{titulo}</AlertTitle>
}
             {msg}
        </Alert>
    )
}