import {Stack, Chip, Typography, Divider, Button } from '@mui/material';

import Card from '../../views/components/view.card';
import CardTitulo from '../../views/components/view.card.titulo';
import { RetornaStringMoedaReal } from '../../services/service.moeda';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

export default function ResumoPedido (props) {
 
    return (
        <>
        <Card titulo={<CardTitulo icon={<SummarizeOutlinedIcon />}  titulo = 'Resumo do pedido' />}  margin_top={20} fontSizeTitulo="1.2rem" divider >
        
          
            {/* ************** VALOR DOS CRÉDITOS ************** */}
            <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={{xs:1, md:1}} sx={{ml:-0.5}}  divider={<Divider orientation="vertical" flexItem />}>
                
                <Chip label={'Créditos: ' + props.creditos.current} sx={{fontSize: 15}}  />
                <Chip label={'Períodos: ' + props.periodos.current} sx={{fontSize: 15}}  />
                <Chip label={'Valor por crédito: R$ ' + props.valorPorCredito.current} sx={{fontSize: 15}}  />
            
            </Stack>

            <Typography variant="h6" component="div" sx={{mt: 3.2}}>
            
                Total: {RetornaStringMoedaReal (props.valor)}
            
            </Typography>
                
            <Button variant='contained' onClick={props.onClick()} size="medium" sx={{mt: 5, mb:-2.0}}>PAGAR</Button>
        
        </Card>
        </>
    )
}