import { useState, useMemo } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {Box, styled, Typography} from '@mui/material'
import { useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


function TreeListView (props) {
    const theme = useTheme();
    const arvore = props.arvore;
    const [selected, setSelected] = useState (props.selected);
    const [iDsExpandidas, setIDsExpandidas] = useState ([0]);
    

   function getChildById (nodes, id) {
        let array = [];
    
    function getAllChild(nodes) {
        if (nodes === null) return [];
        array.push(nodes.id);
        if (Array.isArray(nodes.children)) {
        nodes.children.forEach(node => {
            array = [...array, ...getAllChild(node)];
            array = array.filter((v, i) => array.indexOf(v) === i);
        });
        }
        return array;
    }
    
    
    function getNodeById(nodes, id) {
        if (nodes.id === id) {
        return nodes;
        } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach(node => {
            if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
            }
        });
        return result;
        }

        return null;
    }

    return getAllChild(getNodeById(nodes, id));
    }
    
    function areAllChildrenSelected(nodes, parentId) {
        const childNodeIds = getChildById(nodes, parentId);
        return childNodeIds.every(id => selected.includes(id));
    }
    
    
    function getParentId(nodes, id) {
    let parentId;
    function findParent(node) {
        if (Array.isArray(node.children)) {
        node.children.forEach(child => {
            if (child.id === id) {
            parentId = node.id;
            } else {
            findParent(child);
            }
        });
        }
    }
    findParent(nodes);
    return parentId;
    }
          
    function getOnChange(id, checked) {
      
      
      
      
      let array = [...selected];
      
      
      const allNode = getChildById(arvore, id);

      if (checked) {
      
            
      // Junta as arrays e remove os valores duplicados
      array = [...new Set([...selected, ...allNode])];
      
      }  else {
        array = array.filter(item => !allNode.includes(item));
      
      }
      
      setSelected(array);
      
     // let a = [...array];
      //a.push (0)
      //a = [...new Set([...a, ...iDsExpandidas])];
      
      //setIDsExpandidas (a)
      //UpdateEscolhidas (array);
      props.onChange (array);

     // if (array.length === 0) {setBtnDisabled (true)} else {setBtnDisabled (false)}
      
    }
    
    const handleExpandedItemsChange = useMemo (() => 
      (event, itemIds) => {let a = [];
      
      itemIds.forEach(id => {
        let b = getParentId (arvore, id)
        a.push (b)
     })
     
     a.push (selected)
     a.push (itemIds)
     a.push (0)
      
      setIDsExpandidas (itemIds)
    }, [iDsExpandidas]);
      
    const renderTree = useMemo (() => (nodes) => (
      
      <>
       
        
        <CustomTreeItem 
        key={nodes.id} 
        itemId={nodes.id}
        label={nodes.name}
            
        sx={{ '& .MuiTreeItem-checkbox': {'& .MuiSvgIcon-root': { fontSize: 18 }},
              
            }}
          
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </CustomTreeItem>
</>
      ), []);
    

    const handleSelectedItemsChange = useMemo (() => 
     
      (event, id, isSelected) => getOnChange(id, isSelected)
     
    , [selected]);

    return (
        <>
      <Box sx={{backgroundColor: theme.palette.background.paper}} >
      <Typography color={theme.palette.mode === 'dark' ? theme.palette.primary.main : 'inherit'}>
      <SimpleTreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={iDsExpandidas}
      checkboxSelection
      multiSelect
      selectedItems={selected}
      expandedItems={iDsExpandidas}
      onExpandedItemsChange={handleExpandedItemsChange}
      onItemSelectionToggle={handleSelectedItemsChange }
      defaultExpandIcon={<ChevronRightIcon />}
     
      sx={{ flexGrow: 1, overflowY: 'auto', mt:2,mb:2 }}
      
    >
      {renderTree(arvore)}
      
    </ SimpleTreeView>
    </Typography>
    </Box>
    </>
        
       

)} export default TreeListView;

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  // Adicione aqui os estilos que você deseja personalizar
  
  '& .MuiTreeItem-content': {
    backgroundColor: theme.palette.background.paper,  // Cor de fundo
  
  },
}));