import { Box, Typography } from "@mui/material";
import CountUp from 'react-countup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Card = ({ icon, num, texto, cor_clara, cor_escura }) => {
    const theme = useTheme();
    
    function Size () {
        const tela = useMediaQuery(theme.breakpoints.down('sm')); 
        if (tela) {
            return {width: 183, height: 100, mt:-2, mb:1}} else
        return {width: 220, height: 120, mt:2, mb: 2}
    }

    return (
        <Box 
            sx={{
                width: Size().width,
                height: Size().height,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                mt: Size().mt,
                mb: Size().mb,
                backgroundColor: cor_clara
                , borderRadius: 4, borderStyle: 'solid',
                borderColor: cor_clara
            }}
            
        >
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
            <Typography variant="h3" sx={{mr: 3, color: cor_escura, fontWeight:600}} >
            <CountUp 
                className='contador'
                end={num}
                separator="."
                duration={3} />
            </Typography>

            {icon}
            
            </Box>

            <Typography sx={{color: cor_escura, fontWeight:500}} >
                {texto}
            </Typography>
        </Box>
    );
}

export default Card;