import { chamarApi} from '../../services/service.api';

export function Enviar (data, callback) {
    
    const getResult = async () => {
        
        try {
               
            const result = await chamarApi ({type: 'post', endpoint: 'creditosPagamento', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        
    getResult(); 

}