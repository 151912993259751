import { BarChart, barLabelClasses } from '@mui/x-charts';
import Card from '../../../views/components/view.card';
import { Box, Grid, useTheme } from '@mui/material';
import Enunciado from '../../../pages/Questoes/Questao/view.enunciado'
import CardTitulo from '../../../views/components/view.card.titulo';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import { useState } from 'react';

export default function BarChartPctAcertosPorQuestao (props) {
    const theme = useTheme();
    const [dataset] = useState (Dataset(props.questoes))

    
    return (

        <>
        <Box sx={{width: 'auto'}}>
        <Card titulo={<CardTitulo titulo='Acertos' icon={<MilitaryTechOutlinedIcon />} help slide
        tooltip={<span>O gráfico mostra a porcentagem de acertos de cada questão.<br/><br/>
          
          Obs: apenas as questões de múltipla escolha são consideradas.<br/><br/>
          Dica: passe o mouse sobre a barra do gráfico que representa a questão para revelar o enunciado completo da questão analisada.
          
          </span>}
        />}  margin_top={20} fontSizeTitulo="1.2rem" divider>

            <Box sx={{mt:-4}}>
            
            <BarChart 
              dataset={dataset}
              yAxis={[{categoryGapRatio: 0.3, scaleType: 'band', dataKey: 'y',  
                
                valueFormatter: (x, context) =>
                  context.location === 'tick'
                    ? `${x}`
                    : `Questão ${x} - ${dataset[x-1].x}% de acertos`  
              ,
              
                
              }]}
              series={[{ dataKey: 'x', ry: 5,  valueFormatter: (v, { dataIndex }) => {
                   
                
                  return ( <>
                  
                  <Grid container sx={{maxWidth: 500}}>
                  <Grid item sx={{mt:-1}}>
                  <Enunciado enunciado = {props.questoes[dataIndex].enunciado_completo} />
                  </Grid>
                  
                  </Grid>
                  
                  </>
                  )
                },
                
                }]}
              layout="horizontal"
              bottomAxis={{ disableTicks: true, label: 'Porcentagem %' }}
              leftAxis={{ disableTicks: true, label: 'Questão',
                  

               }}
              xAxis={[{
                colorMap: {
                  type: 'piecewise',
                  thresholds: [50, 70],
                  colors: [theme.palette.chart.vermelho, theme.palette.chart.amarelo, theme.palette.chart.verde],
                }
              }]}
              tooltip={{trigger: 'axis'}}
              grid={{ vertical: true }}
              
              borderRadius={7}
              
              barLabel={(item, context) => {
                let pct = dataset[item.dataIndex].x 
              if (pct > 0) {
                return pct + ' %'
              }
              return null
              }}
              height = {100 + props.questoes.length * 50}
              sx={{[`& .${barLabelClasses.root}`]: {
                fill: 'white',
                
              },}} 
              
             
            />
                
            </Box> 
        </Card>
        </Box>
        </>
    )
}   

const Dataset = (questoes) => {
  
  let data = [];

  questoes.forEach ((questao) => {

    if (questao.tipo === 1) {
    let total = questao.marcacoes.reduce((acumulador, valorAtual) => acumulador + valorAtual, 0);
    let acertos = questao.marcacoes [LetraIndex(questao.gabarito_objetiva)]
    let pct = 100 * acertos / total
    if (total === 0) pct = 0.0
    data.push ({y: questao.num_questao + 1, x: parseInt(pct.toFixed (0))})
    }
  })
  
  return data
 
}

function LetraIndex (letra) {

  switch (letra) {
      case 'A': return 0;
      case 'B': return 1;
      case 'C': return 2;
      case 'D': return 3;
      case 'E': return 4;
}
}
