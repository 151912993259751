import { Alert, AlertTitle, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Switch, Divider, Typography } from '@mui/material';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

export default function BaixarProvaView (props) {
    
    const { open, baixado } = props 
    
    return (
    <>
    
    <Dialog open={open} >
      
      <ModalTitle />
      
      <DialogContent>
        <DialogContentText>
      
        <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
        
          <Icone />
          {!baixado && <PrimeiraMensagem mensagem={'Clique em BAIXAR para obter o arquivo da lista como documento do Word.'} />}
        
        </Grid>  
        
        </DialogContentText>
        
        {baixado && <MensagemArquivoBaixadoComSucesso />}    
    
    </DialogContent>
    
    <DialogActions>
                
        <Button onClick={(e) => props.onClose (e)} disabled={!baixado}>Sair</Button>
        <Button onClick={(e) => props.handleBaixar (e)} disabled={baixado} >Baixar</Button>

    </DialogActions>
    
    </Dialog>
    
    </>
    )
}

function ModalTitle () {
  return (
  <DialogTitle>
      
        Lista produzida com sucesso!
        <Divider /> 
      
  </DialogTitle>
  )
}

function Icone () {
  return (
    <Grid item xs={12} >
      
      <CheckCircleOutlineSharpIcon color="success" sx={{fontSize: "5em"}}></CheckCircleOutlineSharpIcon>
      
    </Grid>
  )
}

function PrimeiraMensagem ({mensagem}) {
  return (
    <Grid item xs={12} mt={3} >
        <Typography > 
          {mensagem}
        </Typography>
    </Grid>
  )
}

function MensagemArquivoBaixadoComSucesso () {
  return (
    <Alert sx={{mt:2}} severity="success" variant='filled'>
      <AlertTitle>Finalizado!</AlertTitle>  
        O arquivo das questões se encontra na pasta Downloads
    </Alert>
  )
}