import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@mui/material';
import { Fab, Tab, Tabs, AppBar } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';

import { QuestoesContext } from '../../services/service.questoes.provider';
 
// MEUS COMPONENTES
import SiteFramework from '../../components/site/view.site-framework.jsx'
import Loading from '../../components/Loading/view.loading.jsx';
import Loading2 from '../../components/Loading/view.loading2.jsx';
import Filtros from './Filtros/modulo.filtro.jsx';
import Tags from './Tags/controller.tag.jsx';
import Alerta from '../../views/components/view.alerta.jsx';
import BotoesQuestoes from './BotoesQuestoes.jsx';
import BotoesNavegacaoQuestoes from './BotoesNavegacao/modulo.botoes.navegacao';
import ExibirQuestoes from './Questao/Questao.jsx';
import SnackbarAviso from '../../components/SnackBar/SnackBarAviso.jsx';

export default function QuestoesMobile (props) {
    
    const [btnEnabled, setBtnEnabled] = useState (false);
    
    const { questoesControle, filtros, disciplinaEscolhida, questoes } = useContext (QuestoesContext);

    const { mostrarQuestoes, terminouLoading, setTerminouLoading, show, theme, 
            loadingQuestoes, setAlinhamento, RetornaFonteAno, alinhamento, onFinishLoading, handleBuscarQuestoes, 
            handleBuscarQuestoes2, tagsEscolhidas, tema, handleChange, value
     } = props
    
    const style = {
        margin: 0,
        top: 'auto',
        right: 25,
        bottom: 30,
        left: 'auto',
        position: 'fixed',
    };
    
    

    return <>
    
    <SiteFramework 
        children = {<>
         <Loading tipo="normal" loading={terminouLoading} onlineStatus ={(valor) => setTerminouLoading ({...terminouLoading, online: valor})} show={show} msg="Buscando os assuntos..."/>
           {questoesControle.escolhida && <SnackbarAviso variant='filled' open={questoesControle.escolhida} time={1500} transition='Grow'   posicao={{vertical: "top", horizontal: "left"}} /> }
           <Box sx={{  ml:-2, mr:-2, mt:-3 }}>
      <AppBar position="sticky"
       sx={{boxShadow: 'none'}}
      >
  
        <Tabs 
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
         
          variant="fullWidth"
          aria-label="full width tabs example"
          keepMounted={true}
          sx={{backgroundColor: tema.palette.background.default}}
        >
          <Tab label="Filtros" {...a11yProps(0)} />
          <Tab label="Assuntos" {...a11yProps(1)} />
          <Tab label="Questões" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      
        <TabPanel keepMounted={true} value={value} index={0} dir={theme.direction} >
        </TabPanel>
        
        
        <TabPanel keepMounted={true} value={value} index={1} dir={theme.direction}>
        </TabPanel>
       
        <TabPanel keepMounted value={value} index={2} dir={theme.direction}>
      </TabPanel>
       
        <div style={{ display: value===0 ? 'block' : 'none', marginTop: '-2em', marginLeft: '1em', marginRight:'1em' }}> 
        <Loading2 local="full" loading={loadingQuestoes} online={loadingQuestoes.online} msg='Procurando as questões...'  tipo="imagem"/> 
        {questoesControle.total === 0 && mostrarQuestoes &&
            <Box sx={{mt:-1.5, mb:3}}>
                <Alerta tipo="info" msg='Refine a sua busca' titulo="Nenhuma questão encontrada com esses filtros" variant="filled"/>
            </Box>
            }
          
            <Filtros onFinishLoading = {(resultado, erro, msg) => onFinishLoading (resultado, erro, msg, 'filtro')}/>
          
          
        </div>
        
        <div style={{ display: value===1 ? 'block' : 'none', marginTop: '-2em', marginLeft: '1em', marginRight:'1em' }}> 
        <Loading2 local="full" loading={loadingQuestoes} online={loadingQuestoes.online} msg='Procurando as questões...'  tipo="imagem"/> 
        {questoesControle.total === 0 && mostrarQuestoes &&
            <Box sx={{mt:-1.5, mb:3}}>
                <Alerta tipo="info" msg='Refine a sua busca' titulo="Nenhuma questão encontrada com esses filtros" variant="filled"/>
            </Box>
            }
          <Tags onFinishLoading = {(resultado, erro, msg) => onFinishLoading (resultado, erro, msg, 'tag')}   />
        </div>
        
        <div style={{ display: value===2 ? 'none' : 'block'}}>
        <Fab style={style} onClick={() => {handleBuscarQuestoes (value) }} variant="extended" size="small" color="primary" disabled={tagsEscolhidas.length===0 ? true : false}>
           <NavigationIcon sx={{ mr: 1 }} />
              Buscar questões
        </Fab>
        </div>

        <div style={{ display: value===2 ? 'block' : 'none', marginTop: '-3em', marginLeft: '1em', marginRight:'1em', marginBottom: '4em' }}> 
        <Loading2 local="conteudo" loading={loadingQuestoes} online={loadingQuestoes.online}  tipo="imagem"/> 
        {questoes!==null && questoes[questoesControle.atual-1]!==undefined && questoesControle.total > 0 && mostrarQuestoes && loadingQuestoes.isSuccess &&
                      <>
                      
                
                      <Grid sx={{mt:-1.5, ml: -2, mr:-2, borderRadius:0, backgroundColor: `${theme.palette.background.default}`, width:'100%', position:"fixed", bottom: 0}} item xs={12} >
                        {<> 
                          <BotoesNavegacaoQuestoes escolhida={questoesControle.escolhida} numEscolhidas={questoesControle.numEscolhidas} 
                                                  btnEnabled = {btnEnabled} onBuscarQuestoes= {() => handleBuscarQuestoes2()}
                                                  btnGerar={<BotoesQuestoes mostrarBtnAssuntos={mostrarQuestoes} onChange={(value) => setAlinhamento (value)}/>}
                                                   /></>}
                    </Grid>
                      <Box sx={{mt:2}}>
                      <ExibirQuestoes enunciado={questoes[questoesControle.atual - 1].enunciado} gabarito_comentado={questoes[questoesControle.atual - 1].gabarito_comentado} gabarito={questoes[questoesControle.atual - 1].gabarito} 
                                      gabarito_objetiva = {questoes[questoesControle.atual - 1].gabarito_objetiva} gabarito_discursiva={questoes[questoesControle.atual - 1].discursiva}
                                      tipo = {questoes[questoesControle.atual - 1].tipo}	
                                      atual={questoesControle.atual} total={questoesControle.total} fonte_ano= {disciplinaEscolhida.idSegmento === 4 ? RetornaFonteAno(filtros, questoes, questoesControle) : ''}
                                      alinhamento={alinhamento}
                      />
                       
                      </Box>
                      
                      </>
                  }  
         </div>         
    </Box>

    </>} />
    </>
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        
      role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3}} >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  