import { useContext, useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { styled } from '@mui/system';
import { StyledNavLink } from "./view.navbar.config";
import { TemasContext } from "../../services/service.temas.provider";
import { AbreFechaMenu } from "./controller.navbar";
import MenuUsuario from "./controller.menu.usuario";
import PersonIcon from '@mui/icons-material/Person';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useTela } from "../../services/service.tela.provider";
import { NavLink } from "react-router-dom";
import { grey } from "@mui/material/colors";

export default function BtnUsuario ({theme}) {
    
    const [open, setOpen] = useState (false);
    const [openTooltip, setOpenTooltip] = useState (false);
    const { mode } = useContext ( TemasContext );
    const [anchorEl, setAnchorEl] = useState(null); // menu de mudar tema
    const [top, setTop] = useState(0); 
    const [left, setLeft] = useState(0);
    const [tela, setTela] = useTela ();

    function handleClick (event) { 
        handleClose();
        AbreFechaMenu (event, anchorEl, setTop, setLeft, setOpen, open, setAnchorEl)
        
}
    
    const handleClose = () => {
        setOpenTooltip (false);
    };

    const handleOpen = () => {
        setOpenTooltip (true);
    };
 
    return (
        <>
            
            <Tooltip title={'Usuário'} open={openTooltip} onClose={handleClose} onOpen={handleOpen} arrow>
              <IconButton onClick={handleClick}>
                <StyledNavLink to="#" eClicado = {eClicado (tela) ? true : false}  >
                    
                    <PersonIcon />
                
                </StyledNavLink>
               </IconButton>
            </Tooltip>    
            <MenuUsuario anchorEl={anchorEl} open={open} top={top} left={left} setOpen={setOpen} options={options} />
        </>
    )
}

const options = [
    ['Editar', false, '/usuario/editar', <EditOutlinedIcon fontSize="small" />],
    ['Contato', true, '/usuario/contato', <MessageOutlinedIcon fontSize="small" />],
    ['Sair', false, '/usuario/logout', <LogoutOutlinedIcon fontSize="small" />]
  ];

  function eClicado (tela) {
   
    if (tela.atual === 'Editar' || tela.atual === 'Contato' || tela.atual === 'Sair')
        return true;

    return false;
}

