import { Chip, Grid } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';

export default function ChipEscolhidas (props) {
    
    return (

        <>
        {!props.isMobile ?
        <Grid item >
        
            <Chip icon={<CheckIcon />} label={props.numEscolhidas} variant="outlined" />
        
        </Grid> 
        :
        <Grid item >
        
            <Chip label={props.numEscolhidas} variant="outlined" />
        
        </Grid> 
}
        </>
    )
}