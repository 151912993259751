import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import {Grid, Button} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import SnackbarAviso from "../SnackBar/SnackBarAviso";
import * as modelPix from './model.pix.js'

export default function ControllerPix (props) {

    const [loading, setLoading] = useState ({inicio: false, fim: false, isSuccess: false})
    const [openSnackErro, setOpenSnackErro] = useState (false);
    const [openSnackErroPagamento, setOpenSnackErroPagamento] = useState (false);
    const [openSnackSucesso, setOpenSnackSucesso] = useState (false);
    const navigate = useNavigate ();

    useEffect (() => {
        
        if (loading.inicio === false) {
            if (loading.fim && loading.isSuccess=== true && loading.result === 'approved') {
                setOpenSnackSucesso (true);
            }
            if (loading.fim && loading.isSuccess=== false) {
             
                setOpenSnackErro (true);
            }
            if (loading.fim && loading.isSuccess=== true && loading.result === 'pending') {
                setOpenSnackErroPagamento (true);
            }
        }

    }, [loading.inicio])
    
    const handleConfirmarPagamento = () => {

        setLoading ({inicio: true, fim: false, isSuccess: false})
        
        modelPix.Consultar (props.id_pagamento, async function (result) {

           if (result.statusCode && result.statusCode === 200) {
            if (result.response === 'approved') {
                setLoading ({inicio: false, fim: true, isSuccess: true, result: 'approved'})
                props.onFinish (true);
            } else setLoading ({inicio: false, fim: true, isSuccess: true, result: 'pending'})
           } else {
            setLoading ({inicio: false, fim: true, isSuccess: false})
           } 

        })


    }
    
    return (


        <>
        
        <BtnConfirmar loading={loading} onClick={handleConfirmarPagamento} id_pagamento={props.id_pagamento}/>
        
        {openSnackErro && 
            
            <SnackbarAviso open={openSnackErro} color='vermelho.main' tipo='error' msg='Não foi possível a conexão com os nossos servidores' onClose={() => setOpenSnackErro (false)} />
           
        }

        {openSnackErroPagamento &&
        
            <SnackbarAviso open={openSnackErroPagamento} color='info.main' tipo='warning' msg='Pagamento não confirmado' onClose={() => setOpenSnackErroPagamento (false)} />
        
        }

        {openSnackSucesso &&
            
            <SnackbarAviso open={openSnackSucesso} color='verde.main' msg='Pagamento confirmado com sucesso!' onClose={() => setOpenSnackSucesso (false)} />
        }

        </>
    )
}

function BtnConfirmar (props) {

    return (
  
      <>
      <Grid item>
      <LoadingButton variant = 'outlined' loading={props.loading.inicio===true ? true : false} onClick={props.onClick}>
        Confirmar pagamento
      </LoadingButton>
      </Grid>
      </>
    )
  }