import ModalSucessoErro from "../../components/ModalSucessoErro/ModalSucessoErro"
import { Typography } from "@mui/material"

export default function ModalSemCreditos (props) {
    const {open, questoes_total, creditos, setOpen, handleComprarCreditos} = props
    return (

        <>
        
        <ModalSucessoErro open={open} titulo={'Créditos insuficientes'} 
        
        msg={<>
             <Typography component='div'>
               Apenas o primeiro link é gratuito. Para criar um novo link são necessários metade das questões da lista em créditos.
             </Typography>
             
             <br/>
             
             <Typography  fontWeight={500}>
               {'Total de questões nesta lista: ' + questoes_total}
             </Typography>
             
             <Typography component='div'fontWeight={500}>
               {'Créditos necessários: ' + (parseFloat (Math.floor(questoes_total/2)).toFixed (0))}
             </Typography>
             <Typography component='div'fontWeight={500}>
               {'Seus créditos: ' + creditos}
             </Typography>
             <br/>
             <Typography component='div' textAlign={'center'}>
               Deseja comprar mais créditos?
             </Typography>
        </>}
        success = {false}
        data = {{btn1: {mostrar: true, label: 'não'}, btn2: {mostrar: true, label: 'sim'},
        
        errorTipo: 'warning'
      }}
      onClose = {() => setOpen (false)}
      onClickBtn2 = {() => handleComprarCreditos()}
        />

        </>
    )
}