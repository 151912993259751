import { useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Slide } from '@mui/material';

import * as Credito from './controller.credito.js'

import SelecionarCreditos from './view.selecionar.creditos.jsx'
import ResumoPedido from './view.resumo.pedido.jsx'
import HistoricoCreditos from './view.historico.creditos.jsx'

import SiteFramework from '../../components/site/view.site-framework.jsx'

import { useTela } from '../../services/service.tela.provider.jsx';

export default function Creditos () {
  
  const [tela, setTela] = useTela();  
  const location = useLocation();
  
  const [valor, setValor] = useState (0);
  const creditos = useRef (!localStorage.getItem('creditosInfo') ? 1200 : JSON.parse (localStorage.getItem('creditosInfo')).valor);
  const periodos = useRef (!localStorage.getItem('creditosInfo') ? 12 : JSON.parse (localStorage.getItem('creditosInfo')).periodo);
  
  const valorPorCredito = useRef (0);
  
  const navigate = useNavigate();
  
  function onChangeSelecionar (credito, periodo) {
    
    creditos.current = credito;
    periodos.current = periodo;
    valorPorCredito.current = Credito.ValorPorCredito (valor, credito);
    UpdateLocalStorage ();
    setValor(Credito.Total (credito, periodo));
 
  }
     
  // UPDATE NO LOCAL STORAGE OS VALORES DE CREDITOS E PERIODOS
  async function UpdateLocalStorage () {
    localStorage.setItem ('creditosInfo', JSON.stringify ({valor: creditos.current, periodo: periodos.current, transaction_amount: valor}));
  }

  // CHAMA A PÁGINA DE PAGAMENTO AO CLICAR EM COMPRAR
  const handleOpenPagamento = () => {
    UpdateLocalStorage ();
    
    if (tela.anterior === 'Questões') {navigate ('/pagamento')} 
    if (tela.anterior === 'Listas')   {navigate ('/pagamento', {state: location.state})} 
    navigate ('/pagamento')
  }
  
  
  return (
    <>
      
        <Grid container spacing={{ xs: 2, md: 3 }} rows={{ xs: 1, lg: 2 }}>

          {/* ************** CARD SELECIONAR CRÉDITOS ************** */}  
            <Slide in={true} direction={'right'} timeout ={1200}>
            <Grid item xs={12} lg={7}>
                
              <SelecionarCreditos  creditos = {creditos} periodos = {periodos}  onChange={(credito,  periodo) => onChangeSelecionar(credito, periodo)} />    

            </Grid>
            </Slide>
          {/* ************** CARD RESUMO ************** */}
          <Slide in={true} direction={'left'} timeout ={1200}>
            <Grid item xs={12} lg={5}>
                
              <ResumoPedido creditos = {creditos} periodos = {periodos} valorPorCredito = {valorPorCredito} valor={valor} onClick={() => handleOpenPagamento}/>

            </Grid>
         </Slide>
         {/* ************** CARD HISTORICO ************** */}
         <Slide in={true} direction={'up'} timeout ={1200}>
            <Grid item xs={12} sm={12}>
      
              {<HistoricoCreditos />}
      
            </Grid>  
      </Slide>
      </Grid>  

    
   </>       
  );
}
