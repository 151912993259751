import { useContext, useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { StyledNavLink, StyledBadge } from './view.navbar.config';
import { TemasContext } from "../../services/service.temas.provider";
import { AbreFechaMenu } from "./controller.navbar";
import MenuMudaTema from "./controller.menu.tema";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

export default function BtnTema () {
    
    const [open, setOpen] = useState (false);
    const [openTooltip, setOpenTooltip] = useState (false);
    const { mode } = useContext ( TemasContext );
    const [anchorEl, setAnchorEl] = useState(null); // menu de mudar tema
    const [top, setTop] = useState(0); 
    const [left, setLeft] = useState(0);

    function handleClick (event) { 
        handleClose();
        AbreFechaMenu (event, anchorEl, setTop, setLeft, setOpen, open, setAnchorEl)
        
}
    
    const handleClose = () => {
        setOpenTooltip (false);
    };

    const handleOpen = () => {
        setOpenTooltip (true);
    };
 

    function icon () {
        
           if (mode === 'light') {return <LightModeIcon fontSize="medium" onClick={(event) => handleClick(event)} />} else {return <DarkModeIcon fontSize="medium" onClick={(event) => handleClick(event)}/>}
      }

    return (
        <>
            
            <Tooltip title={'Tema'} open={openTooltip} onClose={handleClose} onOpen={handleOpen} arrow>
              <IconButton onClick={handleClick}>
                <StyledNavLink to="#" activeClassName="active" >
                    
                    { icon () }
                    
                
                </StyledNavLink>
               </IconButton>
            </Tooltip>    
            <MenuMudaTema anchorEl={anchorEl} open={open} top={top} left={left} setOpen={setOpen} />
        </>
    )
}