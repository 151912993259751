import { TransitionGroup } from 'react-transition-group';
import { Box, Slide,  Grid, Typography, Collapse } from '@mui/material'
import Opcao from './view.opcao'
import { onClick, estaMarcado } from './controller.opcao'
import OpcaoRespostasGabarito from './view.opcao.respostas.gabarito'
import { useEffect, useState } from 'react'

export default function Opcoes (props) {
    
    const { aluno, setAluno, atual, setTotalRespondidas, resultado, gabarito_liberado } = props
    const [ marcado, setMarcado] = useState ([false,false, false, false, false])
    const [fadeKey, setFadeKey] = useState(0);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setFadeKey(prevKey => prevKey + 1);
        setChecked (true);
      }, [atual]);
    
   
      useEffect (() => {
    
    setMarcado (aluno.questoes[atual-1].marcado)

    }, [aluno.questoes[atual-1].marcado])
    
    
    return (

        <>
        <Box sx={{paddingBottom:1}}>
        <TransitionGroup>
        {props.opcoes.map ((o, index) => {
            
            return (
                <Collapse key={o}  timeout={1000} direction='right'>
                <Box sx={{mb:2}}>
        
                    {resultado ?
                    <OpcaoRespostasGabarito key = {index} texto = { o } item = {index} 
                           resposta = {aluno.questoes[atual-1].resposta} gabarito_objetiva = {aluno.questoes[atual-1].gabarito_objetiva} 
                           gabarito_liberado = {gabarito_liberado} resultado={resultado} />
                    :
                    <Opcao key = {'opcap' + index} texto = { o } item = {index} onClick = {(letra, item) => onClick (atual, letra, aluno, setAluno, setTotalRespondidas, item, setMarcado, marcado)} 
                    resposta = {aluno.questoes[atual-1].resposta} 
                    gabarito_liberado = {gabarito_liberado} resultado={resultado} marcado = {aluno.questoes[atual-1].marcado} />
        }        
                </Box>
                </Collapse>
                
            )
        })
    }
    </TransitionGroup>
    </Box>
        </>
    )
}