import { Get, Set } from '../../../../services/service.local-storage';
import * as modelProva from './model.gerar.prova'

export function Gerar (e, setLoading, dados, setOpen, props, arquivo, condicoes, disciplinaEscolhida, questoesEscolhidas, questoes) {
  
    console.count ('Gerar')  

    e.preventDefault ();
    setLoading ({inicio: true, fim: false, isSuccess: false});
    
    dados.current.nome = arquivo;
    dados.current.condicoes = condicoes.current;

    dados.current.id_disciplina = disciplinaEscolhida.idDisciplina
    dados.current.id_segmento = disciplinaEscolhida.idSegmento;
    // Determina apenas os ids das questões
    dados.current.id_questoes = questoesEscolhidas.map (item => item.id);
    dados.current.tipo = dados.current.condicoes.arquivo_word ? 'docx' :  'link';

    // CHAMA A API
    
    modelProva.Gerar (dados.current, async (result) =>  {
      
      //setLoading (result);
      
      if (result.isSuccess) {
        
        dados.current.isSuccess = true;
        dados.current.resposta = result.response;
        dados.current.arquivo = arquivo;
        dados.current.id_lista = parseInt(result.id_lista);
        let listas = await Get ('listas_questoes', 'array');
        
        let id = dados.current.condicoes.arquivo_word ? parseInt (dados.current.id_lista) :  dados.current.resposta.id_lista
        listas.push ({id: id, questoes: RetornarQuestoesPeloID (questoes, questoesEscolhidas) })
        
        const a = await Set ('listas_questoes', listas, 'array')
        
        props.onClose ({gerada: dados.current.isSuccess, nome: dados.current.arquivo, data: dados.current.resposta, gerada_com_word: dados.current.condicoes.arquivo_word ? true : false, id_lista: id});
        setOpen(false);
      
      }
  })
    
}

function RetornarQuestoesPeloID (questoes, questoesEscolhidas) {
  
  return questoes.filter(questao => 
    questoesEscolhidas.some(escolhida => escolhida.id === questao.id)
  );

}


export const handleCloseComWord = (props, dados, setOpen) => {
        
    props.onClose ({gerada: dados.current.isSuccess, nome: dados.current.arquivo, data: dados.current.resposta, gerada_com_word: true});
    setOpen(false);
    
};

export const handleCloseSemWord = (props, dados, setOpen) => {
        
  props.onClose ({gerada: dados.current.isSuccess, nome: dados.current.arquivo, data: dados.current.resposta, gerada_com_word: false});
  setOpen(false);
  
};