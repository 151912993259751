import { useEffect, useState } from "react"
import { Box, useTheme } from "@mui/material"
import Titulo from "../Enunciado/view.titulo"
import ProgressoView from "./view.progresso"
import DrawerBottom from "../../../../components/DrawerBottom/view.drawer.bottom"
import { onClick } from "../Opcao/controller.opcao"
export default function Progresso (props) {
    
    const [progresso, setProgresso] = useState (100 * props.totalRespondidas / props.totalValidas)
    const [open, setOpen ] = useState (false)

    useEffect (() => {
        
        setProgresso (100 * props.totalRespondidas / props.totalValidas)
    
    }, [props.totalRespondidas]
    
    )
    
    const theme = useTheme ();
    const bgColor = theme.palette.background.paper
    
    function onClick (valor) {

       // setOpen (valor)

    }

    return (
        <>
        {open ?
        <DrawerBottom open = {true} onClick={() => setOpen(false)} />
        : null}
        
        <Box sx = {{p: 1, ml: 1, mr: 1, mb: -1, mt: 1, backgroundColor: bgColor, borderRadius: '16px 16px 0px 0px'}} >

        <ProgressoView progress = {progresso} onClick={(valor) => onClick(valor)} {...props} />
         
        </Box>

        </>
    )
}