import { useState } from 'react';
import { TextField } from '@mui/material';

function GenericField(props) {
  
  const { disabled, autofocus, label, helperText } = props;
  const [valor, setValor] = useState(props.valor ? props.valor : '' );
    
  
    function Atualiza (e) {

      setValor (e.target.value);
      props.onChange (e.target.value)

    }

  return (
    <TextField
    size={"small"}  
    disabled={disabled}
    required
    fullWidth
    autoFocus={autofocus}
    margin="normal"
    name={label}
    label={label}
    value={valor}
    onChange={(e) => Atualiza(e)} 
    helperText={helperText}
    />
  );

} export default GenericField;

GenericField.defaultProps = {
  
  disabled: false,
  autofocus: false,
  
  helperText: null

}
