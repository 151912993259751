import { useState } from 'react';

import { TextField, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { Enviar } from './controller.contato';

export default function FormularioContato (props) {
    
    const { loading, setLoading, usuario, setOpen } = props

    const [botaoLigado, setBotaoLigado] = useState(false);
    const [dados, setDados] = useState ({msg: ''});
      
    function AtualizaCampos (e, setDados, setBotaoLigado) {
      
      setDados ({ msg: e.target.value });
      
      if (e.target.value.trim()) {
      
        setBotaoLigado(true);
      
      } else {
      
        setBotaoLigado(false);
      
      }
  }
    
    return <>
    
    <TextField
            sx={{mt: 3}}
            fullWidth
            id="mensagem"
            label="Escreva a sua mensagem aqui"
            variant="outlined"
            value={dados.msg}
            onChange={(e) => AtualizaCampos (e, setDados, setBotaoLigado)}
            multiline
            rows={4} />
          
          <Box display="flex" justifyContent="center" sx={{ mt: 3, mb:2 }}>
            
            <LoadingButton
              disableElevation
              loading={loading.inicio}
            
              variant="contained"
              color="primary"
              disabled={!botaoLigado}
              onClick={(e) => Enviar (e, dados, loading, setLoading, usuario, setOpen)}
            >
              <span>Enviar</span>
            </LoadingButton>
          
          </Box>

    </>
}