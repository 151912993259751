import { useContext, useState, useRef } from "react";
import { VariaveisContext } from "../../services/service.variaveis.provider";
import { QuestoesContext } from "../../services/service.questoes.provider";	
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Grid } from "@mui/material";
import ModalGerarProva from "./Prova/GerarProva/GerarProva";
import BaixarProvaModal from "./Prova/BaixarProva/BaixarProvaModal";
import BotaoGerarProva from "./view.botao.gerar.prova";
import FinalizarGeracao from "./Prova/FinalizarGeracao/FinalizarGeracao";
import ArranjoEnunciadoGabarito from "./view.arranjo.enunciado.gabarito";

function BotoesQuestoes (props) {
    
    const navigate = useNavigate ();
    const { isMobile } = useContext (VariaveisContext);
    const { questoesEscolhidas, usuario } = useContext (QuestoesContext);
    const [gerarProva, setGerarProva] = useState (false);
    const [baixarProva, setBaixarProva] = useState (false);
    const [finalizarGeracao, setFinalizarGeracao] = useState (false);
    const prova = useRef ({});

    function handleGerarProva (e) {
        
        e.preventDefault ();
        setGerarProva (true);
      
    }

      function handleFecharGerarProva (pr, dados) {
        console.log ('pr', pr)        
        prova.current.nome = pr.nome;
        prova.current.data = pr.data;
        prova.current.id_lista = pr.id_lista
        
        if (pr.gerada) {
            
            if (pr.gerada_com_word) {
                setBaixarProva (true);    
            } else {
                setFinalizarGeracao (true);    
            }
            
        
        } 
        
            setGerarProva (false);
        
    }
    
    function handleFecharBaixarProva () {
        
        
        
    }
    return (
        <>
        {isMobile ? 
            
        <BotaoGerarProva onClick={(e) => handleGerarProva (e)}  disabled={questoesEscolhidas.length===0 ? true : false} isMobile = {isMobile} />

        :    
            
        <Card variant="outlined" sx={{mt:-3, borderRadius:2, borderStyle: 'none'}}>
            
            <CardContent sx={{mt:-0.5, mb:-1.5, ml:2, mr:2}} >
            
                <Grid container direction="row" alignItems="center" spacing={3} justifyContent="space-between">
                
                <BotaoGerarProva onClick={(e) => handleGerarProva (e)}  disabled={questoesEscolhidas.length===0 ? true : false} />
                

                {!isMobile &&  
                <Grid item sx={{ml:1}}>
                    <ArranjoEnunciadoGabarito onChange={(value) => props.onChange (value)}/>
                </Grid>
}   
                </Grid> 

                

            </CardContent>
            
        </Card>
        }
        
        {gerarProva && <ModalGerarProva onClose={(pr, dados) => handleFecharGerarProva(pr, dados)} />}
        {baixarProva && <BaixarProvaModal prova={prova.current} usuario={usuario} setBaixarProva={(valor) => setBaixarProva (valor)} onClose={handleFecharBaixarProva()}/>}
        {finalizarGeracao && <FinalizarGeracao prova={prova.current} usuario={usuario}/>}
        </>
    );

} export default BotoesQuestoes;