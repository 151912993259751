
import { Box, Slide, Grow } from '@mui/material';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import Titulo from './view.home.titulo.jsx';
import CardStats from './view.home.card.jsx';
import Card from '../../views/components/view.card.jsx';
import CardTitulo from '../../views/components/view.card.titulo.jsx';

import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';

export default function HomeCards (props) {
    
    const {usuario, isMobile} = props;

    return <>
    
    <Card titulo={<CardTitulo icon={<WavingHandOutlinedIcon />}  titulo = 'Bem-vindo!' slide/>}  margin_top={20} fontSizeTitulo="1.2rem" divider >
    
    <Slide in={true} direction='right' timeout={700}>
    <Box>
    <Titulo />
    </Box>
    </Slide>
        
        <Grow  in={true} timeout={1200}>
        <Box sx ={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...isMobile && {justifyContent: 'center'}, ...!isMobile && {justifyContent: 'flex-start'}, flexWrap: 'wrap', mt: 5,  gap: 2 }} >
          
          <CardStats num={usuario.numprovas}  icon={<FormatListNumberedOutlinedIcon sx={{color: "success.main"}} fontSize="large" />} texto="Listas criadas" cor_clara='success.light' cor_escura='success.dark' />
          <CardStats num={usuario.numquestoes} icon={<InsertChartOutlinedIcon sx={{color: "info.main"}} fontSize="large" />} texto="Questões utilizadas" cor_clara='info.light' cor_escura='info.dark'/>
          <CardStats num={usuario.numcreditos} icon={<ShoppingBagOutlinedIcon sx={{color: "error.main"}} fontSize="large" />} texto="Créditos" cor_clara='error.light' cor_escura='error.dark'/>
        
        </Box>
        </Grow>
        </Card>
    </>

}