import { useLocation } from 'react-router-dom';
import * as modelAluno from './model.aluno.questao'
import { useState, useRef } from "react";
import { useLocalStorage } from "../../../services/service.hooks";
import { dataAtual } from "../../../services/service.data";
import ListaNaoLiberada from './NaoLiberada/modulo.lista.nao.liberada';
import { separarEnunciadoGabaritoObjetiva, inserirMarcadoFalsoQuestoes } from './controller.aluno.lista';
import AlunoLista from './Lista';
import AlunoResultado from './Resultado/Resultado';


function AlunoQuestao (props) {
    
    const location = useLocation();
    const [ aluno, setAluno ] = useLocalStorage ("aluno")
    const loading = useRef ({inicio: true, fim: false, isSuccess: false})
    const [ resultado, setResultado ] = useState (false)
   
    console.log (location)
    
    if (location.state === 'resultado') {
        
        loading.current.isSuccess = false
        loading.current.inicio = true
        loading.current.fim = false
    }
    
    function onFinishLoading (result) {
        
        if (result.fim) {
             
            if (!result.isSuccess) {
                if (result.msg === "O link não existe") {
                    return <h3>  Infelizmente essa lista não existe mais! Fale com o seu professor.</h3>
                }
            }
            
            if (result.isSuccess) {
                console.log ('questies aklui', result.response)
                let resultado = {}
                
                
                resultado.encerrada_pelo_prof = result.response.status.lista_encerrada 
                resultado.liberada_pelo_prof  = result.response.status.lista_liberada 
                resultado.aluno_iniciou       = result.response.status.aluno_iniciou 
                resultado.aluno_terminou      = result.response.status.aluno_terminou 
                resultado.gabarito_liberado   = result.response.status.gabarito_liberado 
                resultado.lista_inicio        = result.response.lista_inicio
                resultado.lista_termino       = result.response.lista_termino 
                
                let a = {...aluno}
                a.status = result.response.status
                a.lista_inicio        = result.response.lista_inicio
                a.lista_termino       = result.response.lista_termino 
                
                console.log ('a2', a)
                if (result.response.nota) {
                    a.nota = result.response.nota     
                    resultado.nota = result.response.nota
                    console.log (resultado)
                  //  setAluno ({...aluno, nota: resultado.nota})
                }
                
                //localStorage.setItem('aluno', JSON.stringify(a));
                //setAluno ({...aluno, lista_inicio: result.response.lista_inicio, lista_termino: result.response.lista_termino, status: result.response.status ? result.response.status : ''})

                if (result.response.questoes) {

                    let b = separarEnunciadoGabaritoObjetiva (result.response.questoes); 
                    b = inserirMarcadoFalsoQuestoes (b);
                    
                   a.questoes = b
                
                } 
                
                
                
                location.state = ''
                resultado = a
                setAluno (a)
                console.log ('resultado', a)

                // LISTA EXISTE E LIBERADA E ALUNO JA TERMINOU MAS GABARITO APENAS APOS LISTA ENCERRADA - enviar questoes e resultado
                if (resultado.status.lista_liberada && !resultado.status.lista_encerrada && resultado.status.aluno_terminou && !resultado.status.gabarito_liberado) return <AlunoResultado gabarito_liberado = {false} resultado={resultado} resultadoTela={true}/>
                
                // LISTA EXISTE E LIBERADA E ALUNO JA TERMINOU E GABARITO QUANDO ALUNO TERMINAR - enviar questoes, gabarito e resultado
                if (resultado.status.lista_liberada  &&!resultado.status.lista_encerrada && resultado.status.aluno_terminou && resultado.status.gabarito_liberado) return <AlunoResultado gabarito_liberado = {true} resultado={resultado} resultadoTela={true}/>

                
                //LISTA EXISTE E NÃO ESTA LIBERADA
                if (!resultado.status.lista_liberada) return <ListaNaoLiberada />
                
                //LISTA EXISTE E ENCERRADA PELO PROF E ALUNO NEM COMECOU
                if (resultado.status.lista_liberada && resultado.status.lista_encerrada && !resultado.status.aluno_iniciou) return <AlunoResultado gabarito_liberado = {true} resultado={resultado} resultadoTela={true}/>
                
                // LISTA EXISTE E ENCERRADA PELO PROF E ALUNO COMECOU MAS NAO TERMINOU - enviar questoes, gabarito e resultado
                if (resultado.status.lista_liberada && resultado.status.lista_encerrada && resultado.status.aluno_iniciou && !resultado.status.aluno_terminou) return <AlunoResultado gabarito_liberado = {true} resultado={resultado} resultadoTela={true}/>
                
                // LISTA EXISTE E ENCERRADA PELO PROF E ALUNO COMECOU MAS NAO TERMINOU - enviar questoes, gabarito e resultado
                if (resultado.status.lista_liberada && resultado.status.lista_encerrada && resultado.status.aluno_iniciou && resultado.status.aluno_terminou) return <AlunoResultado gabarito_liberado = {true} resultado={resultado} resultadoTela={true}/>
               
                // LISTA EXISTE E LIBERADA E ALUNO NEM COMECOU - enviar questoes
                if (resultado.status.lista_liberada && !resultado.status.lista_encerrada && !resultado.status.aluno_terminou  ) return <AlunoLista resultado={resultado} resultadoTela={false}/>
               
                // LISTA EXISTE E LIBERADA E ALUNO COMECOU MAS NAO TERMINOU - enviar questoes 
                if (resultado.status.lista_liberada && !resultado.status.lista_encerrada && resultado.status.aluno_iniciou && !resultado.status.aluno_terminou) return <AlunoLista resultado={resultado} resultadoTela={false} />
                
                
                
            } else return <h1> Erro </h1>
        }

    }

    
    let dados = {}
    dados.id_link     = aluno.id_link
    dados.token_aluno = aluno.token_aluno
    dados.data        = dataAtual ();
   
    if (loading.current.inicio && !loading.current.fim && !loading.current.isSuccess ) {
        
        modelAluno.Questoes (dados, async function (result) {
            
        if (result.isSuccess) {
            loading.current.inicio = false
            loading.current.fim = true
            loading.current.isSuccess = true
            setResultado (onFinishLoading (result));
            
    
    } else {
        loading.current.inicio = false
            loading.current.fim = true
            loading.current.isSuccess = false
        setResultado (onFinishLoading (result));
        console.debug (result)
    
    }
    })
}
       
return (
    <>
    
        {resultado !== '' ? resultado : null}
        
    </>
)
}

export default AlunoQuestao;