import { useState } from 'react';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Grid } from '@mui/material';

function ModalSucessoErro (props) {
    
    const [openModal, setOpenModal] = useState(props.open);

    function Icone () {
        if (props.tipo === "success" ) {return <> <CheckCircleOutlineSharpIcon color="success" sx={{fontSize: "5em"}}></CheckCircleOutlineSharpIcon> </>}
        if (props.tipo === "error"   ) {return <> <ErrorOutlineIcon            color="error"   sx={{fontSize: "5em"}}></ErrorOutlineIcon>            </>}
        if (props.tipo === "warning" ) {return <> <WarningAmberSharpIcon       color="warning" sx={{fontSize: "5em"}}></WarningAmberSharpIcon>       </>}
        if (props.tipo === "info"    ) {return <> <InfoOutlinedIcon            color="info"    sx={{fontSize: "5em"}}></InfoOutlinedIcon>            </>}
    }
    
    function handleBtnClick (e) {
        e.preventDefault();
        setOpenModal(false);
        props.onClose(e);
    }
    
    return <>
     
    {openModal && (
    <Dialog maxWidth={'sm'} open={openModal} aria-labelledby="form-dialog-title">
        
        <DialogContent>
            
            <DialogContentText>
                
                <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center" >
                    <Grid item xs={12} >
                        {Icone()}
                    </Grid>
                    
                    {props.msg &&
                        <Grid item xs={12} mt={3} >
                            {props.msg}
                        </Grid>
                    }

                    {props.componente && (<Grid item xs={12} mt={3} >
                        {props.componente}
                    </Grid>)}
                </Grid>
            
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={(e) => handleBtnClick(e)} color={props.btnColor ? props.btnColor : 'primary'}>
                OK
            </Button>
            
        </DialogActions>
    </Dialog>
    )}
   </>

} 

export default ModalSucessoErro;

