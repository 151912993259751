import { useState, useEffect } from "react";
import { Snackbar, Alert, Slide, useTheme } from "@mui/material";

function SnackbarAviso (props) {
    
    const [openSnack, setOpenSnack] = useState (false);
    const theme = useTheme ();

    useEffect (() => {
        
       setOpenSnack (props.open);
    
    }, [props.open]);
    
    function handleClose () {
    
        setOpenSnack(false);
        props.onClose (false);
    }

    return <>
    
    {openSnack===true &&
    <Snackbar 
    
        open                = {openSnack} 
        autoHideDuration    = {props.time} 
        TransitionComponent = {Slide}
        
        anchorOrigin        = {props.posicao}
        onClose             = {() => handleClose()}
        sx                  = {{zIndex: 10000}}
        
    >

    {props.isAlert===true ? 
        
        <Alert 
            
            variant  = {props.variant} 
            severity = {props.tipo} 
            sx       = {{ width: '100%', color:'white', backgroundColor: CorAlerta (theme, props.tipo) }}
        
        >
            {props.msg}
        
        </Alert> 
        : 
        props.msg
    }

    </Snackbar>
}
     
    
    </>


} export default SnackbarAviso;

function CorAlerta (theme, tipo) {

    if (tipo !== 'success') {
        return theme.palette.vermelho.dark
    }

    if (tipo === 'success' && theme.palette.mode === 'dark') {
        return theme.palette.verde.dark
    }

    return theme.palette.primary.dark
}

SnackbarAviso.defaultProps = {
    open: true,   
    isAlert: true,
    time: 1500,
    transition: Slide,
    posicao: { "vertical" : "bottom", "horizontal": "center" },
    variant: "filled",
    tipo: "success",
    msg: ""
    
}