import './styles.css';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, useTheme } from '@mui/material';

import Login from '../../components/Login/modulo.login';
import CriarConta from '../../components/Login/modulo.cadastro';
import Alerta from './view.alerta';

import { TemasContext } from '../../services/service.temas.provider';
import { QuestoesContext } from '../../services/service.questoes.provider';
import { VariaveisContext } from '../../services/service.variaveis.provider';
import { useTela } from '../../services/service.tela.provider';
import { AlteraEstado, callApi } from './controller.login'

 
const LoginPage = () => {
  
  const [estado, setEstado]                   = useState (0);
  const [dadosLogin, setDadosLogin]           = useState ({ email: '', senha: '' });
  const [dadosCriarConta, setDadosCriarConta] = useState ({ nome: '', sobrenome: '', email: '', senha: '', repetirSenha: '' });
  const [loading, setLoading ]                = useState ({fim: false})
  
  const { setUsuario }   = useContext (QuestoesContext); 
  const { setColorTema } = useContext (TemasContext);
  const { isMobile }     = useContext (VariaveisContext);
 
  const navigate = useNavigate ();
  const theme    = useTheme();
  const [ tela, setTela] = useTela ('Login');
  console.log (tela)
  useEffect (() => {
    
    setColorTema ('Clássico'); 
  
  }, []);

  
const handleLogin = (event) => {
  
  event.preventDefault();
  setLoading({...loading, inicio: true, fim: false}); 
   
  callApi ("login", dadosLogin, dadosCriarConta, setLoading, setUsuario, navigate, setTela); // chama a API de login
  
};

const handleCriarConta = (event) => {

  event.preventDefault();
  setLoading({...loading, inicio: true, fim: false}); 
  
  callApi ("cadastrar", dadosLogin, dadosCriarConta, setLoading, setUsuario, navigate, setTela); // chamada a API de cadastro de nova conta

};

return (
  <>
  
  <div className="body-background overlay" >
  
  <div style={{backgroundColor: 'rgba(254, 252, 244, 0.08)', backdropFilter: 'sepia(75%)', position: 'absolute', top:0, height:'100%', width:'100%'}} >
  
  <Box sx={{ maxWidth: isMobile ? '80%' : 500, mx: 'auto', px: 5,  marginTop: 8, marginBottom: 5, display: 'flex',  flexDirection: 'column', alignItems: 'center', 
              borderRadius: '4px', border: 'solid', borderWidth: '1px', borderColor: 'rgba(0,0,0,0.12)', paddingY: '1em', 
              backgroundColor: theme.palette.background.paper}} variant="outlined">
    
  {estado === 0 && (
    
    <Login isMobile={isMobile} handleLogin={handleLogin} estado={estado} alterarParaCriarConta={(e) => AlteraEstado (e, 1, setEstado, setDadosLogin, setDadosCriarConta)} dadosLogin={dadosLogin} setDadosLogin={setDadosLogin} isLoading={loading.inicio} />
  
  )}
  
  {estado === 1 && (
    
    <CriarConta isMobile={isMobile} handleCriarConta={handleCriarConta} estado={estado} alterarParaLogin={(e) => AlteraEstado (e, 0, setEstado, setDadosLogin, setDadosCriarConta)} dadosCriarConta={dadosCriarConta} setDadosCriarConta={setDadosCriarConta} isLoading={loading.inicio} />
  
  )}
  
  </Box>
    
  {/* Alerta de erro ou sucesso */} 
  <Alerta loading = {loading} setLoading = {setLoading} /> 
    
  </div> 
  </div>
  
  </>
);
};

export default LoginPage;
