import { createContext, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const VariaveisContext = createContext();


function VariaveisProvider(props) {
  
        
    const [openMenu, setOpenMenu] = useState (false);
    const theme = useTheme('Floresta');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // se o dispositivo é mobile ou não

    const [novasNotificacoes, setNovasNotificacoes] = useState (0); 
        
        
    return <VariaveisContext.Provider value={{openMenu, setOpenMenu,
      novasNotificacoes, setNovasNotificacoes, isMobile, 
       
     }}>
                {props.children}
           </VariaveisContext.Provider> 
}

export { VariaveisContext, VariaveisProvider};
