import { useState, useEffect } from 'react';

import Card from '../../../views/components/view.card';
import { Box, Grid, useTheme } from '@mui/material';

import { Gauge,  gaugeClasses } from '@mui/x-charts';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CardTitulo from '../../../views/components/view.card.titulo';

const easeOut = (t) => t * (2 - t); // Função de amortização

export default function GaugeChartNotaMedia  (props) {
    
   const [media_alunos] = useState (media(props.alunos))
   const theme = useTheme (); 

   const [value, setValue] = useState(0); // Valor inicial para a animação
   

   useEffect(() => {
    let start = null;
    const duration = 2000; // Duração da animação em milissegundos

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const val = easeOut(Math.min(progress / duration, 1)) * media_alunos
      setValue(val.toFixed(1));

      if (progress < duration) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, []);
    
   function Cor () {
    let valor = parseFloat(value)

    if ( valor >= 5 && valor < 7) return theme.palette.chart.amarelo
    if ( valor >= 7) return theme.palette.chart.verde
    return theme.palette.chart.vermelho
   } 

    return (

        <>
        <Box sx={{width: 'auto'}} >
        <Card titulo={<CardTitulo titulo={'Média das notas'} icon={<StarBorderIcon />} help slide 
              tooltip={<span>As notas são atribuídas entre os valores de 0 a 10.<br/><br/>
              A média é calculada pela soma das notas de todos os alunos dividida pela quantidade de alunos inscritos na lista.<br/><br/>
              Obs: todas as estatísticas são baseadas nas questões respondidas por todos os alunos, incluindo aqueles que não finalizaram a lista no prazo.
              
              </span>}
        />}    margin_top={20} fontSizeTitulo="1.2rem" divider >

            <Grid container sx={{mt:-2, mb:-3, }} justifyContent='center' >
            
         
     
            
            <Gauge  height={200}  value={value} valueMin={0} valueMax={10}  cornerRadius="50%" 
                   sx={(theme) => ({
                    [`& .${gaugeClasses.valueText}`]: {
                      
                      fontSize: '2.9em',
                      
                    },
                    
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: Cor()
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                      fill: theme.palette.grey.light,
                    },
                    py:1
                  })
                }
                  text={value.toString().replace('.', ',')}     
                 
                
                    
                 
            >
              
              </Gauge>
           
           
              
           
     
            </Grid>           
           
        </Card>
        </Box>
        </>
    )
}   




function media (alunos) {
  
  let total_alunos = alunos.length;
  let total_notas = 0;

  for (let aluno of alunos) {
    total_notas += parseFloat(aluno.nota);
  }
  
  let a = total_notas / total_alunos 
  return a.toFixed(1);

}