
import { OFFSET_QUESTOES } from '../../../data/config/config';
import { obterValoresPorChave } from '../../../services/service.array';
import RetornaIDs from '../../../services/service.id.nome';
import { Get } from '../../../services/service.local-storage';

function getParentId(nodes, id) {
    let parentId = [];
    let anterior = 0;
    let id_temp = id;
    let result = ''

    function findParent(node) {
        if (Array.isArray(node.children)) {
        node.children.forEach(child => {
            
            if (child.id === id_temp) {
            
            if (parentId.length === 0 ) parentId.push (child.name)
            parentId.unshift (node.name)
            anterior = node.anterior;
            
            if (anterior > 0) {
                
                id_temp = node.id;
                findParent(nodes);
            }
            } else {
                
                findParent(child);
            }
        });
        
        }
    }
    
    
    findParent(nodes);
    
    for (let i = 0; i < parentId.length; i++) { 
        if (i > 0) {
        result += ' > ' + parentId[i] }
        else {result += parentId[i] }
    }
    return result;
}

export function inserirArvore (questoes) {
    
    let arvore = Get ('tags', 'array')
    for (let questao of questoes) {
        
        questao.arvore = getParentId (arvore, questao.tag);
        
    }
    
    return questoes
    

}

export function ajeitarResultado (questoes, setQuestoesControle, questoesControle) {
    
    
    let total = questoes[questoes.length - 1].total
    let total_parcial = questoes.length - 1 ;
    questoes.pop();    

    if (total_parcial === 0) {
    
        setQuestoesControle ({...questoesControle, total: 0, total_parcial: 0, offset: 0, escolhida: false});
        return questoes;
    }
    
    if (questoesControle.offset > 0) {
        
        let offset_temp = parseInt(questoesControle.offset) + parseInt(OFFSET_QUESTOES);
        setQuestoesControle ({...questoesControle, atual: 1 + questoesControle.atual, total: total, total_parcial: total_parcial, offset: offset_temp , escolhida: false}); 
        let a = [...questoes]
        a = inserirArvore (questoes);
        return questoes      

    }
      
      if (questoesControle.offset === 0) {
        
        setQuestoesControle ({...questoesControle, atual: 1, total: total, total_parcial: OFFSET_QUESTOES, offset: OFFSET_QUESTOES, escolhida: false }); 
        let a = [...questoes]
        a = inserirArvore (questoes);
        
        return questoes;
        
    }
}

export function RetornarBody (id_usuario, filtros, filtrosEscolhidos, tagsEscolhidas, disciplinaEscolhida, offset) {
    
    let a = {};
    a.id_usuario = id_usuario;
    
    if (disciplinaEscolhida.idSegmento === 4) {

        if (filtrosEscolhidos.id_fonte && filtrosEscolhidos.id_fonte.length > 0) {a.id_fonte = obterValoresPorChave (filtrosEscolhidos.id_fonte, 'id')}; 
        if (filtrosEscolhidos.id_ano && filtrosEscolhidos.id_ano.length > 0) {a.id_ano = obterValoresPorChave (filtrosEscolhidos.id_ano, 'id')};
        
        if (filtrosEscolhidos.ordem) {
            a.ordem = filtrosEscolhidos.ordem
        } else {
            a.ordem = false;
        }
        
    } else {

        a.id_segmento = disciplinaEscolhida.idSegmento;

    }    

    if (filtrosEscolhidos.hasOwnProperty ('tipo') && filtrosEscolhidos.tipo.length > 0) {a.tipo = obterValoresPorChave (filtrosEscolhidos.tipo, 'id')};
    
    a.tag = tagsEscolhidas;
    a.offset = offset;

console.log ('questoes body: ', a);
return a;

}


function RetornaNome (filtro, id, nome) {
    
    let retorno = '';
    filtro.forEach((item) => {
        if (item.id === id) {
            retorno = item[nome];
        }
    });
    return retorno;
}

export function RetornaFonteAno (filtros, questoes, questoesControle) {
    let fonte_ano = '';
    if (questoes[questoesControle.atual - 1].id_fonte===0) {
        fonte_ano = 'Sem fonte'
    } else
      {
       fonte_ano = questoes[questoesControle.atual - 1].fonte.nome;
       
       if (questoes[questoesControle.atual - 1].id_ano > 0) {
        fonte_ano += ' ' + questoes[questoesControle.atual - 1].ano.nome
      }
 }
 return fonte_ano;
 }

export const HTML_INICIAL  = '<!DOCTYPE html><html lang="en-US" dir="ltr"><head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1">';
export const HTML_INICIAL2 = '</head><body>';
export const HTML_FINAL    = '</body></html>';