import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { VariaveisProvider } from './services/service.variaveis.provider';
import { TemasProvider } from './services/service.temas.provider';
import './styles.css';
import { TelaProvider } from './services/service.tela.provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <TemasProvider>
    <TelaProvider>
    <VariaveisProvider>
    
    <App  />
    
    </VariaveisProvider>
    </TelaProvider>    
    </TemasProvider>
  
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();