// REACT
import { useState, useContext, useRef, useEffect } from 'react';
 
// CONTENXT
import { VariaveisContext } from '../../services/service.variaveis.provider';
import { QuestoesContext } from '../../services/service.questoes.provider';
import { TemasContext } from "../../services/service.temas.provider";
import { useTheme } from '@mui/material/styles';

// FUNCOES
import { RetornarBody, ajeitarResultado, RetornaFonteAno, inserirArvore } from './Questao/controller.questao';
 
// HOOKS
import { useQuestoes } from './Questao/model.questao';
 
import QuestoesSite from './view.questoes';
import QuestoesMobile from './view.questoes.mobile'; 

export default function Assuntos () {
  
  const { theme } = useContext (TemasContext);
  const tema = useTheme();
  const { isMobile } = useContext (VariaveisContext);
  const [mostrarQuestoes, setMostrarQuestoes] = useState (false);
  const { usuario, setQuestoes, setQuestoesControle, questoesControle, filtrosEscolhidos, tagsEscolhidas, filtros, disciplinaEscolhida, tags } = useContext (QuestoesContext);
  const loading = useRef ({filtro: {loading: true, sucesso: false}, tag: {loading: true, sucesso: false}});
  const [loadingQuestoes, setLoadingQuestoes] = useState({inicio: false, fim: false, isSuccess: false});
  const [terminouLoading, setTerminouLoading] = useState({inicio: true, fim: false, isSuccess: false});
  const [alinhamento, setAlinhamento] = useState ('left');
  const [show, setShow] = useState (false);
 
  const [value, setValue] = useState(0);

  const [questoesBody, setQuestoesBody] = useState (null);
  const [ questoes, refetch ] = useQuestoes (questoesBody, false, setLoadingQuestoes, disciplinaEscolhida)
 
  

  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect (() => {
    
    if (questoes && questoes.length > 0) {
    
      handleChangeIndex(2)
      
    }
    }, [questoes])
  
  function onFinishLoading (resultado, erro, msg, tipo) {
        
    if (resultado) {

      loading.current [tipo].sucesso = true;
      loading.current [tipo].loading = false;

    } else {
 
      loading.current [tipo].sucesso = false;
      loading.current [tipo].loading = false;
      setTerminouLoading ({...terminouLoading, inicio: false, fim: true, isSuccess: false, erro: erro, msg: msg});
      return;
  
    }
    
    if (!loading.current.filtro.loading && !loading.current.tag.loading ) {
  
      if (loading.current.filtro.sucesso && loading.current.tag.sucesso ) {
          
        setTerminouLoading ({...terminouLoading, inicio: false, fim: true, isSuccess: true});
  
      } 
    }
  }
  

function BuscarQuestoes (offset) {
  
  setMostrarQuestoes(false)
  let a = RetornarBody (usuario.id, filtros, filtrosEscolhidos, tagsEscolhidas, disciplinaEscolhida, offset)
  
  setQuestoesBody (a);
  setLoadingQuestoes ({inicio: true, fim: false, isSuccess: false})
  refetch (a) 
}

function handleBuscarQuestoes (valor) {
  
  if (valor && isMobile) {  
  handleChangeIndex(valor)

}
  
  setQuestoesControle({...questoesControle, offset: 0})
  BuscarQuestoes (0);
    
}

function handleBuscarQuestoes2 (e) {
   
  BuscarQuestoes (questoesControle.offset)
 
}
 
useEffect (() => {

  if (loadingQuestoes.fim && questoes !== undefined && questoes.length > 0  ) {
    
    let a = [...questoes]
        a = ajeitarResultado (questoes, setQuestoesControle, questoesControle, tags)
        console.log (a)
    setQuestoes (a);
    setMostrarQuestoes (true)  
  
  }

  if (loadingQuestoes.fim && isMobile && questoes !== undefined && questoes.length === 0  ) {
    
   // handleChangeIndex(1)
    setMostrarQuestoes (true)
    
  }

}, [loadingQuestoes.fim])


if (isMobile) {

return (
     
  <QuestoesMobile mostrarQuestoes = {mostrarQuestoes} terminouLoading = { terminouLoading } setTerminouLoading = { setTerminouLoading }
                  show = { show } theme = {theme} loadingQuestoes = {loadingQuestoes} setAlinhamento = {setAlinhamento}
                  RetornaFonteAno = { RetornaFonteAno } filtros = {filtros} alinhamento = {alinhamento}
                  onFinishLoading = {onFinishLoading} handleBuscarQuestoes = { handleBuscarQuestoes} handleBuscarQuestoes2 = { handleBuscarQuestoes2} 
                  tema = { tema } handleChange = {handleChange} value = {value}
  />
)
} else 

return (
    
   <QuestoesSite  mostrarQuestoes = {mostrarQuestoes} terminouLoading = { terminouLoading } setTerminouLoading = { setTerminouLoading }
                  show = { show } theme = {theme} loadingQuestoes = {loadingQuestoes} setAlinhamento = {setAlinhamento}
                  RetornaFonteAno = { RetornaFonteAno } alinhamento = {alinhamento}
                  onFinishLoading = {onFinishLoading} handleBuscarQuestoes = { handleBuscarQuestoes} handleBuscarQuestoes2 = { handleBuscarQuestoes2} 
   />
);
}



