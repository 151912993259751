import { useState, useEffect } from "react";
import {Box, Grid, Typography} from '@mui/material';
import ModalSucessoErro from "../../../components/ModalSucessoErro/ModalSucessoErro";
import * as Data from '../../../services/service.data'
import { useLocalStorage } from "../../../services/service.hooks";
import Nota from "../Lista/Resultado/Nota/Nota";

 
export default function MsgNota (props) {
    
        
    
        
    const [liberado, setLiberado] = useState (false)
    
    const componente = <>
      <Box sx={{height: '100%' }}>
      <Nota {...props} play/>
      </Box>
    </>
    return (<>
        <Box  sx={{
        
        backgroundColor: 'background.paper', // Define a cor de fundo com base no tema
       
      }}
    > 
        <ModalSucessoErro open ={true} backgroundColor='background.paper'
        data = {{btn1: {mostrar: true, disabled: false, color: 'primary', label: 'FECHAR'},
        btn2: {mostrar: false, color: 'secondary', label: 'ACESSAR'},
         componente: componente
            }} mtComponente = {0} mbComponente = {-2}
        onClose = {() => props.onClose()}

        ></ModalSucessoErro>
       </Box>   
        </>
        );
}