import { useTheme, Chip, TableContainer, Table, TableHead, TableRow, TableCell, Typography, TableBody, Paper } from "@mui/material"

import * as Data from '../../services/service.data';
import { RetornaStringMoedaReal } from '../../services/service.moeda.js';

import Loading from '../../components/Loading/view.loading2';

export default function Tabela (props) {
    
    const { creditosLista, loading } = props;
    const theme = useTheme ();
    
    return <>

    <TableContainer component={Paper} sx={{ mt: 0, boxShadow: 'none' }}>
    
    <Table size="small" aria-label="a dense table" sx={{ '& > *': { borderBottom: 'unset' }}}>
        
        <TableHead >
        <TableRow >
        
            <TableCell padding='5' align="left">N</TableCell>
            <TableCell align="left">Data</TableCell>
            <TableCell align="left">Créditos</TableCell>
            <TableCell align="left">Valor</TableCell>
            
        </TableRow>
        </TableHead>
    
        {creditosLista === -1 && null }
        
        {creditosLista.length === 0 && loading.inicio===false && loading.isSuccess=== true &&
        
        <Typography sx={{ marginLeft: '1em', marginBottom: '1em', marginTop: '1em' }} component="h6">
        
            Você não adquiriu nenhum crédito até o momento.
        
        </Typography>}
        
        
        <TableBody>                                
        
        {creditosLista.length > 0 && loading.inicio===false && loading.isSuccess=== true && creditosLista.map((a, index) => {return <>
        
        <TableRow sx={{ '& > *': {  borderWidth: 0.5, borderColor: theme.palette.mode === 'light' ? 'cinza.light' : 'cinza.dark', borderBottom: 'none',   pb:-1, pt:1}  }} hover>
        
        <TableCell align="left"><Chip label={index + 1} variant='outlined' size='small' sx={{color: 'primary.main', fontWeight: '500', ml:0, mb:0, mt:0}} /></TableCell>
        <TableCell align="left">{Data.Formatar (a.data)}</TableCell>
        <TableCell align="left">{a.total}</TableCell>
        <TableCell align="left">{a.transaction_amount > 0 ? RetornaStringMoedaReal (a.transaction_amount) : "Bônus"}</TableCell>
        
        </TableRow>  
        </>
        }
        )
    }
            
        </TableBody>
            
    </Table>
    
    <Loading local="componente" loading={loading} online={true} tipo='imagem'  /> 

    </TableContainer>

    </>
}