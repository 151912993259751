import { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

//import SiteFramework from '../../views/components/view.site-framework2.jsx'
import SiteFramework from '../../components/site/view.site-framework'

import { TemasContext } from '../../services/service.temas.provider.jsx';	
import { VariaveisContext  } from '../../services/service.variaveis.provider.jsx';
import { QuestoesContext } from '../../services/service.questoes.provider';

import ModalSucessoErro from '../../components/ModalSucessoErro/ModalSucessoErro.jsx';

import HomeController from './controller.home';
import HomeCards from './view.home.page.jsx';
import { PrimeiroAcesso } from './controller.home';

export default function Home() {
  
  const location = useLocation();
  const { usuario, setUsuario } = useContext (QuestoesContext)
  const { setColorTema } = useContext(TemasContext);
    
  const { isMobile } = useContext(VariaveisContext); 
  
  const [creditosParabensModal, setCreditosParabensModal] = useState (PrimeiroAcesso(usuario));
  const [show, setShow] = useState (false);

  useEffect (() => {
    
    if (location.state && location.state.eLOGIN) {   
      console.log ('a')
      setColorTema ('Clássico');
    }

    if (usuario.primeiro_acesso === true) {
      setCreditosParabensModal (true)
    }
   }, []);
   



// FECHAR MODAL
  const fecharModal = (e) => {
    //e.preventDefault();
    
    setUsuario ({...usuario, primeiro_acesso: false})
    setCreditosParabensModal (false);
  } 
 
  return (
    <>
    
    <SiteFramework 
      
      children = {<>
                  
      {/* ************** MODAL PARABENS PRIMEIRO ACESSO - CREDITOS GRATIS ************** */}
      
      <ModalSucessoErro open={creditosParabensModal} msg={'Parabéns! Você recebeu ' + usuario.numcreditos + ' créditos de bônus!'} 
                        data = {{successTipo: "success",  btn1: {label: "OK", color: "primary", mostrar: true}, btn2: {mostrar: false}}} success={true} 
                        onClickBtn1={(e) => fecharModal (e)} onClose={(e) => fecharModal (e)}  />
      

      {/* ************** BUSCA NO SERVIDOR AS INFORMAÇÕES DO USUÁRIO ************** */}  
      <HomeController onFinishLoading = {(resultado) => resultado ? setShow (true) : setShow (false) } show={show}/>

      { show && <HomeCards isMobile={isMobile} usuario={usuario} /> }
      
      </>} /> 
      </>
  );
}
