"use strict";
import * as React from 'react';
import { Dialog, DialogContent, Box, CircularProgress, Alert, AlertTitle } from '@mui/material';
import useOnlineStatus from '@rehooks/online-status';
import { useEffect, useState, useRef } from 'react';
import { OnlineStatus } from '../../services/service.hooks'
import ErroServidor from '../../views/components/view.erro.servidor.imagem.jsx';

export function Online () {
  
  const status = OnlineStatus ()
  return status;

}
   
function Loading (props) {
  
  const onlineStatus = useOnlineStatus ();
  
  useEffect (() => {
    
    if (onlineStatus) {
    
      if (props.onlineStatus) {props.onlineStatus (true)}
    
    } else {
    
      if (props.onlineStatus) {props.onlineStatus (false)}
    
    }
}, [onlineStatus]);

  function acao () {
    
    if (onlineStatus===false && props.show) return {online: true}  
    if (props.loading.inicio && props.loading.fim===false && onlineStatus) return {carregar: true, online: true};
    if (props.loading.fim && props.loading.isSuccess===false && onlineStatus) return {erro: true, online: true};
    if (props.loading.fim && props.loading.isSuccess===true && onlineStatus) return {erro: false, online: true};

    return{carregar: false, erro: false, online: true}
    
  }

return <>
    
    {props.tipo === 'normal' &&  acao().carregar ? 
    
    <Dialog open={props.loading.inicio}>
    
        <DialogContent>
    
            <Box display="flex" alignItems="center">
    
                    <CircularProgress size={40} color="primary" disableShrink/>
                    {props.msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.1em'}}>{props.msg}</Box>}
    
           </Box>
    
        </DialogContent>
    
    </Dialog>
  
  : null
  }

{props.tipo === 'simples' && acao().carregar &&
              <Box display="flex" alignItems="center" justifyContent="center" sx = {{flexDirection: 'column', height: "100%", minHeight: '75vh'}}>
                 
                  <CircularProgress size={40} color="primary" disableShrink/>
                  {props.msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.2em', mt:2}}>{props.msg}</Box>}
              
              </Box>
}

{props.tipo === 'inside' && acao().carregar &&
              
              <Box display="flex" alignItems="center" justifyContent="center" sx = {{flexDirection: 'column', mb:2, mt:2}}>
                 
                  <CircularProgress size={40} color="primary" disableShrink/>
                  {props.msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.2em', mt:2}}>{props.msg}</Box>}
              
              </Box>
}

{acao().erro &&
            
            <ErroServidor tipo={'servidor'}/>

}

{!acao().online &&
    
    <ErroServidor tipo={'internet'}/>
    
}
</>}

export default Loading;

