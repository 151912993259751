import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Alert, Button, Switch, Grid, FormControlLabel, Collapse, Slide, Typography} from '@mui/material'
import SiteFramework from '../../components/site/view.site-framework.jsx'
import Card from "../../views/components/view.card.jsx"
import CardTitulo from '../../views/components/view.card.titulo.jsx';
import GenericField from '../../components/Form/view.form.generic.field'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ptBR from 'date-fns/locale/pt-BR'
import ControllerNovoLink, { ValidarFormulario } from './controller,novo.link.jsx'
import { useTela } from '../../services/service.tela.provider.jsx';
import BotaoVoltar from '../../components/BotaoVoltar/view.botao.voltar2.jsx'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import HelpTooltip from '../../components/site/view.help.tooltip.jsx';
import { QuestoesContext } from '../../services/service.questoes.provider.jsx';

export default function NovoLink (props) {
    
    const { usuario } = useContext (QuestoesContext)
    const [turma, setTurma] = useState ('');
    const gabarito = useRef (false);
    

    const [inicio, setInicio] = useState (new Date())
    const [termino, setTermino] = useState (new Date())
    const [btnDisabled, setbtnDisabled] = useState (true)
    const [errorData, setErrorData] = useState (false)
    const [chamarApi, setChamarApi] = useState (false)
    const navigate = useNavigate();
    const location = useLocation();
    const id_lista = location.state.id_lista
    const num_links = location.state.num_links
    const questoes_total = location.state.questoes_total;

    const [, setTela] = useTela ();
    
    function handleGabarito (e) {
    
        e.preventDefault();
        gabarito.current = e.target.checked    
    
    }
    
    useEffect (() => {
       
        if (ValidarFormulario (turma, new Date (inicio), new Date(termino))) {
        
            setbtnDisabled(false)
        
        } else {
        
            setbtnDisabled(true)
        
        }

        if (inicio.getTime() > termino.getTime()) {

            setErrorData (true)

        } else {

            setErrorData (false)

        }

    }, [inicio, termino, turma])
    
    function handleBtnCriar () {
        
        setChamarApi(true)
    }

    return (

        <>
        
        <SiteFramework 
        children = {<>

        {/* ************** CONTROLLER NOVO LINK ************** */}
        <ControllerNovoLink turma={turma} inicio={inicio} termino={termino} gabarito={gabarito} id_lista={id_lista} chamarApi={chamarApi} setChamarApi={setChamarApi} questoes_total={questoes_total} id_usuario={usuario.id}/>

        {!chamarApi && 
        <Box>
        <BotaoVoltar onClick={(e) => {e.preventDefault(); setTela ('Listas'); navigate('/listas', {state: {selected: 'novolink', id_lista: id_lista}})}} />

        <Card titulo={<CardTitulo icon={<LinkOutlinedIcon />} help tooltip={<Tooltip />} titulo = 'Novo link' slide/> }  margin_top={20} fontSizeTitulo="1.2rem" divider>
         
        {num_links === 0 &&
        <Typography fontSize={12} mt={-2}>
            O primeiro link de qualquer lista é gratuito e não consome nenhum crédito!
        </Typography>        
        }

        {num_links > 0 &&
        <>
        <Typography fontSize={12} mt={-2}>
            {'Questões: ' + questoes_total}
        </Typography>        
        <Typography fontSize={12} >
        {'Créditos consumidos: ' + Math.floor (questoes_total/2)}
    </Typography>        
    </>
        }

        <Grid container spacing={2} alignItems='center' direction='row' mt={2}>
        
        
            
        
        
            <Grid item sx={{mt:-1}}>
                <GenericField label={'Turma'} onChange={(valor) => {setTurma (valor) }} helperText={'Escreva o nome da turma'} />
            </Grid>
        
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <Grid item>
                
                    <DatePicker label="Início"
                                value={inicio}
                                
                                slotProps={{
                                    textField: {
                                      helperText: 'Data da liberação da lista para a turma',
                                      size: 'small'
                                    },
                                   
                                  }}
                                onChange={(newValue) => setInicio (newValue)}/>
                
            </Grid>

            <Grid item>
                
                    <DatePicker label="Término"
                                value={termino}
                                
                                slotProps={{
                                    textField: {
                                      helperText: 'Data do encerramento da lista para a turma',
                                      size: 'small'
                                    },
                                    
                                  }}
                                onChange={(newValue) => setTermino (newValue)}/>
                
            </Grid>

            </LocalizationProvider>
            
            

        </Grid>
        

        <Grid container spacing={2} alignItems='center' direction='row' mt={1}>

        <Grid item  >
                <FormControlLabel control={<Switch onChange={(e) => {handleGabarito (e)}} />} 
                label="Liberar gabarito somente após o encerramento do prazo" />
                
            </Grid>
        
        <Grid item  ml={-3} mt={0.3}>
            <HelpTooltip  tooltip={<span>Desabilitado (padrão): o aluno somente terá acesso ao gabarito e a resolução das questões após o encerramento do prazo de entrega.
            <br/><br/>Habilitado: o aluno terá acesso ao gabarito e a resolução assim que finalizar a lista. </span>}/>
        </Grid>
        
        </Grid>


        
        {errorData &&
        <Box sx={{mt:2, mb:0}}>
            <Alert severity="error"  variant="filled">A data inicial não pode ser maior que a data final</Alert>
        </Box>
        
        }
        
        <Button
        
        disableElevation
        
        variant="contained"
        disabled={btnDisabled}
        onClick={(event) => handleBtnCriar (event)}
        sx={{ mt: 7, mb: 0 }}
        >
        
        Criar
        
        </Button>


        </Card>  
        </Box>
        }      


        </>
        } />
        
        
        </>
    )
}

function Tooltip () {
    return (<>
    
    <span>
        Crie um link da lista para ser distribuído para os alunos de uma determinada turma.<br/><br/>
        O link permite que o aluno faça as questões online utilizando um celular, tablet ou computador.<br/><br/>
        Você terá acesso a todas as estatísticas das respostas dos alunos ao término do prazo estabelecido para a finalização.<br/><br/>
        Use essa ferramenta para identificar a quantidade de acertos e erros para os assuntos escolhidos. Além disso, as estatísticas são bastante detalhadas e permitem identificar quais os conceitos os alunos estão com mais dificuldades.<br/><br/>
        <strong>Créditos consumidos:</strong><br/><br/>
        O primeiro link de uma lista é sempre gratuito. Caso queira aplicar a mesma lista em uma segunda turma, a quantidade de créditos necessária é a metade da quantidade de questões em uma lista.<br/><br/>
       Exemplo: a lista possui 10 questões. Créditos necessários: 10 / 2 = 5.

    </span>
    </>)
}