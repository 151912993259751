import { useState, useContext, useEffect } from 'react';
import { Grid, Box, Button } from '@mui/material';

import { Scrollbars } from 'react-custom-scrollbars';

import { QuestoesContext } from '../../services/service.questoes.provider';

// MEUS COMPONENTES
import SiteFramework from '../../components/site/view.site-framework.jsx'
import Loading from '../../components/Loading/view.loading.jsx';
import Loading2 from '../../components/Loading/view.loading2.jsx';
import Filtros from './Filtros/modulo.filtro';
import Tags from './Tags/controller.tag.jsx';
import Alerta from '../../views/components/view.alerta.jsx';
import BotoesQuestoes from './BotoesQuestoes.jsx';
import BotoesNavegacaoQuestoes from './BotoesNavegacao/modulo.botoes.navegacao';
import ExibirQuestoes from './Questao/Questao.jsx';
import ArranjoEnunciadoGabarito from './view.arranjo.enunciado.gabarito';
 
export default function QuestoesSite (props) {
    
    const colunasGeral = {xs: 12, sm: 12, md: 12, lg: 4, xl: 3}
    const classes = props.classes;
    const [btnEnabled, setBtnEnabled] = useState (false);
    
    const { questoesControle, filtros, disciplinaEscolhida, questoes } = useContext (QuestoesContext);
    
    const { mostrarQuestoes, terminouLoading, setTerminouLoading, show, theme, 
            loadingQuestoes, setAlinhamento, RetornaFonteAno, alinhamento, onFinishLoading, handleBuscarQuestoes, 
            handleBuscarQuestoes2
     } = props
    
     const [colunas, setColunas] = useState (props.alinhamento === 'left' ? {enunciado: {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}, gabarito : {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}} :
        {enunciado: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}, gabarito : {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}});
    
      useEffect (() => {
      if (props.alinhamento === 'left') {
      setColunas ({enunciado: {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}, gabarito : {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}});
      } else {
      setColunas ({enunciado: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}, gabarito : {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}});
      }
      }, [props.alinhamento]); 




    return <> 
    
    <SiteFramework 
        children = {<>
        
        
        <Grid container spacing={2} className={classes} >
      
        
            {/* ************** FILTROS E TAGS ************** */}
            <Grid  item xs={colunasGeral.xs} sm={colunasGeral.sm} md={colunasGeral.md} lg={colunasGeral.lg} xl={colunasGeral.xl} >
            
            <Scrollbars style={{ width: 'inherit', height: '90vh', marginTop:'-1em', }}>
           
                <Grid item xs={colunasGeral.xs} mt={0}>
            
                    <Filtros onFinishLoading = {(resultado, erro, msg) => onFinishLoading (resultado, erro, msg, 'filtro')}/>
            
                </Grid>

               <Box sx={{ml:2,mr:2, mt:3}} >
            
                  <Button variant="contained"  color='primary' size="small" fullWidth onClick={(e) => handleBuscarQuestoes (e)} sx={{mb:4, mt:-1}}>PROCURAR QUESTÕES</Button>
            
               </Box>
            
               <Grid item xs={colunasGeral.xs} sx={{mt:-1}}>
            
                  <Tags onFinishLoading = {(resultado, erro, msg) => onFinishLoading (resultado, erro, msg, 'tag')}   />
            
                </Grid> 
                
            </Scrollbars>
            </Grid>
            
           
               
            {/* ************** QUESTOES ************** */}
            
            <Grid item xs={colunasGeral.xs} sm={colunasGeral.xs} md={colunasGeral.xs - colunasGeral.md} lg={colunasGeral.xs - colunasGeral.lg} xl={colunasGeral.xs - colunasGeral.xl} sx={{mt:1, }}>

            <Loading2 local="conteudo" loading={loadingQuestoes} online={loadingQuestoes.online}  tipo="imagem"/>     
            
            {questoesControle.total > 0 && mostrarQuestoes && loadingQuestoes.isSuccess && <>
              
            <Grid container sx={{mb:6}} direction="row" justify="space-between" alignItems="center">
                
            
                <Grid item xs={12} sx={{borderStyle: 'none'}} >
                
                      <BotoesQuestoes mostrarBtnAssuntos={mostrarQuestoes} onChange={(value) => setAlinhamento (value)}/>
                      
                </Grid>
        
                  {questoes[questoesControle.atual-1]!==undefined && mostrarQuestoes && 
                  <Scrollbars style={{ width: 'inherit', height: '100vh',  }}>
                
                      <ExibirQuestoes enunciado={questoes[questoesControle.atual - 1].enunciado} gabarito_comentado={questoes[questoesControle.atual - 1].gabarito_comentado} gabarito={questoes[questoesControle.atual - 1].gabarito} 
                                      gabarito_objetiva = {questoes[questoesControle.atual - 1].gabarito_objetiva} gabarito_discursiva={questoes[questoesControle.atual - 1].discursiva} arvore = {questoes[questoesControle.atual - 1].arvore}
                                      tipo = {questoes[questoesControle.atual - 1].tipo} escolhida={questoesControle.escolhida}	
                                      atual={questoesControle.atual} total={questoesControle.total} fonte_ano= {disciplinaEscolhida.idSegmento === 4 ? RetornaFonteAno(filtros, questoes, questoesControle) : ''}
                                      alinhamento={alinhamento}
                      />
                    </Scrollbars>
                  }
                
            </Grid> 
                
            </>}
            
            {questoesControle.total === 0 && mostrarQuestoes &&
            <Box sx={{mt:-1.5, ml:1}}>
            
                <Alerta tipo="info" msg='Refine a sua busca' titulo="Nenhuma questão encontrada com esses filtros" variant="filled"/>
            
            </Box>
            }
            
            <Loading tipo="normal" loading={terminouLoading} onlineStatus ={(valor) => setTerminouLoading ({...terminouLoading, online: valor})} show={show} msg="Buscando as assuntos..."/>
             
            </Grid>
            
            {questoesControle.total > 0 && mostrarQuestoes && questoes[questoesControle.atual-1]!==undefined  &&
            
            <Grid sx={{mt:-1.5, ml: -2, mr:-2, borderRadius:0, backgroundColor: `${theme.palette.background.default}`, width:'100%', position:"fixed", bottom: 0}} item xs={12} >
             
                    {<BotoesNavegacaoQuestoes btnEnabled = {btnEnabled} onBuscarQuestoes= {() => handleBuscarQuestoes2()} />}
            
            </Grid>
}
        </Grid>
        
        </>} />
    </>
}