import * as React from 'react';
import { useContext, useState} from 'react';
import Toolbar from '@mui/material/Toolbar';
import NavBar from './view.navbar';
import SideBar from './view.sidebar';
import { Container, Box } from '@mui/material';
import { VariaveisContext } from '../../services/service.variaveis.provider';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DrawerHeader, Main } from './view.site-framework.config';
import { useTheme, Typography, Grid } from '@mui/material';
import BotaoVoltar from '../BotaoVoltar/view.botao.voltar';

function SiteFramework(props) {
  
  const { isMobile, openMenu, setOpenMenu } = useContext (VariaveisContext);
  const [open, setOpen] = useState(openMenu);
  const theme = useTheme();      

  const handleDrawerOpen = () => {
    setOpen(true);
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenMenu(false);
  };

  return (
    <React.Fragment>
    <Container maxWidth={false}>
          
      <Box sx={{ display: 'flex' }}>
    
          {/* NAVBAR */}
          <NavBar 
              open={open} 
              handleDrawerOpen={handleDrawerOpen}
              theme = {theme}
          />
         
         {/* SIDEBAR */}  
         <SideBar
              open={open}
              setOpen={setOpen}
              setOpenMenu={setOpenMenu}
              handleDrawerClose={handleDrawerClose}
              isMobile={isMobile}
            
          />
           
           {/* BACKDROP */}
      {open && isMobile && (
         <Fade in={open}>
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 200, // Garanta que seja maior que o zIndex do conteúdo e menor que o do Sidebar
        }} onClick={handleDrawerClose}></div>
        </Fade>
      )}
          {/* CONTEUDO */}
          <Main open={open}sx={{mt:2}} >
          
            <DrawerHeader >
              <Toolbar id="back-to-top-anchor" />
            </DrawerHeader>
            
            {props.voltar && 
            <Fade in={true} timeout = {2000}>
            <div>
            <Box sx={{display: 'flex', flexDirection: 'row',  justifyContent: 'flex-start', alignItems: 'center'}} >
            
            <Box mt={-3} mb={3} >
            <BotaoVoltar naomudarnometela = {props.naomudarnometela} />
            </Box>
            
            {props.titulo &&
            <Box mt={-3} mb={3} ml={2}>
            <Typography variant="h6" componente = 'div' >
              {props.titulo}
            </Typography>
            </Box>
            }
            </Box>
            </div>
            </Fade>
            }
            
            {props.children}
         
          </Main>
      
      </Box>
     
    </Container>
    <ScrollTop {...props}>
        <Fab style={{bottom: 60, right: 20}} size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
);
}

export default SiteFramework;

function ScrollTop(props) {
  const window  = props.window;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
 
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {props.children}
      </Box>
    </Fade>
  );
}


SiteFramework.defaultProps = {
  voltar: false,
  naomudarnometela: false
}





