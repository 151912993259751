import { Grid, IconButton } from "@mui/material"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export default function BotaoQuestaoProxima (props) {

    const { onClick, disabled, isMobile } = props
    
    const Btn = styled (IconButton) (({ theme }) => ({
        
        backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[300],
        '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? grey[700] : grey[400],
        
    },
      }));

    return (

        <>
        
        <Grid item  xs={isMobile ? 2 : undefined}>
            
            <Btn variant="outlined" color='primary' size="medium" onClick={onClick} disabled={disabled}>
            
            <KeyboardArrowRightIcon />
            
            </Btn>
        
        </Grid>
        
        </>
    )
}