export const navy_blue = {

    nome: 'Azul Marinho',
    
    components: {
        
    MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'black', // Cor de fundo do Tooltip
            color: 'white', // Cor da fonte do Tooltip
            fontSize: '1em', // Tamanho da fonte
          },
          
        },
      },
    },
    palette: {
        
        mode: 'dark',
            
        primary: {
        
            //main: '#78a6de',
            main: '#BACDDE',
            light: 'rgb(147,183,228)',
            dark: 'rgb(84,116,155)',
            contrastText: 'rgba(28,52,103,0.87)',
        
        },
        
        secondary: {
        
            main: '#2ec5d3',
            light: 'rgb(87,208,219)',
            dark: 'rgb(32,137,147)',
            contrastText: 'rgba(28,52,103,0.87)',
        
        },
        
        vermelho:  {
        
            main: '#d21f3c',
            dark: '#910D09',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        verde:  {
        
            main: '#0B6623',
            dark: '#0B6623',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        background: {
        
            default: '#05050a',
            paper: '#0a1323',
        
        },
        
        text: {
        
            primary: '#e1e1ff',
            secondary: 'rgba(255,255,255,0.8)',
        
        },
        
        success: {
        
            main: '#22b77f',
            light: '#d8f6e8',
            dark: '#004b50',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        info: {
        
            main: '#2dc7db',
            light: '#dbfafc',
            dark: '#003768',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        warning: {
        
            main: '#f9b626',
            light: '#fff4d9',
            dark: '#7a4100',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        error: {
        
            main: '#fb8e67',
            light: '#ffe9e1',
            dark: '#7a0916',
            contrastText: 'rgba(0,0,0,0.87)',
        
        },
        
        
        sidebar: {
        
            dark: '#0a1323',
        
        },
        
        navbar: {
        
            main: '#0a1323'
        
        },
        
        cinza: {
                
            main: 'rgba(0,0,0,0.20)',
            dark: 'rgba(0,0,0,0.70)',
            contrastText: 'rgba(0,0,0,0.87)',
        },

        chart: {
            verde: '#52b202',
            vermelho:  '#d21f3c',
            amarelo: '#f9b626',
            contrastText: 'rgba(0,0,0,0.87)',
        },
        
        divider: 'rgba(255,255,255,0.15)',               
    
    },
}