import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

// ************* SIDE BAR ITEMS *************
export const sidebarItems = [
    {id: 1, nome: 'Home'       , icon: HomeOutlinedIcon,               link: '/home',        divisoria: false, selected: 'Home'},
    {id: 2, nome: 'Disciplinas', icon: MenuBookOutlinedIcon,           link: '/disciplinas', divisoria: true, selected: 'Disciplinas'},
    {id: 3, nome: 'Listas'     , icon: FormatListBulletedOutlinedIcon, link: '/listas',      divisoria: true, selected: 'Listas'},
    {id: 4, nome: 'Créditos'   , icon: ShoppingCartOutlinedIcon,       link: '/creditos',    divisoria: true, selected: 'Créditos'},
    
];
