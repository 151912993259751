import { useContext, useEffect } from 'react';

import { QuestoesContext } from '../../../services/service.questoes.provider';
import { VariaveisContext } from '../../../services/service.variaveis.provider';
import { useLocalStorage } from '../../../services/service.hooks';

import TreeListView from '../../../views/components/view.tree.list';
import Card from '../../../views/components/view.card';
import CardTitulo from '../../../views/components/view.card.titulo';
import AccordionCustom from '../../../views/components/view.accordion';

import { TratarDados } from './service.tags';
import * as ModelTags from './model.tag'
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';

export default function Tags (props) {

  const [ usuario ] = useLocalStorage ("usuario");
  const [ tags, setTags ] = useLocalStorage ("tags");
  const { disciplinaEscolhida, tagsEscolhidas, setTagsEscolhidas } = useContext (QuestoesContext);  
  
  const { isMobile } = useContext (VariaveisContext);

  useEffect (() => {

    //if (tagsEscolhidas.length === 0) {setBtnDisabled (true)} else {setBtnDisabled (false)}

  }, [tagsEscolhidas]);
 
  function removerIndisponiveis(obj) {

    Object.keys(obj).forEach(key => {

      if (Array.isArray(obj[key])) {

        obj[key] = obj[key].filter(item => item.disponivel !== false);
        obj[key].forEach(childObj => removerIndisponiveis(childObj));

      }
    });
}

// CHAMA A API PARA DETERMINAR AS TAGS
  useEffect(() => {
    
    let dados = { params: {id_usuario: usuario.id, segmento: disciplinaEscolhida.segmento, disciplina: disciplinaEscolhida.disciplina }};
      
    ModelTags.Listar (dados, async (result) => {

      if (result.isSuccess) {
       
        let a = TratarDados (result.response[0], disciplinaEscolhida.nome, disciplinaEscolhida.segmento);
        
        removerIndisponiveis(a);
      
        setTags (a);
        
        props.onFinishLoading (true, null, null);
      
        console.debug ('Tags carregadas: ok');
      
      } else {
      
        console.debug ('Erro ao obter tags:', result.erro + ' - ' + result.msg);
      
        props.onFinishLoading (false, result.erro, result.msg);
      
      } 
  })
  }, []);
 
return (<>
   
  {isMobile ?
   
   
   <AccordionCustom titulo={<CardTitulo icon={<SubjectOutlinedIcon  />} titulo = 'Assuntos' />} variant={'body1'} fontSizeTitulo={'1.1rem'} nome='Assuntos' borderRadius={18} divider={true} key="Assuntos"componentes = {<>   
      <TreeListView arvore={tags} selected={tagsEscolhidas} onChange={(escolhidas) => setTagsEscolhidas (escolhidas)} /> 
   </>} /> 
   
    
    :
    
    <AccordionCustom titulo={<CardTitulo icon={<SubjectOutlinedIcon  />} titulo = 'Assuntos' />} variant={'body1'} fontSizeTitulo={'1.1rem'} nome='Assuntos'  borderRadius={18} divider={true} key="Assuntos"componentes = {<>   
      <TreeListView arvore={tags} selected={tagsEscolhidas} onChange={(escolhidas) => setTagsEscolhidas (escolhidas)} /> 
    </>} />
    
}
  </>);
}