import { useContext, useEffect, useState } from "react";
import { VariaveisContext } from "../../../services/service.variaveis.provider";

import { useTheme, Typography, Slide, Fade, Box, Grid } from "@mui/material";
import CardTitulo from "../../../views/components/view.card";
import  './styles.css'
import FonteAno from "./view.fonte.ano";
import TituloEnunciado from "./view.titulo.enunciado";
import Enunciado from "./view.enunciado";
import TituloGabarito from "./view.titulo.gabarito";
import Gabarito from "./view.gabarito";
import TituloGabaritoComentado from "./view.titulo.gabarito.comentado";
import GabaritoComentado from "./view.gabarito_comentado";
import Arvore from "./view.arvore";

 
export default function ExibirQuestoes (props) {
    
    const [chip, setChip] = useState (props.escolhida)
    const theme = useTheme ()
    const { isMobile } = useContext (VariaveisContext);
    const [colunas, setColunas] = useState (props.alinhamento === 'left' ? 
        {enunciado: {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}, gabarito : {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}} :
        {enunciado: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}, gabarito : {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}});
    
        const [fadeKey, setFadeKey] = useState(0);

        useEffect(() => {
            setFadeKey(prevKey => prevKey + 1);
          }, [props.enunciado]);
        

    useEffect (() => {
      
        if (props.escolhida) {
    
            setChip (true)
    
        } else setChip (false)   
    
    }, [props.escolhida])

       
    useEffect (() => {
        
        if (props.alinhamento === 'left') {
        
            setColunas ({enunciado: {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}, gabarito : {xs: 12, sm: 12, md: 12, lg: 6, xl: 6}});
        
        } else {
        
            setColunas ({enunciado: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}, gabarito : {xs: 12, sm: 12, md: 12, lg: 12, xl: 12}});
        }
    
    }, [props.alinhamento]);
    
   

    return (
        <>
        
        <CardTitulo titulo={"Questão " + props.atual + " de " + props.total} divider fontSizeTitulo='1.1em' chip={chip} >
                
      
                 
                 
                 {/* ******** TAG ******** */}
                 <Fade key={fadeKey} in={true} timeout = {900} direction="down">
                 <Box mt={-2} mb={3}>
                    <Arvore arvore={props.arvore} />
                 </Box>
                 </Fade>

                <Grid container direction="row"  rowSpacing={2} columnSpacing={8} justifyContent={isMobile ? "center" : "flex-start"}>
                    
                    
                    {/* ******** FONTE ANO ******** */}
                    <FonteAno fonte_ano = {props.fonte_ano} />
                    
                    

                    <Grid item xs={colunas.enunciado.xs} sm={colunas.enunciado.sm} md={colunas.enunciado.md} lg={colunas.enunciado.lg} xl={colunas.enunciado.xl} >
                        
                    {/* ******** ENUNCIADO ******** */}
                    
                    <Slide key={fadeKey} in={true}  timeout = {500} direction="right">
                    <div>
                    <TituloEnunciado /> 
                    
                    <Typography color={theme.palette.mode === 'dark' ? theme.palette.primary.main : 'inherit'}>
                    <Enunciado enunciado = {props.enunciado} />
                    </Typography>
                    </div>
                    </Slide>

                    </Grid> 
                    
                    <Grid item xs={colunas.gabarito.xs} sm={colunas.gabarito.sm} md={colunas.gabarito.md} lg={colunas.gabarito.lg} xl={colunas.gabarito.xl} >
                    
                    {/* ******** GABARITO ******** */}
                    
                    <Slide key={fadeKey} in={true} timeout = {500} direction="left">
                    <div>
                    <TituloGabarito gabarito_objetiva = {props.gabarito_objetiva} />
                    
                    <Typography color={theme.palette.mode === 'dark' ? theme.palette.primary.main : 'inherit'}>
                    <Gabarito {...props} />
                    </Typography>
                        
                    {/* ******** GABARITO COMENTADO ******** */}
                        
                    <TituloGabaritoComentado gabarito_objetiva = {props.gabarito_objetiva} gabarito_comentado = {props.gabarito_comentado} />
                    <Typography color={theme.palette.mode === 'dark' ? theme.palette.primary.main : 'inherit'}>
                    <GabaritoComentado gabarito_comentado = {props.gabarito_comentado} />
                    </Typography>
                    </div> 
                    </Slide>
                       
                    </Grid> 
                    
                </Grid>     
     </CardTitulo>
     </>   );
    }