import * as ModelCredito from './model.credito'

export function Listar (setLoading, usuario, loading, setCreditosLista) {
    
    setLoading ({...loading, inicio: true, fim: false, isSuccess: false})
    
    let dados = {};
    console.debug ('id_usuario creditos', usuario)
    
    dados.params = {id_usuario: usuario.id};

    ModelCredito.Listar (dados, async (result) => {
      
        let a = [];
      
        if (result.isSuccess) {
        
            console.debug ('Listas dos créditos obtidas com sucesso');
         

            if (result.response.length > 0) {
          
                a = result.response;
           
                localStorage.setItem('creditos', JSON.stringify(a));
        
            } else {
          
                a = [];
                localStorage.setItem('creditos', JSON.stringify([]));
        }
        
       setLoading ({...loading, inicio: false, fim: true, isSuccess: true})
        
        } else {
       
            console.debug ('Erro ao obter a lista de creditos:', result.erro + ' - ' + result.msg);
            
            setLoading ({...loading, inicio: false, fim: true, isSuccess: false, erro: result.erro, msg: result.msg})
            
        } 
       
        setCreditosLista (a);
       
    })
} 

export function ValorPorCredito (total, creditos) {
    // Calcula o valor por crédito
    var valor = total / creditos;
    
    // Arredonda para duas casas decimais
    valor = Math.round(valor * 100) / 100;
    
    // Converte para string e substitui o ponto por vírgula
    valor = valor.toString().replace('.', ',');
    
    // Verifica se tem apenas uma casa decimal e, caso afirmativo, acrescenta um zero
    var partes = valor.split(',');
    
    if (partes[1] && partes[1].length === 1) {
    
        valor += '0';
    
    }
    
    return valor;
}

export function Total (creditos, periodos) {
    // Calcula o total de créditos
    //var total = (1.1 - periodos /20) * (0.20822 * creditos + 200); valor real
    //var total = (1.1 - periodos /20) * (0.10822 * creditos + 20); // valor para teste
    let total = (1.2 - periodos /20) * (0.60822 * creditos + 50); // valor para teste
    
    // Arredonda para duas casas decimais
    total = Math.round(total);
    
    return total;
  }
  
export function TotalPeriodos (periodos) {
    // Calcula o total de créditos
    var total = 28.122377 * periodos + 60;
    
    // Arredonda para duas casas decimais
    total = Math.round(total);
    
    return total;
}