import { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function SenhaField(props) {
  
  const { disabled } = props;
  const [valor, setValor] = useState(props.valor ? props.valor : '' );
  const [showPassword, setShowPassword] = useState(false);
    
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => { event.preventDefault(); };

  function Atualiza (e) {

    setValor (e.target.value);
    props.onChange (e)

  }

  return (
    
    <TextField
    
    disabled={disabled}
    size="small"
    required
    fullWidth
    margin="normal"
    name="senha"
    label="Senha"
    autoComplete="current-password"
    value={valor}
    type={showPassword ? 'text' : 'password'}
    
    InputProps={{
      endAdornment: <InputAdornment position="end">
      <IconButton
    
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      onMouseDown={handleMouseDownPassword}

      >
      
      {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
      </InputAdornment>,
    }}

    onChange={(e) => Atualiza(e)} />

  );

} export default SenhaField;

SenhaField.defaultProps = {
    
  disabled: false,
  autofocus: false,
  onChange: () => {}

}
