import { chamarApi } from "../../../services/service.api";

export function Listar (data, callback) {
    
        const getResult = async () => {
            
            try {
            
                console.debug ('Listar tags');
            
                const result = await chamarApi ({type: 'get' , endpoint: 'tagsListar', data: data}) // chama a API
            
                callback (result);
            
            } catch (error) {
            
                callback (error);
            
            } 
        }
            
        getResult();
        
    }
        
