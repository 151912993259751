import { Navigate, useParams, useLocation } from "react-router-dom";
import { useLocalStorage } from "../../../services/service.hooks";
import AlunoQuestao from "../Lista/controller.aluno.questao";

function AlunoRoute (props) {
        
        let [ aluno ] = useLocalStorage ("aluno") 
        if (aluno === null) {
                aluno = {}
                aluno.id_link = '';
                aluno.token = '';
        }
        const { id_link, token } = useParams();
        const location = useLocation();

        console.log (id_link)
        let logado = false;
        
        

                const token_existe = aluno.token !== '' ? true : false;
                console.log (token_existe)
                
                if (token_existe) {
                        
                        const token_ls   = aluno.token;
                        const id_link_ls = aluno.id_link;
                        
                        console.debug (token_ls)

                        if (token_ls === token && id_link_ls === id_link) {

                                logado = true


                        } else {
                         
                                logado = false;
                                let a = {id_link: id_link, token: token}
                                console.log (a)
                                localStorage.setItem ('aluno', JSON.stringify (a))


                        }
                }
                
         else {
                
                logado = false;
                let a = {id_link: id_link, token: token}
                                console.log (a)
                                localStorage.setItem ('aluno', JSON.stringify (a))
                
        }
        
        console.debug ('logado', logado)
       
        console.debug ('location', location)        
        return logado ? <AlunoQuestao location = {location} /> : <Navigate to = "/aluno/cadastro" />;

}

export default AlunoRoute;