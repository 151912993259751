import {Stack, Chip, Divider} from '@mui/material';
import Card from '../../views/components/view.card';
import CardTitulo from '../../views/components/view.card.titulo';
import { RetornaStringMoedaReal } from '../../services/service.moeda';
import { useContext } from 'react';
import { VariaveisContext } from '../../services/service.variaveis.provider';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

export default function ResumoPagamento (props) {
     
    const {isMobile } = useContext (VariaveisContext)
    return (
        <>
        
        <Card titulo={<CardTitulo icon={<SummarizeOutlinedIcon />}  titulo = 'Resumo do pedido' />}  margin_top={20} fontSizeTitulo="1.2rem" divider mt={-2} mb = { 4 }>  
        {/* ************** VALOR DOS CRÉDITOS ************** */}
        
        {isMobile ? <>
            
            <Stack  direction={{ xs: 'row' }} sx={{mt:3, mb:1}}  spacing={{xs:1, md:1}}  divider={<Divider orientation="vertical" flexItem />}>
            
                <Chip label={'Créditos: ' + props.valores.valor} sx={{fontSize: 15}}  />
                <Chip label={'Períodos: ' + props.valores.periodo} sx={{fontSize: 15}}  />
            
            </Stack>
            
            <Stack  direction={{ xs: 'row' }} sx={{ mt:2, mb:-1}}  spacing={{xs:0, md:1}}   >
            
                <Chip label={'Total: R$ ' + RetornaStringMoedaReal (props.valores.transaction_amount)} sx={{fontSize: 15}}  />
            
            </Stack>
            </>
            :
            <>
            
            <Stack  direction={{ xs: 'row' }} sx={{ml:-2, mt:3, mb:2}}  spacing={{xs:1, md:1}}  divider={<Divider orientation="vertical" flexItem />}>
                
                <Chip label={'Créditos: ' + props.valores.valor} sx={{fontSize: 15}}  />
                <Chip label={'Períodos: ' + props.valores.periodo} sx={{fontSize: 15}}  />
                <Chip label={'Total: R$ ' + RetornaStringMoedaReal (props.valores.transaction_amount)} sx={{fontSize: 15}}  />

            </Stack>
    </>
    }
        </Card>
        </>
    )
}