// REACT
import { useContext } from 'react';

// MUI
import { Toolbar, Typography, Box } from '@mui/material';

// CONTEXT
import { VariaveisContext } from '../../services/service.variaveis.provider';


import { BtnMenu } from './view.navbar.btn.menu';
import LogoEmpresa from './view.navbar.logo.empresa';
import { NAVBAR_TITULO, AppBar } from './view.navbar.config';
import BtnNotificacoes from './view.navbar.btn.notificacoes';
import BtnTema from './view.navbar.btn.tema';
import BtnUsuario from './view.navbar.btn.usuario';


const NavBar = ({ open, handleDrawerOpen, theme }) => {
  const { isMobile } = useContext( VariaveisContext );
  
  return (
    <>
    
    <AppBar position="fixed" open={open}   
       sx={{
            boxShadow: '0',
       }}>
      
      <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            
            {/* ****** LADO ESQUERDO ****** */}
            <div style ={{display: 'flex', alignItems: 'center'}} >
                
                {/* ****** BTN HAMBURGUER ****** */}
                  <BtnMenu handleDrawerOpen={handleDrawerOpen} isMobile={isMobile} open={open} />    
                
                {/* ****** LOGOTIPO DA EMPRESA ****** */}
                {isMobile===false && open===false &&
                  <LogoEmpresa open={open} isMobile={isMobile} />
                }
          
                {/* ************* TITULO  ************* */}
                {isMobile && open ? null :
                  <Typography style ={titulo.style} variant="h6" sx={titulo.sx}>{NAVBAR_TITULO}</Typography>
                }
        
            </div>
            {/* ****** LADO DIREITO ****** */}      
            
            {/* ************* BOTÕES  ************* */}
            <Box sx={{display: 'flex',
              ...(isMobile && open && {marginLeft: -2}),
            }} >
            
                <BtnNotificacoes />
                <BtnTema />    
                <BtnUsuario theme={theme}/>                
            </Box>  
       
      </Toolbar>
    </AppBar>
     </>
  );
};

export default NavBar;

const titulo = {
  style: {marginLeft: '0.85em', marginTop: '-0.32em'},
  sx:    {fontWeight:500, mb: -0.6, fontSize: '1.4em'}
}

 

    

    