import { dataAtual } from '../../../../services/service.data';
import { inserirMarcadoFalsoQuestoes } from '../controller.aluno.lista';
import * as modelAluno from './model.aluno.resposta'

export async function Resposta (dados) {

    
        modelAluno.Resposta (dados, async function (result) {
          
          
            
            console.debug ('resposta result', result) 
           
            
          
    
          
          
      })
        
}

export function onClick (atual, letra, aluno, setAluno, setRespondidas, item, setMarcado, marcado) {
    
    let a = [...aluno.questoes]
    let b = [false, false, false, false, false]
    let letra_final = 'X'

    a[atual-1].marcado = b
    
       
        if (marcado[item]===true) {

            a[atual - 1].marcado[item] = false;
            setRespondidas ((old) => old = old + -1)
   
        } else {
            a[atual - 1].marcado[item] = true;
            letra_final = letra
        }

    
    
    
    setAluno ({...aluno, questoes: a});
    setMarcado (a[atual-1].marcado)

    const valoresPermitidos = ["A", "B", "C", "D", "E"];

    let resposta = aluno.questoes [atual - 1].resposta;
    console.log ('resposta', resposta)
    
    if (aluno.questoes [atual - 1].tipo === 1 && resposta !== 'Z' && !valoresPermitidos.includes (resposta)  ) {

        setRespondidas ((old) => old = old + 1)
    }

    aluno.questoes[atual - 1].resposta = letra_final;
    setAluno (aluno);

    let dados = {}
    dados.token_aluno = aluno.token_aluno
    dados.id_link = aluno.id_link
    dados.data = dataAtual();

    let respostas = ArrayRespostas (atual, letra_final, aluno);
    let tempos = ArrayTempos (atual, '0', aluno);
    
    dados.respostas = respostas;
    dados.tempos = tempos;
    
    Resposta (dados);


}

function ArrayRespostas (atual, letra, aluno) {

    let result = []
    
    aluno.questoes.forEach (q => {
        
        if (q.tipo === 1) {

            if (q.resposta) {
                
                result.push (q.resposta)

            } else {

                result.push ('X')

            }
        } else {

            result.push ('Z')

        }
    });
    
    result [atual - 1] = letra;
    return result
};

function ArrayTempos (atual, tempo, aluno) {

    let result = []
    
    aluno.questoes.forEach (q => {
        
        if (q.tipo === 1) {

            if (q.tempo) {
                
                result.push (q.tempo)

            } else {

                result.push ('0')

            }
        } else {

            result.push ('Z')

        }
    });
    
    result [atual - 1] = tempo;
    return result
};

export function estaMarcado (questoes) {
    
    let result = []

    questoes.forEach ((q) => {

        if (q.tipo===1 && q.resposta!=='X') {
            result.push (true)
        } else
        {
            result.push (false)
        }

    })
    
    return result
}
