import { useEffect, useState, useRef } from 'react';

import { Grid, Divider, Typography, FormControl } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { Enviar } from './controller.usuario.editar';

import GenericField from '../../components/Form/view.form.generic.field';
import EmailField from '../../components/Form/view.form.email';
import SenhaField from '../../components/Form/view.form.senha';
import RepetirSenhaField from '../../components/Form/view.form.repetir.senha';

export default function FormularioUsuarioEditar (props) {
    
    const { usuario, setUsuario, loading, setLoading, setDadosMsg, setOpen } = props

    const [botaoLigado, setBotaoLigado] = useState(false);
    const [dados, setDados] = useState ({nome: usuario.nome, sobrenome: usuario.sobrenome, email: usuario.email, senha: "", repetirSenha: ""});
    const emailValido = useRef (true); 
  
    useEffect (() => {
    if (dados.nome === usuario.nome && dados.sobrenome === usuario.sobrenome && dados.email === usuario.email && dados.senha === '') {
    
        setBotaoLigado (false);
        return;
    
    } else {

    if ((dados.senha===dados.repetirSenha && emailValido.current)) 
        {
        
            setBotaoLigado (true)
        
        } else {
        
            setBotaoLigado (false)
        
        };
    }
    }, [emailValido.current, dados.senha, dados.repetirSenha, dados.nome, dados.sobrenome, dados.email])
    
    return <>
    
    <FormControl size='small' fullWidth>   
                
                <Grid container spacing={2}>
                  
                  <Grid item xs={12} md={4}>
                      <GenericField label="Nome" valor={dados.nome} onChange={(valor) => setDados({...dados, nome: valor})}  />
                  </Grid>  

                  <Grid item xs={12} md={4}>    
                      <GenericField label="Sobrenome" valor={dados.sobrenome} onChange={(valor) => setDados({...dados, sobrenome: valor})}  />     
                  </Grid>

                  <Grid item xs={12} md={4} >   
                      <EmailField label="Email" valor={dados.email} onChange={(valor) => setDados({...dados, email: valor})} onValidate={(valor, validado) => emailValido.current = validado}  />
                  </Grid>    
                    
                    <Divider sx={{mt:3}}/>

                  <Grid item xs={12} >
                      <Typography sx={{mt:2, mb:-2, ml:0.5}} variant="caption" component="div">
                                Deixe os campos Senha e Repetir a senha em branco caso não queira alterar a senha.
                      </Typography>
                  </Grid>    

                  <Grid item xs={12} md={6}>  
                       <SenhaField label="Senha" valor={dados.senha} onChange={(valor) => setDados({...dados, senha: valor})}  />
                  </Grid>

                  <Grid item xs={12} md={6}>  
                    <RepetirSenhaField label="Repetir a senha" valor={dados.repetirSenha} onChange={(valor) => setDados({...dados, repetirSenha: valor})}  
                                       onValidate = {(valor, setIgual) => {if (valor !== dados.senha) {setIgual (false) } else {setIgual (true)} }}
                    />
                  </Grid>  
                </Grid>
            </FormControl>
            
            <Divider sx={{mt:3}}/>
            
            <LoadingButton
              disableElevation
              size="medium"
              loading={loading.inicio}
              sx={{mt: 2, mb:0}}
              variant="contained"
              color="primary"
              disabled={!botaoLigado}
              onClick={(e) => Enviar (e, dados, loading, setLoading, usuario, setUsuario, setDadosMsg, setOpen)}
            >
              <span>Enviar</span>
            </LoadingButton>

    </>
}