
import { Grid, Divider } from "@mui/material"
import HtmlPainel from "../../../components/HtmlPainel/view.html.panel";

export default function GabaritoComentado (props) {
    
    const { gabarito_comentado } = props

    function tornarImagensResponsivas(html) {
        
            // Encontrar todas as tags img e modificar seus atributos
            return html.replace(/<img([^>]*)>/g, (match, capture) => {
        
            // Se a tag já contém um estilo, adicionamos os estilos de responsividade a ele
            if (capture.includes('style="')) {
            return match.replace(/style="/, 'style="max-width: 100%; height: auto; object-fit: contain; ');
          
        }
            // Se a tag não contém um estilo, adicionamos um novo atributo de estilo
            return `<img style="max-width: 100%; height: auto; object-fit: contain;"${capture}>`;

        });
      }

    return <>
    
    {props.gabarito_comentado &&
    <Grid item xs={12} >
   
        <HtmlPainel  html= {  tornarImagensResponsivas( gabarito_comentado ) }  />
        
        <Divider  sx={{ mb:2 }}  />
   
    </Grid>
}
    </>
}