import React, { useState, useEffect } from 'react';
import { Grid, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import Email from '../Form/view.form.email';
import Senha from '../Form/view.form.senha';
import Botao from './view.button';

import { AtualizaCampos, validarFormularioLogin, validarEmail } from './controller.login';
import Copyright from '../Copyright/view.copyright';
import { COPYRIGHT_NOME, COPYRIGHT_SITE } from '../../data/config/config';
import ResetarSenha from './ResetarSenha';
import Logo from './view.logo';
import Titulo from './view.titulo';
import Avatar from './view.avatar';
import AlterarParaCriarConta from './view.alterar.para.criar.conta';

function Login ({ dadosLogin, setDadosLogin, alterarParaCriarConta, isLoading, handleLogin, estado, isMobile }) {
    
    const titulo = "Entrar"

    const [botaoLigado, setBotaoLigado] = useState(false);
    const [emailValido, setEmailValido] = useState(true);
    const [openResetar, setOpenResetar] = useState(false);

    // VALIDAÇÃO DOS DADOS - UPDATE
    useEffect(() => {
      
      validarFormularioLogin (dadosLogin, emailValido, setBotaoLigado); 

  }, [dadosLogin]);

    // RECUPERACAO SENHA - ABRIR
    function esqueciSenha() {
      
      setOpenResetar(true);
    
    }
 
  return (
    <>
       
      <Logo isMobile = { isMobile } />
      
      <Avatar bgcolor = 'warning.dark' mt = { 3 } icon = {<LockOutlinedIcon />} />
      
      <Titulo titulo = {titulo} />
      
      <Email value = {dadosLogin.email} onChange={(e) => AtualizaCampos (e, estado, setDadosLogin, null)} disabled={isLoading} emailValido = {(valor) => setEmailValido (valor)} /> 
      <Senha value = {dadosLogin.senha} onChange={(e) => AtualizaCampos (e, estado, setDadosLogin, null)} disabled={isLoading} /> 
      
      <Botao botaoLigado = {botaoLigado} isLoading = {isLoading} handle = {(event) => handleLogin (event)} palavra = {titulo} /> 
      
      <Grid container justifyContent="space-between" sx={{ mb: 4 }}>

        <EsqueciSenha esqueciSenha = {esqueciSenha} isLoading = {isLoading} />
        <AlterarParaCriarConta alterarParaCriarConta = {alterarParaCriarConta} isLoading = {isLoading} />

      </Grid>

      <Copyright nome={COPYRIGHT_NOME} site={COPYRIGHT_SITE} />

      {openResetar ? 
      
      <ResetarSenha onClose={() => setOpenResetar(false)} /> 
      
      : null}
    
    </>
  );
}

export default Login;

function EsqueciSenha ({isLoading, esqueciSenha}) {

  return (

      <>
      
      <Grid item>
      
          <Link href="#" variant="body2" onClick={esqueciSenha}>
          
              {isLoading ? null : "Esqueceu a senha?"}
          
           </Link>
      
      </Grid>
      
      </>
  )
}