import { createContext, useState, useMemo, useEffect } from 'react';
import { createTheme } from '@mui/material';
import { themes } from '../data/config/themes';
import { Get as lsGetTema, Set as lsSetTema} from './service.local-storage'

const TemasContext = createContext();

function TemasProvider(props) {
    
  const temas = [
    {id: 0, modo:'light', tipo: 'Claro',  nome: 'Clássico', divisoria: true},
    {id: 1, modo:'dark',  tipo: 'Escuro', nome: 'Floresta', divisoria: false},
    {id: 2, modo:'dark',  tipo: 'Escuro', nome: 'Blue',     divisoria: false},
    {id: 3, modo:'dark',  tipo: 'Escuro', nome: 'Terra',    divisoria: false},
  ]
    const [mode, setMode] = useState('dark');
    const [colorTema, setColorTema] = useState (BuscarTemaID()); // 'padrao Clássico
        
    const colorMode = useMemo(
      
      () => ({
      
        toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        
      },
      }),
      [],
    );    
    
    
   
    const theme_geral = (() => {
      console.log ('theme_geral')
      switch (colorTema) {
          case 0:
            lsSetTema ('tema', 0)  
            return createTheme({ ...themes.light.classico });
            
          case 1:
            lsSetTema ('tema', 1)  
              return createTheme({ ...themes.dark.militar });
              
          case 2:
            lsSetTema ('tema', 2)  
              return createTheme({ ...themes.dark.navy_blue });
              
          case 3:
            lsSetTema ('tema', 3)  
              return createTheme({ ...themes.dark.marrom });
              
          default:
            
            if (BuscarTemaID() === 1) {lsSetTema ('tema', 1); return createTheme({ ...themes.dark.militar })}
            if (BuscarTemaID() === 2) {lsSetTema ('tema', 2); return createTheme({ ...themes.dark.navy_blue })}    
            if (BuscarTemaID() === 3) {lsSetTema ('tema', 3); return createTheme({ ...themes.dark.marrom })}
            lsSetTema ('tema', 0);
            
            return createTheme({ ...themes.light.classico });
            
      }
  })();
  
  
  

    

    const theme = useMemo(
      () => { 
        
        return createTheme(theme_geral , {
          palette : {
            violeta: theme_geral.palette.augmentColor({
              color: {
                main: '#7F00FF',
              },
              name: 'violeta',
            }),
          },
         
          components: {
           
            MuiAppBar: {
              styleOverrides: {
                root: {
                  //backgroundColor: theme_geral.palette.background.paper,
                  [theme_geral.breakpoints.down ('sm')] : { backgroundColor: theme_geral.palette.background.default},
                  [theme_geral.breakpoints.up ('sm')] : { backgroundColor: theme_geral.palette.background.paper},
                  backgroundImage: 'linear-gradient(rgba(255,255,255,0.0), rgba(255,255,255,0.0))'
                }
              }
            },
            
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  backgroundColor: theme_geral.palette.mode === 'dark' ? 'black' : theme_geral.palette.cinza.dark, // Cor de fundo do Tooltip
                  color: theme_geral.palette.mode === 'dark' ? theme_geral.palette.primary.main : theme_geral.palette.text.contrastText,
                  fontSize: '0.9em', // Tamanho da fonte
                },
                arrow: {
                  color: theme_geral.palette.mode === 'dark' ? 'black' : theme_geral.palette.cinza.dark, // Cor da seta do Tooltip
                },
            },
          }, 

          MuiDialogTitle: {
            styleOverrides: {
              root: {
                backgroundColor: theme_geral.palette.background.paper,
                color: theme_geral.palette.primary.main // Defina a cor de fundo desejada
              },
            },
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                backgroundColor: theme_geral.palette.background.paper, // Defina a cor de fundo desejada
              },
            },
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                backgroundColor: theme_geral.palette.background.paper, // Defina a cor de fundo desejada
              },
            },
          },

          MuiAlert: {
            styleOverrides: {
              root: {color: 'white'},
              standardError: {
                backgroundColor: theme_geral.palette.chart.vermelho, // substitua pelo valor desejado
              },
            },
          },


          },
        }
        )
        
      }, [colorTema]
    );   
    
    return <TemasContext.Provider value={{mode, theme, setMode, colorTema, setColorTema, colorMode, temas}}>
                
              {props.children}
           
           </TemasContext.Provider> 
}

export { TemasContext, TemasProvider};


function BuscarTemaID () {
  
  let result = lsGetTema ('tema')

  if (result === '') {
    return 0
  }

  return parseInt (result);
}