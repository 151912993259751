
import { chamarApi } from '../../../services/service.api';

export const ensino_medio = [

    {titulo: 'Fontes', nome: 'id_fonte', tipo: 'select'},
    {titulo: 'Anos', nome: 'id_ano', tipo: 'select'},
    {titulo: 'Tipos', nome: 'tipo', tipo: 'select'},
    {titulo: 'Ordem dos anos', nome: 'ordem', label: ['Decrescente', 'Crescente'], tipo: 'radio'},

]

export const ensino_fundamental2 = [

    {titulo: 'Tipos', nome: 'tipo', tipo: 'select'},
    
]    
 
export function Listar (data, nome, callback) {
    
    const getResult = async () => {

        try {

            console.debug ('Listar filtros');

            const result = await chamarApi ({type: 'get', endpoint: nome + 'Listar', data: data}) // chama a API

            callback (result);

        } catch (error) {

            callback (error);

        } 
    }

    getResult();

}


