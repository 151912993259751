import { useState, useContext } from 'react';
import { QuestoesContext } from '../../services/service.questoes.provider';

import ModalSucessoErro from '../../components/ModalSucessoErro/ModalSucessoErro';

import Card from '../../views/components/view.card.jsx';
import CardTitulo from '../../views/components/view.card.titulo.jsx';
import Titulo from '../../views/components/view.titulo';
import SiteFramework from '../../components/site/view.site-framework.jsx'

import FormularioContato from './view.formulario.contato';
import { useLocalStorage } from '../../services/service.hooks.js';
import { Slide, Box } from '@mui/material';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

export default function Contato () {
  
  const [ usuario ] = useLocalStorage ("usuario");
  const [loading, setLoading] = useState({inicio: false, fim: false, isSuccess: false});
  const [open, setOpen] = useState (false);

  return (

    <>
    {/* ************** MODAL ************** */

    <ModalSucessoErro open={open} msg={loading.modalMsg} data={{errorTipo: "error", successTipo: "success", btn1: {label: "OK", color: "primary", component: null, mostrar: true}, btn2: {mostrar: false}}}   
                      success={loading.isSuccess ? true : false} mudarPagina = {true} link = '/home' 
     />
    }
           
    <SiteFramework 
        
        children = {<>
        
        {/* ************** CARD ************** */}
        <Slide in={true} timeout={700} direction='down'>
        <Box>
        <Card titulo={<CardTitulo icon={<MessageOutlinedIcon />}  titulo = 'Contato' /> }  margin_top={20} fontSizeTitulo="1.2rem" divider > 
          
        <Titulo texto='Mensagem' divider/>

        <FormularioContato loading={loading} setLoading = {setLoading} usuario = {usuario} setOpen = {setOpen} />

        </Card>
         </Box>
         </Slide> 
        
        </>}/>  
           
    </>
  );
} 