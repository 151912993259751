import { useState, useEffect } from "react";
import { TextField } from "@mui/material";

function FormSobreNomeView (props) {
    
    const [value, setValue] = useState (props.value ? props.value : '');
    const focus = props.focus!==undefined ? props.focus : false;

    useEffect(() => {
        
        props.onChange (value);
    
    }, [value]);

    useEffect(() => {
    
        setValue (props.value);
    
    }, [props.value]);
    
    function onHandleChange (e) {
        
        if (e !== undefined) {
        
            e.preventDefault ();
            setValue (e.target.value);
            props.onChange (e);
        
    }
    }

    return (
    
    <>
    
    <TextField
    disabled = {props.disabled ? props.disabled : false}
    size="small"      
    required
    fullWidth
    autoFocus={focus}
    margin="normal"
    name="sobrenome"
    label="Sobrenome"
    value={value}
    onChange={(e) => onHandleChange (e)}
    
    /> 
    </>

)} export default FormSobreNomeView;
