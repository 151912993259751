import { Box, Slide } from '@mui/material';

import SiteFramework from '../../components/site/view.site-framework.jsx'

import ResumoPagamento from '../../components/Pagamento/modulo.pagamento.resumo';
import DadosPagamento from '../../components/Pagamento/modulo.pagamento.dados.jsx';

import { useLocalStorage } from '../../services/service.hooks';


function PaymentPage ({setEstado}) {
  
const [ creditosInfo ] = useLocalStorage ('creditosInfo'); 
  
return (
    <>   
    
    <DadosPagamento dados = {creditosInfo} />
    
  </>
  );
}

export default PaymentPage;