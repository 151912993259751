import { NavLink } from "react-router-dom";
import { grey } from "@mui/material/colors";




import { styled } from '@mui/system';
import { Badge } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { drawerWidth } from './view.site-framework.config';

export const NAVBAR_TITULO = "FazProva";

// ************* ICONS *************
/*
export const navbarIcons = [
    {id: 1, nome: 'Notificações', icon: NotificationsIcon, link: '/notificacoes', tooltip: 'Notificações', menuItems: }
    {id: 2, nome: 'Modo escuro', nome2:  icon: LightModeIcon, icon2: DarkModeIcon, link: '/notificacoes', tooltip: 'Notificações',  menuItems: }
    {id: 1, nome: 'Notificações' , icon: NotificationsIcon, link: '/notificacoes', tooltip: 'Notificações' , menuItems: }
                            ['Modo escuro', null, '/modo', 'Tela', true],
                            ['Usuário', PersonIcon, '/usuario', 'Usuário', true],

                            

];
*/

// **************** MENUS ********************** //


export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 10,
      top: 16,
      
      padding: '0 0px',
    },
  }));

  export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' ,
  })(({ theme, open, isMobile }) => ({
      color: theme.palette.primary.main,
       
      transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen + 700,
    }),
    ...(open &&  {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen + 700,
      }),
    }),
  }));

  export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color:  ${     ({ theme, eClicado }) => eClicado ? theme.palette.primary.main : grey[400]};
  
  &:hover {
     color: ${({ theme }) => theme.palette.primary.light};
 }
  
 }
`;
 