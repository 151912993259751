import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


import SiteFramework from '../../components/site/view.site-framework.jsx'
import Loading from '../../components/Loading/view.loading2.jsx';

import Card from '../../views/components/view.card.jsx';
import CardTitulo from '../../views/components/view.card.titulo.jsx';
import ListaCard from './view.lista.card.jsx';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

import { useListas } from './model.lista.js';
import { useTela } from '../../services/service.tela.provider.jsx';
import { useLocalStorage } from '../../services/service.hooks.js';
import ControllerListaDeletar from './controller.lista.deletar.jsx';
import ControllerListaEncerrar from './controller.lista.encerrar.jsx';
import Stats from './StatsLista.jsx';

export default function Listas () {
  
  const location = useLocation ();
  const navigate = useNavigate ();
  
  const [ tela, setTela ] = useTela();
  const [loading, setLoading] = useState(tela.anterior === 'NovoLink' || tela.anterior === 'LinkStats' || tela.anterior === 'LinkAlunos' || tela.anterior === 'VerQuestoes' ? 
    {inicio: false, fim: false, isSuccess: false} : {inicio: true, fim: false, isSuccess: false});
  
  const [ usuario ] = useLocalStorage ("usuario");
  
  const [ listas ] = useListas ({params: { id_usuario: usuario.id}}, loading.inicio, setLoading, location)
  const [ listasLocalStorage , setListasLocalStorage ] = useLocalStorage ("listas")
  
  console.log (tela.anterior)
  const [openDeletar, setOpenDeletar] = useState (false);
  const [openEncerrar, setOpenEncerrar] = useState (false);
  const [openEditar, setOpenEditar] = useState (false);
  const [openStats, setOpenStats] = useState (false);
  
  const id_link = useRef ('');
  const questoes_total = useRef ('');
  const [id_lista, setIdLista] = useState (eVoltar (tela) ? location.state.id_lista : -1);

  function handleBtnAcoes (btn, id_link_valor, id_lista_valor, turma, questoes_total_valor) {
    
    id_link.current = id_link_valor;
    questoes_total.current = questoes_total_valor
    setIdLista (id_lista_valor);
   
    switch (btn) {
      
      case 'deletar':
        setOpenDeletar (true);
        break;
      
        case 'encerrar':
        setOpenEncerrar (true);
        break;
      
        case 'editar':
        setOpenEditar (true);
        break;
      
        case'stats':
        setTela ('LinkStats')
        navigate ('/listas/link/stats', {state: {id_link: id_link.current, id_lista: id_lista_valor, turma: turma}})
        break;
        
      case'alunos':
        setTela ('LinkAlunos')
        navigate ('/listas/link/alunos', {state: {id_link: id_link.current, id_lista: id_lista_valor, turma: turma}})
        break;
        
        default:
        break;
    }
  }
  return (
    <>
     
    
        {/* ************** LOADING ************** */}
        <Loading local="conteudo" loading={loading} online={loading.online} tipo='imagem' msg={"Buscando as listas..."} mostrarErroOnlineStatus /> 
        
        {openDeletar===true && <ControllerListaDeletar open={openDeletar} setOpenDeletar = {setOpenDeletar} id_link = {id_link.current} questoes_total= {questoes_total.current}/>}
        {openEncerrar===true && <ControllerListaEncerrar open={openEncerrar} setOpenEncerrar = {setOpenEncerrar} id_link = {id_link.current} /> } 
        
        
        {/* ************** LISTAS ************** */}
        {tela.anterior === 'NovoLink' || tela.anterior === 'LinkStats' || tela.anterior === 'LinkAlunos' || tela.anterior === 'VerQuestoes' ?       
        <Card titulo={<CardTitulo icon={<FormatListBulletedOutlinedIcon />}  titulo = 'Listas' slide/> }  margin_top={20} fontSizeTitulo="1.2rem" divider mtChildren={2}>
        
            <ListaCard id_lista ={ id_lista} listas = {listasLocalStorage} onClick = {(btn, id_link, id_lista, turma, questoes_total) => handleBtnAcoes (btn,id_link, id_lista, turma, questoes_total)} />
        </Card> 
        :   
        
        (tela.anterior !== 'NovoLink' && tela.anterior !== 'LinkStats' && tela.anterior !== 'LinkAlunos' && tela.anterior !== 'VerQuestoes') && loading.online && loading.inicio===false && loading.fim===true && loading.isSuccess===true ?
        <Card titulo={<CardTitulo icon={<FormatListBulletedOutlinedIcon />}  titulo = 'Listas' slide />}  margin_top={20} fontSizeTitulo="1.2rem" divider mtChildren={2}>
           <ListaCard id_lista ={ id_lista} listas = {listas} onClick = {(btn, id_link, id_lista, turma, questoes_total) => handleBtnAcoes (btn,id_link, id_lista, turma, questoes_total)} />
         </Card> 
        : null
        }   
          
             
        
        
        </>
    
  )}

function eVoltar (tela) {
  if (tela.anterior === 'NovoLink' || tela.anterior === 'LinkStats') {
    return true
  }

  return false;

}