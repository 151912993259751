import { chamarApi } from "../../../services/service.api";

export function Encerrar (data, callback) {
    
    const getResult = async () => {
        
        try {
            
            const result = await chamarApi ({type: 'put', endpoint: 'alunoEncerrar', data: data}) // chama a API
            
            console.debug ('lista encerrar', result)
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}