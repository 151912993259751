
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


export function exportar(data, fileName) {
  return new Promise((resolve, reject) => {
    try {
      // Cria uma nova planilha
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Converte a planilha para um arquivo binário
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Função para converter string para array buffer
      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      };

      // Salva o arquivo
      fileName = sanitizeFilename(fileName);
      saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
      
      resolve('ok');
    } catch (error) {
      reject(error);
    }
  });
}

  function sanitizeFilename (filename) {
    // Definindo caracteres inválidos para Windows, Mac e Linux
    const invalidChars = /[\/\?<>\\:\*\|":\x00-\x1F\x80-\x9F]/g;
    
    // Substituindo os caracteres inválidos por um espaço em branco
    const sanitizedFilename = filename.replace(invalidChars, ' ');
    
    return sanitizedFilename;
  }
  


