import  './styles.css';
import { useState, useRef } from 'react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { Payment } from '@mercadopago/sdk-react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material';

import { API_KEY } from '../../data/config/config'

import Loading from '../Loading/view.loading2';
import Card from '../../views/components/view.card';
import CardTitulo from '../../views/components/view.card.titulo';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';

import useOnlineStatus from '@rehooks/online-status';

import * as MP from './controller.mercado.pago'
import * as modelPagamento from './model.pagamento'

function MercadoPago (props) {
  
  const online = useOnlineStatus();
 
  const theme  = useTheme();
  const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false, online: online});
  
  // INICIALIZA O MERCADO PAGO
  initMercadoPago (API_KEY, {locale: 'pt-BR'});
  
  // ENVIA DADOS PARA O SERVIDOR
  const onSubmit = async ({formData}) => {
  
    formData = {...formData, metadata: {id_usuario: props.idUsuario, valor: props.dados.valor, periodo: props.dados.periodo, transaction_amount: props.dados.transaction_amount}};
  
    modelPagamento.Enviar (formData, async function (result) {

      if (result.isSuccess) {
        
        props.onFinish (result, formData.payment_method_id);

      } else {

        console.debug (result)
        props.onFinish (result, formData.payment_method_id);

      }
    })
 };
     
  // DEFINE O MODULO DO MERCADO PAGO SDK    
  const paymentRef = useRef();

  if (!paymentRef.current) {
      
    paymentRef.current = (
      
      <Box style= {{ml:-2, mr:-2, px:-2, }} >
      
        <Payment
          
          initialization={MP.initialization (props)}
          customization={MP.customization (theme)}
          onSubmit={onSubmit}
          onReady={(e) => MP.onReady (e, setLoading)}
          onError={MP.onError}
        
        />
    
      </Box>
    
    );
}

return <> 

  <Card titulo={<CardTitulo icon={<PaymentOutlinedIcon />}  titulo = 'Mercado Pago' />}  margin_top={20} fontSizeTitulo="1.2rem" divider mtChildren={-1} >   
  
      
    <Loading local="componente" loading={loading} tipo='imagem' online={online} mostrarErroOnlineStatus/> 
    
    <Box sx={{mb:1, mt: 4, width: '100%'}}>
    
      {paymentRef.current}
    
    </Box>
   
  </Card>
   
</>

} export default MercadoPago;