import { useState, useEffect } from "react";
import { Collapse, Avatar, Chip, Box, Typography, useTheme, Slide, Grow, Tooltip } from "@mui/material"
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import HtmlPainel from '../../../../components/HtmlPainel/view.html.panel';

export default function GabaritoComentado (props) {

    const theme = useTheme ();

    const bgColor = theme.palette.background.paper
    const chipColor = theme.palette.primary.main
    const textColor = theme.palette.cinza.dark

    const [fadeKey, setFadeKey] = useState(0);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setFadeKey(prevKey => prevKey + 1);
        setChecked (true);
      }, [props.texto]);

    return (

        <>
        
        <Box >
        <Grow in={true} key={fadeKey} timeout={1000} >
        <Box sx = {{ ml:4, mr: 4}} >
        
        
        <Chip sx={{ color: textColor, ml:-1, mt:3, mb:1}} variant="outlined"  avatar = {<Avatar> <AssignmentOutlinedIcon sx={{backgroundColor: bgColor}}/> </Avatar>} label = {"Questão " + props.atual} />

        <Box sx={{mt:2, mb:2}}>
        <Typography color = {textColor} sx = {{textAlign: "justify"}}>
                    
             <HtmlPainel html = {tornarImagensResponsivas (props.texto)} />

        </Typography>
        </Box>
        </Box>
        </Grow>
        </Box>
        
        </>

    )
}

function tornarImagensResponsivas(html) {
        
    // Encontrar todas as tags img e modificar seus atributos
    return html.replace(/<img([^>]*)>/g, (match, capture) => {

    // Se a tag já contém um estilo, adicionamos os estilos de responsividade a ele
    if (capture.includes('style="')) {
    return match.replace(/style="/, 'style="max-width: 100%; height: auto; object-fit: contain; ');
  
}
    // Se a tag não contém um estilo, adicionamos um novo atributo de estilo
    return `<img style="max-width: 100%; height: auto; object-fit: contain;"${capture}>`;

});
} 