import { Chip, Grid, Box, Typography, Slider } from '@mui/material';
import { useLocalStorage } from '../../services/service.hooks';
import Card from '../../views/components/view.card';
import CardTitulo from '../../views/components/view.card.titulo';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

export default function SelecionarCreditos (props) {
    
  const [ usuario ] = useLocalStorage ('usuario');
     
  // RETORNA O TEXTO DO NUMERO DE CREDITOS NO SLIDER
  function valuetextcreditos(value) {
    
    props.onChange (value, props.periodos.current);
    return `${value}`;

  }
  
  // RETORNA O TEXTO DO NUMERO DE PERIODOS NO SLIDER
  function valuetextperiodos(value) {
    
    props.onChange (props.creditos.current, value);
    return `${value}`;

  }

    return (
      <Card titulo={<CardTitulo icon={<ShoppingCartOutlinedIcon />}  titulo = 'Selecionar' />}  margin_top={20} fontSizeTitulo="1.2rem" divider >
    
                  
              <Chip label={'Créditos disponíveis: ' + usuario.numcreditos} sx={{fontSize: 15, fontWeight: 400}}  />
              
              {/* ************** SLIDER CRÉDITOS ************** */}
              <Grid container spacing={1} sx={{mt:0.5, mb:2.5}} >
                  <Grid item xs={12} md={6}>
                  <Box sx={{  mt: 2, mx: 3 }}>
                    <Typography id="Créditos" gutterBottom>
                      Créditos: 
                    </Typography>

                    <Slider
                    sx={{mb:-1.5, mt:-1.5}}
                      aria-label="Créditos"
                      min={100}
                      max={1000}
                      defaultValue={props.creditos.current}
                      getAriaValueText={valuetextcreditos}
                      step={100}
                      valueLabelDisplay="on"
                      
                    />
                  </Box>
                  </Grid>

                  {/* ************** SLIDER PERIODO ************** */}
                  <Grid item xs={12} md={6}>
                  <Box sx={{  mt: 2, mx: 3 }}>
                    <Typography id="Períodos" gutterBottom>
                      Períodos: 
                    </Typography>
                    
                    <Slider
                    sx={{mb:-1.5, mt:-1.5}}
                      aria-label="Período"
                      min={1}
                      max={12}
                      defaultValue={props.periodos.current}
                      getAriaValueText={valuetextperiodos}
                      step={1}
                      valueLabelDisplay="on"
                      
                    />
                    <Typography  id="Período2" variant="caption" gutterBottom>
                      número de meses da validade dos créditos
                    </Typography>
                  </Box>
                  </Grid>
                  </Grid>
                  
                </Card>
    )                
}