import { Grid, Typography, Divider } from "@mui/material"

export default function TituloGabarito (props) {
    
    const { gabarito_objetiva } = props
    
    return <>
    
    {gabarito_objetiva &&
        
        <Grid item xs={12} >
        
        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
        
            Gabarito
        
        </Typography>
        
        <Divider sx={{mt:1}}/>
        
        </Grid>
    }
    </>
}