import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SiteFramework from '../../components/site/view.site-framework.jsx'
import ModalSucessoErro from "../../components/ModalSucessoErro/ModalSucessoErro.jsx";


export default function Logout () {
    
    const [open, setOpen] = useState (true);
    const location = useLocation ();
    const navigate = useNavigate ();

    function handleClose (e) {
        
        
        setOpen (false);
        console.debug ('location', location);
        navigate (location.state.tela);
    }

    function handleSair (e) {
        
        e.preventDefault();
        localStorage.clear ();
        setOpen (false);
        navigate ('/conta/login');

    }

    return (
        <SiteFramework 
            children = {
            <>
            <ModalSucessoErro 
                
                open = {open} 
                msg  = {"Você realmente deseja sair da plataforma?"} 
                data = {{errorTipo: "warning", 
                         btn1: {mostrar: true, label: "Voltar", color: "secondary", component: null}, 
                         btn2: {mostrar: true, label: "Sair", color: "primary", component: null}}} 
                
                onClickBtn1={(e) => handleClose (e)} onClose={(e) => handleClose(e)}
                onClickBtn2={(e) => handleSair (e)} success={false} 
                
            />
       </>} />
    )
}
