import { useEffect, useState } from 'react';
import {Tooltip, Stack, Grid, CircularProgress, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Switch, Divider } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import GenericField from '../../../../components/Form/view.form.generic.field';
import Erro from './view.erro';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

export default function GerarProvaView (props) {
    console.count ('GerarProvaView')
    const { open, handleClose, condicoes, loading, arquivo, handleGerar, setArquivo } = props
    const [ resolucaoDesligar, setResolucaoDesligar ] = useState (false);
    const [disabled, setDisabled] = useState (true);

    useEffect (() =>  {
      
            if (arquivo === '') {
              
              setDisabled (true)
            } else setDisabled (false);
            
          }, [arquivo])   

    function handleGabarito (e) {
      
      e.preventDefault(); 
      condicoes.current.gabarito = e.target.checked

      if (!condicoes.current.gabarito) {
        
        setResolucaoDesligar (true)
      
      } else {
        
        setResolucaoDesligar (false)

      }

    }

    return (
        <>
        
        <Dialog open={open} onClose={handleClose} >
        <DialogTitle>
        <Stack direction="row" spacing={1} alignItems='center' >
          <ReceiptLongOutlinedIcon />
          <span>Gerar Prova
          </span>
        
          <Tooltip title={<span>

          </span>
 
          } >
          <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>
          </Tooltip>
          </Stack>
          <Divider sx={{mt:1}} /> 
        
        </DialogTitle>
        
        <DialogContent>
          
          <DialogContentText mt={1}>
        
              Selecione as preferências para gerar a prova
        
          </DialogContentText>
          
          <FormGroup sx={{mt:4}}>
             
            <Grid container spacing={6} >
            
              <Grid item>
              <Tooltip title={'Determina se a fonte da questão (UFRJ, FUVEST, etc) deve ser incluída'} arrow style={{whiteSpace: 'pre-line'}}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                
                <FormControlLabel control={<Switch defaultChecked onChange={(e) => {e.preventDefault(); condicoes.current.fonte = e.target.checked}} />} label="Fonte" />
               
        
                <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>
        
                
                </Stack>
                </Tooltip>
              </Grid>
              
              <Grid item>
              <Tooltip title={'Determina se o ano da questão (2024, 2023, etc) deve ser incluído'} arrow style={{whiteSpace: 'pre-line'}}>
              <Stack direction='row' spacing={1} alignItems={'center'}>
                <FormControlLabel control={<Switch defaultChecked onChange={(e) => {e.preventDefault(); condicoes.current.ano = e.target.checked}} />} label="Ano" />
                
        
        <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>

        
        </Stack>
        </Tooltip>
              </Grid>

            </Grid>

            <Grid container spacing={3} >
              <Grid item >
              <Tooltip title={'Determina se o gabarito da questão deve ser incluído'} arrow style={{whiteSpace: 'pre-line'}}>
              <Stack direction='row' spacing={1} alignItems={'center'}>
                <FormControlLabel control={<Switch  defaultChecked onChange={(e) => {handleGabarito (e)}} />} label="Gabarito" />
                
        
        <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>

        
        </Stack>
        </Tooltip>
              </Grid>
              
              <Grid item>
              <Tooltip title={'Determina se a resolução da questão deve ser incluída'} arrow style={{whiteSpace: 'pre-line'}}>
              <Stack direction='row' spacing={1} alignItems={'center'}>
                <FormControlLabel control={<Switch defaultChecked onChange={(e) => {e.preventDefault(); condicoes.current.resolucao = e.target.checked}} />} label="Resolução" disabled = {resolucaoDesligar} />
                
        
        <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>

        
        </Stack>
        </Tooltip>
              </Grid>

            </Grid>

            <Grid container spacing={3} >
              <Grid item >
              <Tooltip title={'Determina se o arquivo no formato Word deve ser gerado (caso você não precise editar a prova ou enviar para a escola imprimir, desmarque essa opção)'} arrow style={{whiteSpace: 'pre-line'}}>
              <Stack direction='row' spacing={1} alignItems={'center'}>
                <FormControlLabel control={<Switch  defaultChecked onChange={(e) => {e.preventDefault(); condicoes.current.arquivo_word = e.target.checked}} />} label="Arquivo Word" />
                
        
        <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>

        
        </Stack>
        </Tooltip>
              </Grid>
              </Grid>





            <Box sx={{mt:2}}>
            
                <GenericField label={'Disciplina - Série - Assunto'} onChange={(valor) => {setArquivo (valor) }} />
            
            </Box>
            
            <Typography variant="body2" sx={{color: "text.secondary"}} mx={1}>
            
                Utilize um nome que identifique facilmente a prova.
            
            </Typography>
            
            <Typography variant="body2" sx={{color: "text.secondary"}} mx={1} mb={1}>
            
                Exemplo: Física - 1º série - Leis de Newton
            
            </Typography>
          
          </FormGroup>
         
        {/* Alerta de erro */} 
        <Erro loading = { loading } />

        </DialogContent>
        
        <DialogActions>
        
          <Button color='secondary' onClick={handleClose}>Voltar</Button>
          
          {loading.inicio && !loading.fim ? 
          <Button disabled={true} ><CircularProgress size={20} /></Button>
          : 
          <Button   onClick={(e) => handleGerar (e)} disabled={disabled} >Gerar</Button>}

        </DialogActions>
      
      </Dialog>
    
        </>
    )
}