import { Grid, Button } from "@mui/material"

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';   

import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export default function BotaoSelecionarRemover (props) {

    const { onClickSelecionar, onClickRemover, isMobile, escolhida, numEscolhidas } = props
    
    const BtnCustom = styled(Button)(({ theme }) => ({
         
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[300],
        },
      }));

    return (

        <>
        {!isMobile ?
        <Grid item >
            
            {!escolhida &&
                <BtnCustom variant="text" size="small" onClick={onClickSelecionar}>
             
                    Selecionar
                    
                </BtnCustom>
            }
                
            {escolhida &&
            
                <Button variant="text" size="small" color="vermelho" onClick={onClickRemover}  >
                
                    Remover
                
                </Button>
            }    
            
        </Grid>
        
        :
        <Grid item xs = { 3 } sx = {{ ml:2 }}>
            
            {!escolhida &&
            
            <Button variant="outlined"  onClick={onClickSelecionar}>
            
                <AddIcon sx = {{ ml:-1, mr:-1 }} />
            
            </Button>
           
           }

           {escolhida &&
           
           <Button variant="outlined"  color= 'vermelho' onClick={onClickRemover}>
           
                <RemoveIcon sx = {{ ml:-1, mr:-1 }}/>
                
           
            </Button>
            }   
        </Grid>
}
        </>
    )
}