import { useState, useRef } from 'react';
import ModalSucessoErro from '../ModalSucessoErro/ModalSucessoErro';
import Loading from '../Loading/view.loading';
import { chamarApi } from '../../services/service.api';

import { InputAdornment, IconButton, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Alert, AlertTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { validaEmail2 } from './controller.login';


function ResetarSenha (props) {

    //const { dados } = props;    
    const [loading, setLoading] = useState ({inicio: false, fim: false, isSuccess: false, msg: 'Checando a validade do email...', response: null, error: null,  modalTipoGeral: 'snack', modalMsg: 'Erro desconhecido', modalVariant: "filled", modalTipo: "success", modalIsAlert: true, modalTimeout: 3000});
    const [estado, setEstado] = useState(0); // em qual parte do cancelamento está o usuário (0 - email, 1 - Loading, 2 - código, 3 - Loading, 4 - senha, 5 - loading , 6 - msgfinal)
    const [titulo, setTitulo] = useState('Email');
    const [subtitulo, setSubtitulo] = useState('Digite o seu email para receber o código de confirmação');
    const [code, setCode] = useState(''); // código de confirmação enviado por email [0] a [5
    const [senha, setSenha] = useState('');
    const [repetirSenha, setRepetirSenha] = useState('');
    const [open, setOpen] = useState(true);
    const [msg, setMsg] = useState('profbrunocfernandes@gmail.com');
    const [showPassword, setShowPassword] = useState(false);
    const [idUsuario, setIdUsuario] = useState (-1);
 
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    async function EnviarEmailServidor () {
        setLoading ({...loading, inicio: true})
                
        const result = await chamarApi ({type: 'get', endpoint: 'senhaSolicitarResetRota', data: {params: {email: msg}}})
        
        if (result.isSuccess) {
            setTitulo ('Código');
            setSubtitulo ('Digite o código de confirmação enviado para o seu email');
            setLoading ({...loading, inicio: false, fim: true, isSuccess: true})
            setEstado (2);
        } else {
            setLoading ({...loading, inicio: false, fim: true, isSuccess: false, erro: result.erro, msg: result.msg});
        }       
    }
    
    async function EnviarCodigoServidor () {
        setLoading ({...loading, inicio: true, fim: false})
                
        const result = await chamarApi ({type: 'post', endpoint: 'senhaValidarTokenRota', data: {email: msg, token: code}})
        
        if (result.isSuccess) {
            setTitulo ('Nova senha');
            setSubtitulo ('Digite a nova senha');
            setLoading ({...loading, inicio: false, fim: true, isSuccess: true})
            setIdUsuario (result.response[0].id)
            setEstado (4);
        } else {
            setLoading ({...loading, inicio: false, fim: true, isSuccess: false, erro: result.erro, msg: result.msg});
        }
    }
    
    async function EnviarSenhaServidor () {
        
        setLoading ({...loading, inicio: true, fim: false})
        const result = await chamarApi ({type: 'patch', endpoint: 'senhaEditarSenhaRota', data: {id_usuario: idUsuario, email: msg, token: code, senha: senha}})
        
        
        if (result.isSuccess) {
            setEstado (6);
            setLoading ({...loading, inicio: false, fim: true, isSuccess: true,  msg: 'Senha resetada com sucesso!'})
        } else {
            setLoading ({...loading, inicio: false, fim: true, isSuccess: false, erro: result.erro, msg: result.msg});
        }
    }
        
    function handleFechamento (e) {
        
        setSenha ('');
        setRepetirSenha ('');
        setCode ('');
        setMsg ('');
        setEstado (-1);
        setOpen (false);
        props.onClose();
    }   

    return <>
    
        
        <Dialog open={open}>
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {subtitulo}
          </DialogContentText>
          <Box sx={{marginTop: '2em'}}>
          {estado === 0 ?     
          <>
          <TextField
            onChange={(e) => setMsg (e.target.value)}
            fullWidth
            type="email"
            id="mensagem"
            label="Email"
            variant="outlined"
            autoFocus
            error={!(validaEmail2  (msg) || msg ==='')}
            helperText={!(validaEmail2  (msg) || msg ==='') ? 'Não é um email válido' : ''}
            />
          </> : null}
          
          {estado === 2 ? <>
            <TextField
            onChange={(e) => setCode (e.target.value)}
            fullWidth
            type="text"
            id="codigo"
            label="Código de 6 dígitos"
            variant="outlined"
            autoFocus
            inputProps={{minlength: 6, maxlength: 6, style: {fontSize: 25}}}
            />
            </> 
          : null}   
            
          {estado === 4 ? <>
            <TextField
            onChange={(e) => setSenha (e.target.value)}
            fullWidth
            id="senha"
            label="Nova senha"
            variant="outlined"
            autoFocus
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }}
            />
            <TextField
            sx = {{marginTop: '1.5em'}}
            onChange={(e) => setRepetirSenha (e.target.value)}
            fullWidth
            id="repetirsenha"
            label="Repetir senha"
            variant="outlined"
            
            
            type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }}
                error={!(senha===repetirSenha || senha === repetirSenha === '' || (senha !== '' && repetirSenha === ''))}
                helperText={!(senha===repetirSenha || senha === repetirSenha === '' || (senha !== '' && repetirSenha === '')) ? 'As senhas devem ser iguais.' : ''}
            />
            </>
           : null}
        
        {loading.isSuccess===false && loading.fim && loading.inicio===false &&
              <Box mt={2}>
              <Alert severity="error" >
              <AlertTitle>
              {loading.erro}
              </AlertTitle>  
                {loading.msg}
            </Alert>
            </Box>
        
}
            </Box>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={(e) => {handleFechamento (e)}} color={'vermelho'}>Cancelar</Button>
          
          {estado === 0 && validaEmail2 (msg) && <LoadingButton loading={loading.inicio} color={'primary'} onClick={() => {EnviarEmailServidor()}} ><span>Enviar</span></LoadingButton>}
          {estado === 2 && code.length === 6  && <LoadingButton loading={loading.inicio} color={'primary'} onClick={() => {EnviarCodigoServidor()}}><span>Enviar</span></LoadingButton>}
          {estado === 4 && (senha === repetirSenha) && <LoadingButton loading={loading.inicio} onClick={() => {EnviarSenhaServidor()}} ><span>Enviar</span></LoadingButton>}
          
        </DialogActions>
      </Dialog>
  
    
    {(estado === 6) && loading.isSuccess && loading.fim ? <ModalSucessoErro open={true} data={{successTipo: 'success', btn1: {label: "OK", color: "primary", mostrar: true}, btn2: {mostrar: false}}} success msg={'Senha alterada com sucesso!'} btn1='OK' onClose={(e) => handleFechamento(e)} /> : null}
        
    </>
} export default ResetarSenha;
