import { useEffect, useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function RepetirSenhaField (props) {
  
  const { disabled } = props;
  const [valor, setValor] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [senhaIgual, setSenhaIgual] = useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => { event.preventDefault(); };
    
  useEffect(() => {
        
    //props.onValidate (valor, setSenhaIgual)
  
  }, [valor]);

  useEffect(() => {
        console.log ('props.senha', props.senha)
    console.log ('valor', valor)
       validarSenha (valor)
  
  }, [props.senha]);

  function Atualiza (e) {
  
    setValor (e.target.value);
    props.onChange (e)
    validarSenha(e.target.value);
    
   }
    
  function validarSenha(dado) {
  
    

        if (dado !== '' || props.senha !== '') {

          if (dado === props.senha) {
          
            setSenhaIgual(true);
            props.senhaIgual (true)

          } else {
          
            setSenhaIgual(false);
            props.senhaIgual (false)
          }
        } 
    
      }

  return (
    
    <TextField

    disabled={disabled}
    size="small"
    required
    fullWidth
    margin="normal"
    name="repetirsenha"
    label="Repetir a senha"
    
    value={valor}
    type={showPassword ? 'text' : 'password'}
    
    InputProps = {{
      endAdornment: <InputAdornment position="end">
    
    <IconButton
    
    aria-label="toggle password visibility"
    onClick={handleClickShowPassword}
    onMouseDown={handleMouseDownPassword}
    
    >
    
    {showPassword ? <VisibilityOff /> : <Visibility />}
    
    </IconButton>
    </InputAdornment>,
    
  }}

    onChange={(e) => Atualiza(e)}
    error={!senhaIgual}
    helperText={!senhaIgual ? 'As senhas devem ser iguais.' : ''}

      />
      );

} export default RepetirSenhaField;

RepetirSenhaField.defaultProps = {
    
  disabled: false,
  autofocus: false,
  senha: '',
  onChange: () => {}

}
