import { chamarApi } from '../../services/service.api';

export function Validos (data, callback) {
    
    const getResult = async () => {
        try {
               
            const result = await chamarApi ({type: 'get', endpoint: 'creditosTotal', data: data}) // chama a API
            
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult(); 
    }

