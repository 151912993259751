import SnackbarAviso from '../../components/SnackBar/SnackBarAviso';

export default function Alerta ({loading, setLoading}) {
    
    return (

        <>
        
        <SnackbarAviso 
            
            open       = {loading.fim} 
            tipo       = {loading.isSuccess ? 'success' : 'error'} 
            msg        = {loading.msg} 
            onClose    = {() => setLoading ({...loading, fim: false})} 
            time       = {3000} 
        />

        </>
    )
}