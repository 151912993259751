import {useState, useEffect, Fragment, useContext}  from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Divider, LinearProgress, Grid, Slide, Tooltip, Button, Box, Typography, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, useTheme, Chip}  from '@mui/material';

import Card from '../../views/components/view.card';
import CardTitulo from '../../views/components/view.card.titulo';
import BotaoVoltar from '../../components/BotaoVoltar/view.botao.voltar2';
import Loading from '../../components/Loading/view.loading2.jsx';

import { useTela } from '../../services/service.tela.provider';
import { useLinkStats } from "./model.lista.link";
import { QuestoesContext } from '../../services/service.questoes.provider'
 
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import SnackbarAviso from '../../components/SnackBar/SnackBarAviso';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

export default function AlunosInscritos (props) {
  
  
  const navigate = useNavigate ();
  const location = useLocation ();
  const [id_lista] = useState (location.state ? location.state.id_lista : 0)
  const [id_link] = useState (location.state ? location.state.id_link : 0)
  const [turma] = useState (location.state ? location.state.turma : '')
  const { usuario } = useContext (QuestoesContext)

  const [listas_questoes, setListaQuestoes] = useState (buscarNoLocalStorage('listas_questoes'));
  const [loading, setLoading] = useState ({inicio: true, fim: false, isSuccess: false})
  const [questoes, setQuestoes] = useState (buscarNoLocalStorage('listas_questoes', id_lista));
  
  const [, setTela] = useTela ();
  const [stats] = useLinkStats ({params: {id_usuario: usuario.id, id_link: id_link}}, loading.inicio, setLoading, questoes)
  
  const [alunos, setAlunos] = useState ({})
    
  const [questoes_validas] = useState (questoesValidas (questoes));
  const [liberado, setLiberado] = useState (false)
  
  

  useEffect (() => {
    
    if (loading.inicio === false) {
    alunos.dados = RespostasParaArray (stats.alunos)
    alunos.total = alunosTotal (stats.alunos);
    alunos.queFinalizaram = alunosQueEntregaram(stats.alunos)
    alunos.queComecaram = alunosQueComecaram(stats.alunos)
    alunos.queNaoComecaram = alunosQueNaoComecaram(stats.alunos)
    alunos.dados = ordenarPorNomeCompleto (alunos.dados)
    setAlunos (alunos)
    
    if (stats.questoes) {
       let a = [...listas_questoes, {id_lista: id_lista, questoes: stats.questoes}]
       localStorage.setItem ('listas_questoes', JSON.stringify (a))
       
       setQuestoes (stats.questoes)
    } 
     
    setLiberado (true)
     }
    }, [loading.inicio])
  
  return ( 
    <>
    {/* ************** LOADING ************** */}
    <Loading local="conteudo" loading={loading} online={loading.online} tipo='imagem' msg={"Buscando as informações..."} mostrarErroOnlineStatus /> 

    <Box sx={{width: 'auto'}} >
    
    <BotaoVoltar onClick={(e) => {e.preventDefault(); setTela ('Listas'); navigate('/listas', {state: {selected: '', id_lista: location.state.id_lista}})}} titulo={'Turma: ' + turma} />

    <Card titulo={<CardTitulo titulo='Alunos' icon={<PeopleOutlinedIcon />} slide/>}  margin_top={20} fontSizeTitulo="1.2rem" divider >
    
    <Box sx={{width: '100%', display: 'flex', flexDirection:'column'}}>
    
    {liberado &&
      <>
      <Resumo alunos={alunos} questoes_validas={questoes_validas} questoes={questoes}/>
      <Box mt={2}>
      <Divider />
      </Box>
      </>
    }
    
    {liberado && 
    <TableContainer component={Paper} sx={{mt:2, mb: 2, boxShadow: 'none'}}>
      <Table aria-label="collapsible table" size="small" sx={{ '& > *': { borderBottom: 'unset' }}}>
        
        <TableHeadCustom />
        
        <TableBody>
         
          {
          alunos.dados.map((row, index) => (
            <Row key={row.nome+row.sobrenome} row={row} num ={index} questoes_validas={questoes_validas} />
          ))
         
         }
        </TableBody>
      </Table>
    </TableContainer>
}
{liberado && alunos.dados.length === 0 &&
      <Box sx={{mt:2, display: 'flex', justifyContent:'center', alignItems:'center' }}>
        
        <Typography sx={{color: 'primary.main', fontSize: 16}} >Nenhum aluno inscrito nesta lista.</Typography>
      </Box>
    }
</Box>
     
    </Card>
    </Box>
    </>
  );
}

 

function Row (props) {
  const { row,  num, questoes_validas } = props;
  
  const [ open ] = useState(false);
  const [ chip ] = useState(Status (row));
   
  return (
    
    <Fragment>
      <Slide in={true} direction='down' timeout={1000} ease  >
      <TableRow sx={{ '& > *': { borderBottom: 'none', pb:-1, pt:1}  }} hover selected={open}>
        
        {/* NUMERO */}
        <TableCell sx={{py:0}}  align="left">
            <Chip label={num + 1} variant='outlined' size='small' sx={{color: 'primary.main', fontWeight: '500', ml:0, mb:1, mt:1}} />
        </TableCell>      
        
        {/* NOME */}
        <TableCell sx={{py:0}}  align="left">{row.nome + ' ' + row.sobrenome} </TableCell>
        
        {/* QUESTOES FEITAS / QUESTOES TOTAL */}
        <TableCell sx={{py:0}} align="left">{
         questoesRespondidas (row) + '/' + questoes_validas
        }</TableCell>
        
        {/* LINK */}
        <BotaoCopiarToken token={row.token_aluno} />
        
        {/* STATUS */}
        <TableCell sx={{py:0}} align="left">{<Chip label={chip.label} size='small' sx={{backgroundColor: chip.color, color: 'cinza.dark', fontWeight: '400', ml:0, mb:0.5}} />}</TableCell>
                
      </TableRow>
      </Slide>
    </ Fragment>
    
  );
}

function Resumo (props) {
  
  const [total_alunos] = useState (alunosTotal (props.alunos.dados))
  const [progress] = useState (pctAlunosQueEntregaram(props.alunos.queFinalizaram, props.alunos.total))
  const [progress2] = useState (questoesPctRespondidas (props.questoes, props.questoes_validas, props.alunos.dados))
  const [atual, setAtual] = useState(0);
  const [atual2, setAtual2] = useState(0);
  
  useEffect (() => {
    const timer = setInterval(() => {

      setAtual ((oldAtual) => {

          if (oldAtual === progress) {
        
            clearInterval(timer);
        
          }
        
        const diff = 2;
        
        return Math.min(oldAtual + diff, progress);

      });
    }, 100);

    const timer2 = setInterval(() => {

      setAtual2 ((oldAtual2) => {

          if (oldAtual2 === progress2) {
        
            clearInterval(timer2);
        
          }
        
        const diff = 5;
        
        return Math.min(oldAtual2 + diff, progress2);

      });
    }, 100);

    return () => {
      clearInterval(timer);
      clearInterval(timer2);
    };
}
, [])
  
  return (

    <>
    
    <Box sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
    <Chip label={'Inscritos: ' + props.alunos.total} size='small' sx={{fontWeight: '500', ml:0, mb:0.5}} />
    <Box sx={{width: '100%'}} mx={6}>
    <PctFinalizaram atual={atual} />
    </Box>
    <Box sx={{width: '100%'}}>
    <PctQuestoes atual={atual2} />
    </Box>
    </Box>
    



      


    
    </>
  )
}

function PctQuestoes (props) {

  return (

    <>
      <Box sx={{ alignItems: 'flex-start', flexDirection:'column' }}>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                
                  <LinearProgress variant="determinate" value = { props.atual } />
                
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                  
                  <Typography fontSize={12}>
                      {Math.round(props.atual) + '%'}
                  </Typography>
                  
            
                  </Box>
                  </Box>
            
                  <Typography fontSize={12}>
                     % das questões respondidas
                  </Typography>
                  </Box>
            
    </>
  )
}


function PctFinalizaram (props) {

  return (

    <>
      <Box sx={{ alignItems: 'flex-start', flexDirection:'column' }}>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                
                  <LinearProgress variant="determinate" value = { props.atual } />
                
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                  
                  <Typography fontSize={12}>
                      {Math.round(props.atual) + '%'}
                  </Typography>
                  
            
                  </Box>
                  </Box>
            
                  <Typography fontSize={12}>
                     % dos alunos que finalizaram a lista
                  </Typography>
                  </Box>
            
    </>
  )
}

function TableHeadCustom () {

    const alunosNomeColunas = [
      {label: 'Num'}, 
      {label: 'Nome'},
      {label: 'Questões feitas', tooltip: 'Quantidade de questões feitas pelo aluno / Questões totais válidas (apenas as questões de múltipla escolha)'},
      {label: 'Token', tooltip: <span>

        Forneça esse número de token caso o aluno necessite.<br/><br/>
        Cada aluno recebe um número de token único ao acessar a lista pela primeira vez. <br/><br/>

        Porém, caso o aluno troque de dispositivo (acesse a lista no celular e, posteriormente, pelo computador, por exemplo), o sistema pedirá o número do token para recuperar as respostas das questões dadas pelo aluno até então.<br/><br/>

        Portanto, esse token é necessário caso o aluno não anote o número ao acessar a lista pela primeira vez.
      </span>


      },
      
      {label: 'Status', tooltip: <span>
        <Chip label='Entregue' size='small' sx={{backgroundColor: 'success.light', color: 'cinza.dark', fontWeight: '400', mr: 2}} />
        <Box display='block' mt={1}>
        Alunos que resolveram todas as questões e, portanto, finalizaram a lista.<br/><br/>
        </Box>
        <Chip label='Iniciada' size='small' sx={{color: 'cinza.light', fontWeight: '400', mr: 2}} />
        
        <Box display='block' mt={1}>
        Alunos que resolveram apenas algumas questões.<br/><br/>
        </Box>

        
        <Chip label='Não iniciada' size='small' sx={{backgroundColor: 'error.light', color: 'cinza.dark', fontWeight: '400', mr: 2}} />
        <Box display='block' mt={1}>
        Alunos que acessaram a lista pelo link, mas não resolveram nenhuma questão.
        </Box>
        </span>}
    ]

      return (
        <>
        <TableHead >
         
            {alunosNomeColunas.map ((item) => (
              <TableCellTooltip texto={item.label} tooltip={item.tooltip} />
             
            ))}  
        
        </TableHead>
        </>
      )
    }

function TableCellTooltip (props) {
      return (
        
          
          <TableCell>
          <Grid container spacing = {0} display='flex' flexDirection = 'row'  justifyContent={'flex-start'} alignContent={'center'}>
          
         
            {props.texto}
                
          {props.tooltip &&
          <Grid item xs={1}  mt={0.3} ml={0.3}>
            <Tooltip title={props.tooltip} arrow style={{whiteSpace: 'pre-line'}}>
            
              <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>
              
              </Tooltip>
              </Grid>  
              
          }
          </Grid>
            </TableCell>
         
        
      )
    }


function BotaoCopiarToken (props){
  
      const [open, setOpen] = useState (false);
    
      function copiar ()  {
        
        navigator.clipboard.writeText(props.token);
        setOpen(true)
      }
      
      
      
      return(
      
        <>
        <Tooltip title="Copiar token" arrow>
          <Button onClick={() => copiar()} size='small' ><TokenOutlinedIcon/></Button>
        </Tooltip> 
    
        {open && <SnackbarAviso open={open} msg='Token copiado com sucesso!' onClose = {setOpen} />}
        </>
       )
      }

function Status (aluno) {

      if (aluno.encerrada === true) {
             return {label: 'Entregue', color: 'success.light'
          }
      }
      if (aluno.encerrada === false && aluno.respostas !== null) {
              return {label: 'Iniciada'
      }
    }
      if (aluno.encerrada === false && aluno.respostas === null) {
          return {label: 'Não iniciada', color: 'error.light'
      }
    }
  }


  function questoesPctRespondidas (questoes, questoes_validas, alunos) {
    console.log (questoes)
    if (alunos.length === 0) return 0;
    let total_questoes = questoesValidas (questoes)
    let result = 0;
    for (let questao of questoes) {
        if (questao.tipo === 1) {
            
            for (let aluno of alunos) {
                
                if (aluno.respostas.length > 0) 
                {
                    let resposta = aluno.respostas[questao.num_questao]
                    if ( resposta !== 'X' && resposta !== 'Z') {
                        
                        result += 1;
                    }
                    
                }
            }
            
        }
    }
    
    let total_aluno = alunos.length
    total_questoes = total_questoes * total_aluno
    console.log (result)
    console.log (total_aluno);
    console.log (total_questoes);
    
    let pct = 100 * result / total_questoes
    if (!pct) pct = 0.0
    console.log (pct)
    return pct.toFixed(0);
}

  
/*
function questoesPctRespondidas (questoes, alunos) {
    
    if (alunos.length === 0) return 0;
    let total_questoes = 0
    let result = 0;
    
            
            for (let aluno of alunos) {
              
              for (let resposta of aluno.respostas) {
              if ((resposta !== 'X' && resposta !== 'Z'))
                {
                    result += 1;
                }
              }
              
              total_questoes += questoes
              }
    
    let pct = 100 * result / total_questoes
    return pct;
}
*/
  function questoesRespondidas (alunos) {
     let total = alunos.respostas.length;

    let result = 0;
            
            for (let resposta of alunos.respostas) {
                if (total > 0 && (resposta !== 'X' && resposta !== 'Z'))
                {
                    result += 1;
                }
            }
    return result;
}

function questoesValidas (questoes) {
    console.log (questoes)
    let result = 0;
    for (let questao of questoes) {
        if (questao.tipo === 1) {
            result += 1;
        }
    }
    return result;
}

function ordenarPorNomeCompleto (array) {
  return array.sort((a, b) => {
    const nomeCompletoA = `${a.nome} ${a.sobrenome}`.toLowerCase();
    const nomeCompletoB = `${b.nome} ${b.sobrenome}`.toLowerCase();
    if (nomeCompletoA < nomeCompletoB) return -1;
    if (nomeCompletoA > nomeCompletoB) return 1;
    return 0;
  });
}

function buscarNoLocalStorage(chave, id_lista) {
  // Obtém o valor associado à chave do localStorage
  const valor = localStorage.getItem(chave);

  // Verifica se o valor é null (chave não encontrada)
  if (valor === null) {
    return [];
  }

  // Parseia o valor como JSON (assumindo que é uma string JSON)
  const arrayDeObjetos = JSON.parse(valor);

  // Verifica se arrayDeObjetos é realmente uma array
  if (!Array.isArray(arrayDeObjetos)) {
    return [];
  }

  
  console.log (arrayDeObjetos);
  // Percorre a array para encontrar o objeto com o id_lista especificado
  for (const objeto of arrayDeObjetos) {
      console.log (id_lista)
      if (objeto.id === id_lista) {
      return objeto.questoes || [];
    }
  }

  // Se não encontrar o id_lista, retorna uma array vazia
  return [];
}

function pctAlunosQueEntregaram (entregues, total_alunos) {

  if (total_alunos === 0) return 0

  let result = 0;

  result = 100 * entregues / total_alunos

  return result

}

function alunosQueEntregaram (alunos) {

  let result = 0;
  for (let aluno of alunos) {
      if (aluno.encerrada === true) {
          result += 1;
      }
  }
  return result;
}

function alunosQueComecaram (alunos) {

  let result = 0;
  for (let aluno of alunos) {
      if (aluno.encerrada === false && aluno.respostas.length > 0) {
          result += 1;
      }
  }
  return result;
}

function alunosQueNaoComecaram (alunos) {

  let result = 0;
  for (let aluno of alunos) {
      if (aluno.encerrada === false && aluno.respostas.length === 0) {
          result += 1;
      }
  }
  return result;
}

function alunosTotal (alunos) {

  return alunos.length
}

function RespostasParaArray (alunos) {
    
  for (let item of alunos) {
      
      item.respostas = TransformarStringParaArrayStrings (item.respostas)
  }
  
  return alunos;

}

function TransformarStringParaArrayStrings (str) {
  
  if (str == null  ) return []
  return str.split(',').map(String);

}

