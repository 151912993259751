import React, { createContext, useContext, useState } from 'react';

// Criação do contexto
const TelaContext = createContext();

// Provider do contexto
function TelaProvider({ children }) {
  const [atual, setAtual] = useState('-1');
  const [anterior, setAnterior] = useState(null);

  

  const setTela = (novaTela) => {
    setAnterior(atual);
    setAtual(novaTela);
  };

  return (
    <TelaContext.Provider value={[ {atual: atual, anterior: anterior}, setTela ]}>
      {children}
    </TelaContext.Provider>
  );
}

// Hook personalizado
function useTela() {
  const context = useContext(TelaContext);
  if (!context) {
    throw new Error('useTela deve ser usado dentro de um TelaProvider');
  }
  return context;
}

export { TelaProvider, useTela };
