import { light }     from './theme.light'
import { militar }   from './theme.dark.militar'
import { navy_blue } from './theme.dark.navy.blue'
import { marrom }    from './theme.dark.marrom'

export const themes = {
  
  light: light, 
    
  dark: {
      
    militar: militar,
    navy_blue: navy_blue,
    marrom: marrom,            
  }
}

