import { Tooltip, Grid } from "@mui/material"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"

export default function HelpTooltip (props) {

    
    return (

        <>
        
        <Grid container spacing = {0} display='flex' flexDirection = 'row'  justifyContent={'flex-start'} alignContent={'center'}>
      
        <span>
        {props.texto}
        </span>    
        {props.tooltip &&
        <Grid item xs={1}  mt={0.3} ml={0.3}>
            <Tooltip title={props.tooltip} arrow style={{whiteSpace: 'pre-line'}}>
            
            <HelpOutlineOutlinedIcon sx={{ width:'0.6em', height:'0.6em'}}/>
            
            </Tooltip>
            </Grid>  
            
        }
      </Grid>
        </>
    )
}