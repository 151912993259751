import { chamarApi } from "../../../services/service.api";

export function Questoes (data, callback) {
    
    const getResult = async () => {
        
        try {
            console.debug ('data modelo aluno questao', data)
            const result = await chamarApi ({type: 'post', endpoint: 'alunoQuestoes', data: data}) // chama a API
            console.log ('questoes modelo', result)
            callback (result);
            
        } catch (error) {
            
            callback (error);
        } 
    }
        getResult();
}