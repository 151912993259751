 import { useContext, useEffect, useState, useRef } from "react"

import { Box, Button } from "@mui/material"
import HtmlPainel from "../../components/HtmlPainel/view.html.panel"
import Card from "../../views/components/view.card"
import CardTitulo from "../../views/components/view.card.titulo"
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined"
import ReactToPrint from 'react-to-print';
import { QuestoesContext } from "../../services/service.questoes.provider"
import BotaoVoltar from "../../components/BotaoVoltar/view.botao.voltar2"
import { ControllerVerQuestao, ObterPorIDLista } from "./controller.ver.questoes"
import { useTela } from "../../services/service.tela.provider"
import { useLocation, useNavigate } from "react-router-dom"
import './verquestoes.css'
import { useReactToPrint } from 'react-to-print';
import LogoAluno from "../Aluno/Cadastro/view.aluno.logo"
import { Get } from "../../services/service.local-storage"

export default function VerQuestoes (props) {
  
    const { id_lista, usuario, nomeLista, disciplinaEscolhida } = useContext (QuestoesContext)
    const [lista] = useState (ListaExiste (id_lista));
    const [, setTela] = useTela();
    const location = useLocation()
    const navigate = useNavigate();
    
    const [dados] = useState ({id_lista: id_lista, id_usuario: usuario.id}) 
    
    const [liberado, setLiberado] = useState (false);
    const [chamarAPI, setchamarAPI] = useState (false);

    const [questoes, setQuestoes] = useState ()
    const [html, setHtml] = useState ('')
    const componentRef = useRef();
    
    const marginTop = '10mm';
    const marginBottom = '10mm';
    const marginLeft = '10mm';
    const marginRight = '10mm';

    const getPageMargins = () => {
      return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    
  

   useEffect (() => {
     console.log (lista)
     if (lista.length === 0) {
         
        setchamarAPI (true)
         

     } else {
        let q = Montar (lista)
        setQuestoes (q)
        setHtml (tornarImagensResponsivas(q))   
        setLiberado (true)
     }

   }, [])

    return (<>
     
    {chamarAPI &&
        
        <ControllerVerQuestao dados={dados} setQuestoes={setQuestoes} setLiberado={setLiberado} Montar={Montar} setchamarAPI={setchamarAPI} tornarImagensResponsivas={tornarImagensResponsivas} setHtml={setHtml}  />
    
    }

    
    {liberado && !chamarAPI && html !== '' && <>
    
    <BotaoVoltar onClick={(e) => {e.preventDefault(); setTela ('Listas'); navigate('/listas', {state: {selected: 'VerQuestoes', id_lista: id_lista}})}} />
    <Card titulo={<CardTitulo icon={<FormatListBulletedOutlinedIcon />}  titulo = {'Questões - ' + nomeLista}  slide/> }  margin_top={20} fontSizeTitulo="1.2rem" divider mtChildren={2}>
    
    <ReactToPrint
        trigger={() => <Button variant="contained">Imprimir</Button>}
        content={() => componentRef.current}
        documentTitle = {'Lista de exercícios - ' + nomeLista + ' - FazProva'}
       
      />
    
      
        <Box mt={6}>
          <div ref={componentRef} >
            
             <Box sx={{mt:4, ml:-3}}>
             <LogoAluno />
             </Box>
              <style>{getPageMargins()}</style>  
                <HtmlPainel html = {html} />
              </div>

             
            

          
        </Box>
        
    </Card>
    </>}
    
    </>)
}



function Montar (questoes) {
    
    let arrayEnunciado = InserirNumeroEnunciado (questoes)
    let enunciado      = ConverteEnunciadoParaString (arrayEnunciado);
    
    let gabarito;
    gabarito       = Gabarito (questoes); 

    return enunciado.concat (gabarito)

}

function Gabarito (questoes) {
        let gabarito = '<p></p><p><strong>Gabarito:</strong></p><p></p>';
        
        questoes.forEach( (questao, index) => {
        
            questao.gabarito_objetiva !== '' ? gabarito = gabarito + '<p>' +  (index+1) + '. ' + questao.gabarito_objetiva + '</p>' : gabarito = gabarito + '<p>' +  (index+1) + '. ' + '</p>';
            questao.tem_gabarito_comentado !== '' ? gabarito = gabarito + questao.gabarito_comentado + '' : gabarito = gabarito + '';
            gabarito = gabarito + '<br>'
        
        }
        );
        return gabarito;
    }
    
function ConverteEnunciadoParaString (arrayEnunciado) {
        let stringFinal = '';
        
        arrayEnunciado.forEach(element => {
            stringFinal = stringFinal + element.enunciado + '<br/>';
        });
        
        return stringFinal;
}

function tornarImagensResponsivas(html) {
        
    // Encontrar todas as tags img e modificar seus atributos
    return html.replace(/<img([^>]*)>/g, (match, capture) => {

    // Se a tag já contém um estilo, adicionamos os estilos de responsividade a ele
    if (capture.includes('style="')) {
    return match.replace(/style="/, 'style="max-width: 100%; height: auto; object-fit: contain; ');
  
}
    // Se a tag não contém um estilo, adicionamos um novo atributo de estilo
    return `<img style="max-width: 100%; height: auto; object-fit: contain;"${capture}>`;

});
} 

function InserirNumeroEnunciado (questoes) {
    let index = 1;
    
    return questoes.map((questao) => {
        let str = `${index}. `;  // 1. Iniciar com "index . "

        // 4. Verificar questao.enunciado
        if (questao.enunciado.startsWith('<p>')) {
            str = questao.enunciado.slice(0, 3) + str + questao.enunciado.slice(3);  // Inserir str após <p>
        } else {
            questao.enunciado = questao.enunciado.replace("<p>", "<p>" + str);             
            str = questao.enunciado;
        }
        
        index += 1;  // Incrementar o index
        return { ...questao, enunciado: str };  // Retornar o objeto questao atualizado
    });
}



    function buscarNoLocalStorage(chave, id_lista) {
        // Obtém o valor associado à chave do localStorage
        const valor = localStorage.getItem(chave);
      
        // Verifica se o valor é null (chave não encontrada)
        if (valor === null) {
          return [];
        }
      
        // Parseia o valor como JSON (assumindo que é uma string JSON)
        const arrayDeObjetos = JSON.parse(valor);
      
        // Verifica se arrayDeObjetos é realmente uma array
        if (!Array.isArray(arrayDeObjetos)) {
          return [];
        }
      
        
        console.log (arrayDeObjetos);
        // Percorre a array para encontrar o objeto com o id_lista especificado
        for (const objeto of arrayDeObjetos) {
            
            if (objeto.id_lista === id_lista) {
            return objeto.questoes || [];
          }
        }
      
        // Se não encontrar o id_lista, retorna uma array vazia
        return [];
      }

      // Verifica no localStorage se as questões dessa lista estão presentes para não precisar pegar no servidor
      function ListaExiste (id_lista) {
      
        let listas = Get ('listas_questoes', 'array')
        console.log ('listas', listas)
        console.log (id_lista)
        const lista_existe = listas.some (item => item.id_lista === id_lista)
        console.log (lista_existe)
        if (lista_existe === true) {
          
          listas = listas.find (item => item.id_lista === id_lista)
          return listas.questoes
        
        } 
        
        return [];

      }