import { useState, useEffect } from "react";
import { Avatar, Stack, Box, Divider, Grid, Typography, useTheme, Grow } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HtmlPainel from '../../../../components/HtmlPainel/view.html.panel';

export default function Opcao (props) {
    
    const theme = useTheme ();
    
    const bgColor = theme.palette.background.default
    const lineColor = theme.palette.cinza.main
    const textColor = theme.palette.cinza.dark
    const checkColor = theme.palette.primary.main
    const closeColor = theme.palette.vermelho.dark
    const [letra, setLetra] = useState (null)
    const [marcado, setMarcado] = useState (false)
    //const textColor = theme.palette.primary.contrastText
    
    function tornarImagensResponsivas(html) {
        
        // Encontrar todas as tags img e modificar seus atributos
        return html.replace(/<img([^>]*)>/g, (match, capture) => {
    
        // Se a tag já contém um estilo, adicionamos os estilos de responsividade a ele
        if (capture.includes('style="')) {
        return match.replace(/style="/, 'style="max-width: 100%; height: auto; object-fit: contain; ');
      
    }
        // Se a tag não contém um estilo, adicionamos um novo atributo de estilo
        return `<img style="max-width: 100%; height: auto; object-fit: contain;"${capture}>`;

    });
  }
  
   
 
  function Letra () {
    
      
        if (props.item === 0) return 'A';
        if (props.item === 1) return 'B';
        if (props.item === 2) return 'C';
        if (props.item === 3) return 'D';
        if (props.item === 4) return 'E';
    }
  
  useEffect (() => {
        setLetra (
            <Avatar sx={{ width: 30, height: 30, bgcolor: bgColor,  color: textColor }}>
            {Letra ()}
            </Avatar>
        )
  }, [])
  
  

  function onClick (e) {
  
    e.preventDefault ();
    props.onClick (Letra(), props.item );    
    
 
    
  
}
    return (
        <>
            <Box sx = {{p: 1, ml: 1, mr: 1, mb: -1, backgroundColor: bgColor,  border: '1px solid ' + lineColor,  borderRadius: '16px 16px 16px 16px'}} onClick = {(e) => onClick (e)}>
                
            <Stack  direction={ "row" } spacing={2} sx={{alignItems: "center", ml:1, mr:2}}  divider={<Divider orientation="vertical" flexItem />}>
           
                    
           
                        
                    
                        
                        {props.marcado[props.item]===true ? 
                        
                            <Avatar sx={{ width: 30, height: 30, bgcolor: checkColor }}>
                              <Grow in={props.marcado[props.item]}
                              style={{ transformOrigin: '0 0 0' }}
          {...(props.marcado[props.item] ? { timeout: 500 } : {})}
                              >
                                <CheckIcon /></Grow>
                            </Avatar>
                            :
                            <Grow in={!props.marcado[props.item]}
                              style={{ transformOrigin: '0 0 0' }}
          {...(!props.marcado[props.item] ? { timeout: 500 } : {})}
                              >
                            <Avatar sx={{ width: 30, height: 30, bgcolor: bgColor,  color: textColor }}>
                            {Letra ()}
                            </Avatar>
                            </Grow>
                            }
                
                    

 
           
                
                    <Typography sx = {{textAlign: "justify"}} color = {textColor}>
                       
                        <HtmlPainel html = {tornarImagensResponsivas (props.texto)} />

                    </Typography>
                
           
            
           
            </Stack>
            </Box>
        </>
    )
}