import { Zoom, Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { QuestoesContext } from "../../services/service.questoes.provider";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTela } from "../../services/service.tela.provider";

const DisciplinaCard = ({ nome, icon, idDisciplina, idSegmento, disciplinaAbreviacao, segmentoAbreviacao, questoes, cor}) => {
    
    const { setDisciplinaEscolhida,  ZerarEscolhaQuestoes} = useContext (QuestoesContext);
    const navigate = useNavigate(); 
    const theme = useTheme();
    const tela = useMediaQuery(theme.breakpoints.down('sm')); 
    const [ , setTela] = useTela ();

    function Size () {
        
        if (!tela) {
            return {width: 140, height: 150, fonte: 'caption'}} else
        return {width: 140, height: 150, fonte: 'body1'}
    
    }

    
    function handleClick(e) {
    
        e.preventDefault();
        ZerarEscolhaQuestoes();
    
        setDisciplinaEscolhida ({idDisciplina: idDisciplina, idSegmento: idSegmento, nome: nome, disciplina: disciplinaAbreviacao, segmento: segmentoAbreviacao});
        setTela ('Questoes')
        navigate('/questoes');
    }

    return (
        
        <Box 
        
        id={nome}    
        sx={{
                width: Size().width,
                height: Size().height,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                ...(tela ? {mt: 0} : {mt :2}),
                mb: 2,
                ...(tela ? {ml: 1} : {ml :2}),
                ...(tela ? {mr: 1} : {mr :2}),
                
                backgroundColor: cor.light,
                borderRadius: 4, borderStyle: 'solid',
                borderColor: cor.light,
                transition: theme.transitions.create(['transform'], {
                    duration: theme.transitions.duration.standard,
                }),
                '&:hover': {
                    transform: 'scale(1.15)'
                  }
            }}
        
            onClick={(e) => handleClick(e)}
            onHover={() => alert('aaa')}
        >
           
          <Box sx={{width: 70, height: 70}} >
        
          {icon}
        
          </Box>
            
            <Typography variant={Size().fonte} sx={{ mt: nome==="História" ? 0.8 : 0.8, color: cor.dark, fontWeight:600, ...(tela ? {fontSize: '1.0em'} : {fontSize: '1.2em'})}}>
        
                {nome==='Língua Portuguesa' ? 'Português' : nome}
               
            </Typography>
        
            <Typography variant={Size().fonte} sx={{mt:-0.5, color: cor.dark, fontWeight: 300}}>
        
                {questoes + ' questões'}
        
            </Typography>
        
        </Box>
        
    );
}

export default DisciplinaCard;