import { useState, useEffect } from "react";
import ModalSucessoErro from "../../../../components/ModalSucessoErro/ModalSucessoErro";
import eDataMenorQueAtual, * as Data from '../../../../services/service.data'
import { useLocalStorage } from "../../../../services/service.hooks";
import { onClick } from "../Opcao/controller.opcao";
import * as modelAluno from '../model.aluno.questao';
import { dataAtual } from "../../../../services/service.data";

export default function ListaNaoLiberada (props) {
    
    const success = false
    const errorTipo = 'info'
    const titulo = 'Lista não liberada'
    const [ aluno ] = useLocalStorage ('aluno')
    const data = Data.Extrair (aluno.lista_inicio)
    
    const msg = 'O seu cadastro foi realizado com sucesso! Retorne nessa página e clique no botão ACESSAR quando a lista for liberada pelo professor no dia ' + data + '.'
    const [liberado, setLiberado] = useState (false)
    
    function chamarApi () {
        
        let dados = {}
        

        dados.id_link     = aluno.id_link
        dados.token_aluno = aluno.token_aluno
        dados.data        = dataAtual ();

        modelAluno.Questoes (dados, async function (result) {

            if (result.isSuccess) {

                window.location.reload();

            } else {
                
                setLiberado (true)

            }
        })
    }
   
    useEffect(() => {
        // Função que verifica a hora atual
        function verificarHora() {
            const agora = new Date();
            const horas = agora.getHours();
            const minutos = agora.getMinutes();

            // Se for meia-noite e 1 minuto, executa a ação desejada
            if (eDataMenorQueAtual (Data.Extrair(aluno.lista_inicio)) && horas === 0 && minutos === 1) {
                
                setLiberado (true)
                
            }
        }

        // Define o intervalo para verificar a hora a cada minuto (60000 milissegundos)
        const intervalo = setInterval(verificarHora, 60000);

        // Limpa o intervalo quando o componente for desmontado
        return () => clearInterval(intervalo);
    }, []);
    
     function onClickAcessar () {
        setLiberado (false)   
        chamarApi();
     }

    return (<>
        
        <ModalSucessoErro open ={true} success ={success} errorTipo={errorTipo} titulo={titulo} msg={msg}
        data = {{btn2: {mostrar: true, disabled: !liberado, color: 'primary', label: 'ACESSAR'},
        btn1: {mostrar: false, color: 'secondary', label: 'ACESSAR'},
        success: success, errorTipo: errorTipo
        }} 
        onClickBtn2 = {() => onClickAcessar()}

        ></ModalSucessoErro>
        
        </>
        );
}