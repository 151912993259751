// REACT
import { useState, useContext, useEffect } from 'react';

// CONSTANTES
import { VariaveisContext } from '../../services/service.variaveis.provider';

// MUI COMPONENTS
import { Alert, Box, Slide } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

// COMPONENTES
import SiteFramework from '../../components/site/view.site-framework.jsx'
import NotificacoesCard from './view.notificacoes.card';
import Card from '../../views/components/view.card.jsx';
import CardTitulo from '../../views/components/view.card.titulo.jsx';

export default function Notificacoes () {
    
  const { novasNotificacoes, setNovasNotificacoes } = useContext (VariaveisContext);
  const [notificacoes, setNotificacoes] = useState (!localStorage.getItem('notificacoes') ? [] : JSON.parse (localStorage.getItem ('notificacoes')));
    
  // VERIFICA SE TEMPOS EM TEMPOS SE HÁ NOVAS NOTIFICAÇÕES NO LOCALSTORAGE
  useEffect(() => {
  
    // Entrou na página, o número de novas notificações é zerado
    setNovasNotificacoes (0);
      
  }, []);
  
  // ATUALIZA AS NOTIFICAÇÕES
  function AtualizarNotificacoes () {
    
    if (novasNotificacoes > 0) {
      
      setNotificacoes (JSON.parse (localStorage.getItem ('notificacoes')));
      setNovasNotificacoes (0);

    }
  }
  
  return (
      <>
      <SiteFramework 
        children = {
        <>
           
           <Slide in={true} timeout={700} direction='down'>
        <Box>
        <Card titulo={<CardTitulo icon={<NotificationsNoneOutlinedIcon />}  titulo = 'Notificações' /> }  margin_top={20} fontSizeTitulo="1.2rem" divider > 
          
          {/* ************** CARD NOTIFICACOES ************** */}
          
          {notificacoes.length > 0 ? notificacoes.map ((a, index) => {return <> <NotificacoesCard key={index} notificacoes={a} /> </>})
          
          : 
          
          <Box component="main" sx={{ mt:-1.5 }}>
          
              <Alert severity="info" variant='filled'>Nenhuma notificação até o momento</Alert>
          
          </Box>
          }

</Card>
         </Box>
         </Slide> 
        
</>} />
   </>       
  );
}  