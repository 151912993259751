import { useState, useEffect } from 'react';

import { Box, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Card, CardContent, Typography, Divider, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ApiCall from '../../services/service.api';
import SnackbarAviso from '../SnackBar/SnackBarAviso';
import CardTitulo from '../../views/components/view.card';
import ControllerPix from './controller.pix';
import { useLocalStorage } from '../../services/service.hooks';
import { parseJSON } from 'date-fns';

export default function Pix (props) {
  
  const [usuario] = useState (JSON.parse (localStorage.getItem ('usuario')).id);
  //const [minutes, setMinutes] = useState(1);
  const [minutes, setMinutes] = useState(5)   ;
  const [seconds, setSeconds] = useState(0);
  const [isTimeOut, setTimeOut] = useState(props.dados.encerrado === false ? false : true);
  const [avisoOpen, setAvisoOpen] = useState(false);
 
  const [pixCode] = useState (props.dados.code); // Substitua por seu código PIX
  const [base64] = useState ("data:image/png;base64," + props.dados.qr_code);
  const [id_pagamento] = useState (props.dados.id)
  
  async function UpdateLocalStorage () {
     localStorage.setItem ('time', minutes)
  }


  // TIMER DO PAGAMENTO DO PIX
  useEffect(() => {
    if (minutes === 0 && seconds === 0 || isTimeOut) {
      localStorage.removeItem ('time');
      localStorage.setItem ('pix', JSON.stringify ({...props.dados, encerrado: true}))
      setTimeOut (true);
    } else {
      const timerId = setTimeout(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
        UpdateLocalStorage();
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [minutes, seconds]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(pixCode);
    setAvisoOpen(true);
  };
 
  

return (    
<>

{/* ************** AVISO ************** */}
{avisoOpen ? <SnackbarAviso tipo='success' open={avisoOpen} msg="Código PIX copiado!" onClose={() => setAvisoOpen(false)} /> : null
}
{/* ************** CARD ************** */}
<Box sx={{display: 'flex', flexDirection:'row', justifyContent: 'center' }}>
<Box sx={{maxWidth: '800px', }}>

<CardTitulo titulo='PIX' divider >

  <Instrucoes />
         
  <Grid container sx={{mt:3}}  spacing={2} direction="column" justifyContent="center" alignItems="center">
            
    {!isTimeOut &&
    <>
    <QRCodeImagem base64={base64} />
    
    <Grid item >

      <QRCodeCodigo pixCode={pixCode} handleCopyClick={() => handleCopyClick()} />        

       <Tempo minutes={minutes} seconds={seconds} />

    </Grid>

    </>
}
    
    <ControllerPix id_pagamento={id_pagamento} onFinish = {(valor) => props.onFinish(valor)}/>
    </Grid>

    {isTimeOut &&       
         
      <Typography sx={{ mt: 4, mb:4, textAlign: "center" }} color="vermelho.dark" fontWeight={500} fontSize={'1.2em'}>
      
        O tempo para a realização do pagamento expirou. Por favor, clique em CONFIRMAR PAGAMENTO ou refaça a compra.
    </Typography>
} 
    



    </CardTitulo>
    </Box>
    </Box>
</>
)
}


function Tempo (props) {
  
  const {minutes, seconds} = props;

  return (

    <>
    <Grid item sm={12} >
          <Typography align='center' sx={{ mt: 3, mb:3 }} variant="h6">
            Tempo restante: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
          </Grid>
    </>
  )
}

function QRCodeCodigo (props) {
  
  const {pixCode, handleCopyClick} = props;

  return (

    <>
    
    <Grid container sx={{mt:3}} spacing={0} direction="row" justifyContent="center" alignItems="center">
            <Grid item >
          <FormControl style={{width: "300px"}}  variant="outlined">
          <InputLabel   htmlFor="outlined-adornment-password">{pixCode}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
           // type={showPassword ? 'text' : 'password'}
            
            label="Código PIX"
          />
          
        </FormControl>
        </Grid>
        <Grid item > 
        <Button onClick={() =>  handleCopyClick()} variant="outlined"  sx={{borderRadius:0, pt:-0, pb:0, pl:0, pr:0}}  >
        <ContentCopyIcon fontSize="medium" sx={{ mt:1.9, mb:1.9}}/>
</Button>

          
                </Grid>
                
         
         
          </Grid>
    </>
  )

  


}
function QRCodeImagem (props) {

  return (
    <>
    <Grid item sm={12}>
    
      <img src={props.base64} style={{"width": "200px", "display": "block", "marginLeft": "auto", "marginRight": "auto" }} alt="QR Code" />
    
    </Grid>
    </>
  )
}
function Instrucoes () {

  return (
    <>
    <Typography sx={{mt: 2, fontSize: '1.2em'}}>
            Siga as instruções para realizar o pagamento via PIX:
        </Typography>  
        <Typography sx={{ml:2, mt: 3, fontSize: '1.0em'}} color='text.secondary'>
            1. Abra o aplicativo do seu banco e selecione a opção PIX.
        </Typography>    
        <Typography sx={{ml:2, mt: 1, fontSize: '1.0em'}} color='text.secondary'>
            2. Escolha a opção "Pagar com QR Code" ou insira o código abaixo.
        </Typography>
        <Typography sx={{ml:2, mt: 1, fontSize: '1.0em'}} color='text.secondary'>
            3. Clique no botão CONFIRMAR PAGAMENTO após a realização do pagamento no seu banco.
        </Typography>    
    </>
  )
}
